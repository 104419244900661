.container {
  display: flex;
  flex-direction: column;
  background: #F5F5F5;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.container .noOrder {
  height: 25vh;
  margin: auto;
  padding-top: 20vh;
  text-align: center;
}

.container .noOrder>div:nth-child(1) img {
  width: 20%;
}

.container .noOrder>div:nth-child(2) {
  margin-top: 0.4rem;
  font-size: 0.373333rem;
  color: #aaaaaa;
}

.container .projects {
  padding-bottom: 1.866667rem;
  height: 100vh;
  overflow: auto;
}

.container .projects .project {
  margin-top: 0.266667rem;
  background-color: #ffffff;
  padding: 0.133333rem 0.266667rem;
  font-size: 0.346667rem;
  overflow: hidden;
}

.container .projects .project>div {
  padding: 0.133333rem 0;
}

.container .projects .project>div:last-child {
  margin-top: 0.133333rem;
  padding: 0.266667rem 0 0 0;
  border-top: solid 0.026667rem #d9d9d9;
  color: #828282;
  display: flex;
}

.container .projects .project>div:last-child>div:nth-child(2) {
  padding-left: 0.266667rem;
  flex: auto;
}

.container .btn {
  background: #F5F5F5;
  padding: 0 0.533333rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.866667rem;
  display: flex;
  align-items: center;
  justify-content: center;
}