.runing {
  background: var(--runing_background);
  height: 1.066667rem;
  line-height: 1.066667rem;
  display: flex;
}

.runing .img {
  padding: 0 0 0 0.32rem;
  width: 0.853333rem;
}

.runing .img img {
  margin-top: 0.266667rem;
  width: 0.533333rem;
  height: 0.533333rem;
}

.runing .tip {
  padding: 0 0.133333rem;
  color: #2d2d2d;
  letter-spacing: 0;
  flex: auto;
  font-size: 0.346667rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 63%;
}

.runing .tip span {
  color: var(--runing_tip_color);
}

.runing .opt {
  padding: 0.213333rem 0.4rem;
  overflow: hidden;
}

.runing .opt .but {
  color: var(--runing_but_color);
  border: solid 0.026667rem var(--runing_but_border_color);
  background-color: var(--runing_but_background_color);
  height: 0.64rem;
  line-height: 0.64rem;
  font-size: 0.32rem;
  padding: 0 0.186667rem;
  border-radius: 0.533333rem;
  text-align: center;
}