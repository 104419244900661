.address {
  overflow: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #f4f4f4;
}

.address .heads {
  background-color: #fafaf8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.address .heads .head {
  display: flex;
  line-height: 1.2rem;
  border-bottom: solid 0.026667rem #d9ddde;
  font-size: 0.346667rem;
  justify-content: space-between;
}

.address .heads .head .cityCell {
  display: -webkit-box;
}

.address .heads .head .city {
  font-size: 0.346667rem;
  min-width: 1.066667rem;
  text-align: center;
  padding: 0 0.133333rem 0 0.4rem;
  background-size: 0.186667rem 0.32rem;
}

.address .heads .head .input {
  flex: auto;
  margin: 0 0 0 0.266667rem;
}

.address .heads .head .input input {
  width: 100%;
  width: 100%;
  height: 0.8rem;
  line-height: 0.8rem;
  text-indent: 0.266667rem;
  border: none;
  border: solid 0.026667rem #d9d9d9;
  border-radius: 0.133333rem;
  font-size: 0.373333rem;
}

.address .heads .head .input>input::-ms-input-placeholder {
  font-size: 0.346667rem;
}

.address .heads .head .input>input::-webkit-input-placeholder {
  font-size: 0.346667rem;
}

.address .heads .head .cancel {
  width: 1.6rem;
  color: #4670a0;
  text-align: center;
}

.address .addressBox {
  font-size: 0.373333rem;
  top: 1.333333rem;
  font-size: 0.373333rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin: 0.133333rem 0.266667rem 0.266667rem 0.266667rem;
  border-radius: 0.133333rem;
}

.address .container {
  background-color: #f4f4f4;
}

.address .body {
  background-color: white;
  border-radius: 0.133333rem;
}

.address .body .currentaddress {
  height: 1.2rem;
  background-size: 0.32rem 0.373333rem;
  padding-left: 0.4rem;
  font-size: 0.346667rem;
  width: 100vw;
  text-overflow: ellipsis;
  overflow: hidden;
}

.address .body .current {
  border-bottom: solid 0.026667rem #e7e7e7;
}

.address .body .sscontainer {
  background-color: #f4f4f4;
}

.address .body .sscontainer .noneData>div:nth-child(1) {
  margin: auto;
  width: 35vw;
  margin-top: 10vh;
}

.address .body .sscontainer .noneData>div:nth-child(1) img {
  width: 100%;
  height: 100%;
}

.address .body .sscontainer .noneData>div:nth-child(2) {
  text-align: center;
  font-size: 0.346667rem;
  color: #a0a0a0;
}

.address .body .ss .city {
  padding-left: 0.266667rem;
  padding-right: 0.266667rem;
  border-top: solid 0.026667rem #e7e7e7;
  padding-top: 0.32rem;
}

.address .body .ss .city .tip {
  font-size: 0.32rem;
  line-height: 0.533333rem;
  color: #9d9d9d;
  position: relative;
  top: -0.266667rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-top: 0.4rem;
}

.address .body .ss .city .doors {
  line-height: 0.533333rem;
  font-size: 0.32rem;
}

.address .body .ss .city .doors .door {
  width: 33%;
  padding: 0 0.266667rem 0.266667rem 0.266667rem;
  display: inline-block;
}

.address .body .ss .city .doors .door div {
  border: solid 0.026667rem #f1f1f1;
  text-align: center;
  padding: 0.133333rem 0;
  box-shadow: 0 0 0.133333rem 0 rgba(60,72,88,0.1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.citys {
  overflow: none;
  position: relative;
  height: 100vh;
  width: 100vw;
}

.citys .heads {
  background-color: #fafaf8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 0.346667rem;
}

.citys .heads .head {
  display: flex;
  line-height: 1.2rem;
  border-bottom: solid 0.026667rem #d9ddde;
}

.citys .heads .head .input {
  flex: auto;
  margin: 0 0 0 0.266667rem;
}

.citys .heads .head .input input {
  width: 100%;
  width: 100%;
  height: 0.8rem;
  line-height: 0.8rem;
  text-indent: 0.266667rem;
  border: none;
  border: solid 0.026667rem #d9d9d9;
  border-radius: 0.133333rem;
  font-size: 0.373333rem;
}

.citys .heads .head .input>input::-ms-input-placeholder {
  text-align: center;
  font-size: 0.346667rem;
}

.citys .heads .head .input>input::-webkit-input-placeholder {
  text-align: center;
  font-size: 0.346667rem;
}

.citys .heads .head .cancel {
  width: 1.6rem;
  color: #4670a0;
  text-align: center;
}

.citys .body {
  top: 1.333333rem;
  font-size: 0.373333rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.citys .body .cs {
  line-height: 1.2rem;
}

.citys .body .cs .currentcity {
  height: 1.2rem;
  background: url(../../assets/begin_blue.png) 0.4rem center no-repeat;
  background-size: 0.32rem 0.373333rem;
  padding-left: 0.933333rem;
}

.citys .body .indexBar {
  position: absolute;
  top: 1.333333rem;
  left: 0;
  right: 0;
  bottom: 0;
}