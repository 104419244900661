.radio .boxList {
  width: 100%;
  max-width: 100%;
  padding-right: 0.266667rem;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  flex: 1 1;
  align-items: center;
}

.radio :global .adm-list-body {
  border-bottom: 0;
}

.radio :global .adm-list-item .adm-list-item-content {
  height: 1.306667rem !important;
  padding-right: 0 !important;
}

.radio :global .adm-space-item {
  margin-right: 0.08rem !important;
}

.radio :global .adm-list-item-content-main {
  display: flex;
  word-break: break-word;
  overflow: hidden;
}

.radio :global .adm-input {
  width: 90%;
}

.radio .box {
  width: 100%;
}

.radio .checkListDefaultValue {
  color: #999999;
  font-size: 0.4rem;
  width: 100%;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  font-size: 0.4rem;
  display: inline-block;
  text-align: right;
}

.radio .checkListValue {
  width: 100%;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  font-size: 0.4rem;
  display: inline-block;
  text-align: right;
}

.boxSearchList {
  width: 100%;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  flex: 1 1;
  align-items: center;
}

.space {
  margin-right: 0.133333rem;
  margin-left: 0.32rem;
}

.project_search {
  display: flex;
  align-items: center;
  background-color: white;
  margin: 0 0 0 0.133333rem;
  display: flex;
  justify-content: right;
  display: inline-block;
}

.project_search .right_box {
  display: flex;
  justify-content: right;
  align-items: center;
}

.project_search .retrieval {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  font-size: 0.373333rem;
}

.project_search span {
  margin-left: 0.053333rem;
  font-size: 0.373333rem;
}

.required {
  color: red;
}

.popupBox {
  max-height: 70%;
  min-height: 40%;
  padding-bottom: 0.8rem;
}

.popupBox :global .adm-list-body-inner {
  height: 8rem;
  overflow-y: auto;
}

.searchBox {
  padding: 0.266667rem;
  background-color: #ffffff;
}

.customForm {
  margin-top: 0.4rem;
}

.customForm :global .adm-list-body {
  border-bottom: 0;
}

.customForm:first-child :global .adm-list-body {
  border-top: 0;
}

.inputText :global .adm-list-item-content {
  height: 1.306667rem !important;
}

.inputText :global .adm-input input {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.textArea :global .adm-list-item-content {
  align-items: flex-start;
}

.textArea :global .adm-list-item-content .adm-list-item-content-prefix {
  margin-top: 0.266667rem;
}

.textArea :global .adm-list-item-content-main {
  display: flex;
  overflow: hidden;
  word-break: break-word;
}

.textArea :global .adm-input {
  width: 90%;
}

.textArea :global .adm-input input {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}