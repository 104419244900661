.set_mode {
  padding: 0.133333rem 0.4rem 0.4rem 0.4rem;
}

.set_mode .title {
  text-align: center;
  font-size: 0.346667rem;
  font-weight: bolder;
  display: block;
  margin-top: 0.32rem;
}

.mode_box {
  width: 100%;
}

.mode_box .car_mode {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #eef3f8;
  padding: 0.32rem;
  border-radius: 0.266667rem;
  margin-top: 0.266667rem;
}

.mode_box .car_mode .message {
  display: flex;
  margin-right: 0.586667rem;
}

.mode_box .car_mode .message img {
  width: 0.506667rem;
  height: 0.506667rem;
  margin-right: 0.133333rem;
}

.mode_box .car_mode .message .illustrate {
  text-align: left;
}

.mode_box .car_mode .message .illustrate p {
  width: 6.666667rem;
  text-align: left;
  margin: 0;
  color: #9F9F9F;
  font-weight: normal;
}

.mode_box .default_checked {
  width: 0.533333rem;
  height: 0.533333rem;
  border-radius: 0.4rem;
  overflow: hidden;
  border: solid 0.026667rem #cccccc;
}

.mode_box .checked {
  width: 0.533333rem;
  height: 0.533333rem;
  background: url(../../assets/checked.png) no-repeat 0% 0%;
  background-size: 100% 100%;
  border: none;
}