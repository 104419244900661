.fade-enter {
  opacity: 0;
}

.fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

:root {
  /* 按钮字体颜色 */
  --icar-btn-color: #ffffff;
  --icar-text-color-white: #ffffff;
  --icar-text-color-price: #2D3034;
  --icar-text-colot-error: #EC5F50;
  --icar-bg-white: #ffffff;
  --icar-bg-container: #F5F5F5;
  /* ================打车流程=============== */
  --icar-callcar-text-head: #3c4858;
  --icar-callcar-text-content: #9b9b9b;
  --icar-callcar-text-cancel: #4670A0;
  --icar-callcar-text-tip: #9D9D9D;
  --icar-callcar-text-head: #3C4858;
  --icar-callcar-text-car: #3D4959;
  --icar-callcar-text-estimate: #455060;
  --icar-callcar-text-call: #A3A8B0;
  --icar-callcar-text-amount: #00AB19;
  --icar-callcar-text-waiting: #165DC4;
  --icar-callcar-text-pirce: #CEAD2F;
  --icar-callcar-text-ok: #2099FF;
  --icar-callcar-text-title: #9F9F9F;
  --icar-callcar-text-black: #000000;
  --icar-callcar-text-info: #8c8c8c;
  --icar-callcar-text-info-blue: #4B8ED3;
  --icar-callcar-waitingTime-text-black: #272727;
  --icar-callcar-waitingTime-text-blue: #5688BE;
  --icar-callcar-text-useDetails: #6492C4;
  --icar-callcar-text-checkList: #999999;
  --icar-callcar-text-time: #77ac75;
  --icar-callcar-text-beforchoose: #3A4656;
  --icar-callcar-text-name: #5685BB;
  --icar-callcar-text-summary: #141414;
  --icar-callcar-text-detail: #2D2D2D;
  --icar-callcar-text-addrname: #42A737;
  --icar-callcar-text-place: #9DA3AB;
  --icar-callcar-text-distance: #DFB45F;
  --icar-callcar-text-airportPickup: #438AD1;
  --icar-callcar-text-star: #F5A623;
  --icar-callcar-text-tools: #131415;
  --icar-callcar-text-footbar: #467DB9;
  --icar-callcar-text-line: #0082EF;
  --icar-text-confirm: #B0B5BC;
  --icar-text-head: #ABABAB;
  --icar-text-boxshadow: #F0F0F0;
  --icar-text-estimatetime: #62B659;
  --icar-trip-text-time: #808080;
  --icar-runing-text-time: #B8B8B8;
  --icar-runing-text-noOrder: #AAAAAA;
  --icar-runing-text-trip: #A3A3A3;
  --icar-share-text-tip: #4F77AA;
  --icar-coupon-text-alert: #4A4A4A;
  --icar-callpolice-text-title: #497DAB;
  --icar-callpolice-text-info: #C0C0C0;
  --icar-callpolice-text-strategy: #387CC6;
  /* 背景色 */
  --icar-callcar-bg-white: #ffffff;
  --icar-callcar-bg-addr: #f4f4f4;
  --icar-callcar-bg-head: #FAFAF8;
  --icar-callcar-bg-btn: #97BEE6;
  --icar-callcar-bg-blue: #165DC4;
  --icar-callcar-bg-scene: #F5F5F5;
  --icar-callcar-bg-commonaddr: #E3E5E7;
  --icar-callcar-bg-ticket: #D0D0D0;
  --icar-callcar-bg-yellow: #F7901C;
  --icar-callcar-bg-level: #5685BB;
  --icar-callcar-bg-menu: #F7F7F7;
  --icar-callcar-bg-greenCircle: #42A737;
  --icar-callcar-bg-underline: #0082EF;
  --icar-bg-container: #F0F0F0;
  --icar-callpolice-bg-container: #FDFDFD;
  --icar-callpolice-bg-dot: #387CC6;
  /* cancelReason */
  --icar-callcar-cr-bg-container: #EEEEF0;
  --icar-callcar-cr-bg-title: #F3F3F3;
  --icar-callcar-border-white: #ffffff;
  --icar-callcar-border-but: #2099FF;
  --icar-callcar-border-carpannel: #EEF3F8;
  --icar-callcar-border-title: #F7F7F7;
  --icar-callcar-border-door: #F1F1F1;
  --icar-callcar-border-prompt: #BBBBBB;
  --icar-callcar-border: #ffffff;
  --icar-callpolice-border-info: #C0C0C0;
  /* ================打车流程=============== */
  /* ===============审批=========== */
  --icar-approval-text-msg: #9b9b9b;
  --icar-approval-text-icon: #C9C9CE;
  --icar-approval-text-black: #000000;
  --icar-approval-text-loading: #CCCCD0;
  --icar-approval-text-btn: #FF4731;
  --icar-approval-text-record: #465161;
  --icar-approval-text-title: #ABAFB6;
  --icar-approval-bg-step: #165DC4;
  --icar-approval-bg: #F5F5F5;
  --icar-approval-bg-success: #42A737;
  --icar-approval-bg-reject: #FF4731;
  --icar-approval-bg-bar: #FAFAFA;
  --icar-approval-bg-tabs: #FAFAFA;
  --icar-approval-border-btn: #FF4731;
  --icar-approval-border-prompt: #BBBBBB;
  /* ===============审批=========== */
  /* =============发票================= */
  --icar-invoice-text-content: #9b9b9b;
  --icar-invoice-text-price: #cccccc;
  --icar-invoice-text-loading: #CCCCD0;
  --icar-invoice-text-price: #3480CE;
  --icar-invoice-text-time: #89898A;
  --icar-invoice-text-cell: #808080;
  --icar-invoice-text-score: #F5AE37;
  --icar-invoice-bg-btn: #165DC4;
  --icar-invoice-bg-tabs: #F5F5F5;
  --icar-invoice-bg-bar: #FAFAFA;
  --icar-invoice-border-prompt: #F5F5F5;
  /* =============发票================= */
  /* =============订单================= */
  --icar-order-text-content: #9b9b9b;
  --icar-order-text-begin: #3D4959;
  --icar-order-text-black: #000000;
  --icar-order-text-prepaid: #42A737;
  --icar-order-text-msg: #467DB9;
  --icar-order-text-cell: #292C31;
  --icar-order-text-frozen: #CCCCD0;
  --icar-order-text-price-em: #698BB5;
  --icar-order-text-prepaid: #F5AE38;
  --icar-order-text-date: #A0A0A0;
  --icar-order-text-verb: #ABABAB;
  --icar-order-text-finish: #448AD2;
  --icar-order-text-msg: #A6A6A6;
  --icar-order-text-colorgrey: #A2A2A2;
  --icar-order-bg-addr: #165DC4;
  --icar-order-bg-box: #F5F5F5;
  --icar-order-bg-start-circular: #42A737;
  --icar-order-bg-end-circular: #F34747;
  --icar-order-bg-pop: #F9F9F9;
  --icar-order-bg-dateSelect: #FAFAFA;
  --icar-order-bg-progress: #4C75A8;
  --icar-order-bg-selector: #E7F1FF;
  --icar-order-border-textarea: #C0C0C0;
  --icar-order-border-selector: #1677FF;
  --icar-order-border-prompt: #BBBBBB;
  /* =============订单================= */
  /*============= 支付============= */
  --icar-pay-text-pay: #9b9b9b;
  --icar-pay-text-payNum: #3C4858;
  --icar-pay-text-copy: #165DC4;
  --icar-pay-text-noneOrders: #9F9F9F;
  --icar-pay-text-black: #000000;
  --icar-pay-text-msg: #999999;
  --icar-pay-text-moneyReturn: #F5A623;
  --icar-pay-text-adm: #467DB9;
  --icar-pay-text-service: #1677FF;
  --icar-pay-text-head: #FCFDFE;
  --icar-pay-text-orderPay: #F12C20;
  --icar-pay-text-timeDate: #A4A7AB;
  --icar-pay-text-step: #E5E5E5;
  --icar-pay-bg-footbar: #165DC4;
  --icar-pay-bg-copy: #F5F5F5;
  --icar-pay-bg-container: #F9F9F9;
  --icar-pay-bg-tabs: #FAFAFA;
  /*============= 支付============= */
  /* =============汇报================= */
  --icar-report-text-menu: #F7F7F7;
  --icar-report-text-statistics: #A6A6A6;
  /* =============汇报================= */
  /* =============登录、账户、联系人、个人================= */
  --icar-login-text-msg: #9b9b9b;
  --icar-login-text-register: #5279AB;
  --icar-login-bg-register: #EDF1F6;
  --icar-login-bg-lightColor: #A6C5E5;
  --icar-account-text-noneData: #9F9F9F;
  --icar-account-text-tip: #F34747;
  --icar-account-text-inc: #F98919;
  --icar-account-text-sub: #4B81C4;
  --icar-account-text: #71a6dd;
  --icar-setting-text-box: #CBCBD0;
  --icar-account-bg-amount: #165DC4;
  --icar-account-bg-footbar: #FAFAFA;
  --icar-contact-text-box: #467DB9;
  /*============= 登录============= */
  /* =======字号========= */
  /* --fs-10:10px;
  --fs-11:11px;
  --fs-12:12px;
  --fs-13:13px;
  --fs-14:14px;
  --fs-15:15px;
  --fs-16:16px;
  --fs-17:17px;
  --fs-18:18px;
  --fs-20:20px;
  --fs-22:22px;
  --fs-24:24px;
  --fs-32:32px; */
}

.qqmap_control {
  display: none;
}

:root.imtpath_style {
  --button_background_color: linear-gradient(134deg, #165DC4 0%, #165DC4 100%);
  --button_border_color: #165DC4;
  --button_color: #ffffff;
  --main_address: #42A730;
  --main_place: #9DA3AB;
  --main_fixed_color: #42A737;
  --main_endaddr_add_pathway_grey_background: #cccccc;
  --main_endaddr_add_pathway_grey_color: #141414;
  --estimate_car_count_color: #00AB19;
  --estimate_level_background: rgba(47, 125, 205, 0.10);
  --servertype_underline: #0082ef;
  --runing_background: rgba(32, 153, 255, 0.1);
  --runing_tip_color: #2099ff;
  --runing_but_color: #2099ff;
  --runing_but_border_color: #2099ff;
  --runing_but_background_color: none;
  --runing_dispatch_span: #165DC4;
  --runing_showWait5s_color: #558ff5;
  --runing_showWait5s_circle_color: #1677ff;
  --runing_addModel_btn_color: #141414;
  --recommendpath_header_font_weight: normal;
  --recommendpath_header_color: #3C4858;
  --recommendpath_place_rigth_color: #42A737;
  --recommendpath_h2_span_color: #42A737;
  --manualChoose_carlevel_active_color: #165DC4;
  --manualChoose_carlevel_active_underline_color: #165DC4;
  --manualChoose_carlevel_span_background_color: #165DC4;
  --manualChoose_carlevel_span_color: #ffffff;
  --manualChoose_car_checked_image: url(./assets/checked.png);
  --manualChoose_car_coupon_color: #00ab19;
  --manualChoose_button_disabled_background_image: linear-gradient(98deg, #96B6E4 0%, #96B6E4 100%);
  --manualChoose_button_background_image: linear-gradient(98deg, #165DC4 0%, #165DC4 100%);
  --manualChoose_button_interval_color: #FFBC00;
  --manualChoose_button_count_color: #FFBC00;
  --countDown_waitingTime_color: #5688be;
  --runingView_distance_color: #dfb45f;
  --runingView_time_color: #77ac75;
  --runingView_small_bell: url(./assets/small_bell.png);
  --runingView_star_color: #F5A623;
  --runingView_cancel_tip: #357bc3;
  --order_circular_green: #42a737;
  --order_circular_red: #f34747;
  --order_unpaid_background_color: #F5A623;
  --order_unpaid_color: #ffffff;
  --order_blue: #1677ff;
  --confirm_go_confirm_pay_color: #1677ff;
  --confirm_yes_confirm_btn_color: #000000;
  --payments_tab_active_color: #1677ff;
  --payments_tab_line_background: #1677ff;
  --payments_checkbox_border-color: #1677ff;
  --payments_checkbox_background_color: #1677ff;
  --payments_checkbox_color: #ffffff;
  --payments_pay_btn_background_image: linear-gradient(134deg, #165DC4 0%, #165DC4 100%);
  --payments_pay_btn_color: #ffffff;
  --payments_footbar_child_color: #F5A623;
  --payments_body_adm_auto_center_content: #333;
  --orderdetails_pay_amount: #165DC4;
  --orderdetails_unit: #3C4858;
  --orderdetails_evaluate_span: #165DC4;
  --orderdetails_expenses: #165DC4;
  --orderdetails_driver_level: #F5A623;
  --orderdetails_bule_name: #165DC4;
  --orderdetails_label_bule_color: #165DC4;
  --orderdetails_label_bule_background_color: rgba(47,125,205,0.10);
  --orderdetails_label_bule_border_color: rgba(47,125,205,1);
  --orderdetails_complaint_background_color: rgba(47, 125, 205, 0.1);
  --orderdetails_complaint_span_background_color: #165DC4;
  --orderdetails_prepaid_color: #165DC4;
  --orderdetails_prepaid_background_color: #e7eef9;
  --orderdetails_paid_span_color: #165DC4;
  --orderdetails_complaint_details_step_background_color: #1677ff;
  --orderdetails_confirm_btn_yes_background: #42A737;
  --orderdetails_confirm_btn_yes_font_size: #ffffff;
  --serviceCenter_details_p_color: #3C4858;
  --serviceCenter_details_type: rgba(60,72,88,0.05);
  --serviceCenter_pay_type_color: #FFFFFF;
  --serviceCenter_pay_type_background_color: #F5A623;
  --serviceCenter_begin_span_background_color: #42A737;
  --serviceCenter_end_span_background_color: #F34747;
  --serviceCenter_fee_cell_bule_background_color: rgba(47,125,205,0.10);
  --serviceCenter_fee_cell_bule_border_color: rgba(47,125,205,1);
  --serviceCenter_fee_cell_bule_color: #165DC4;
  --serviceCenter_badge_color: #ffffff;
  --complaintsDetails_fee_cell_bule_background_color: rgba(47,125,205,0.10);
  --complaintsDetails_fee_cell_bule_border_color: rgba(47,125,205,1);
  --complaintsDetails_fee_cell_bule_color: #165DC4;
  --expenseDetails_car_source_span: #165DC4;
  --expenseDetails_feedback_dev: #165DC4;
  --expenseDetails_red_color: #F34747;
  --address_index_bar_background_color: #1677ff;
  --address_cancel_color: #4670a0;
  --habitAddress_empty_background_image: linear-gradient(134deg, #165DC4 0%, #165DC4 100%);
  --habitAddress_empty_color: #ffffff;
  --setphone_count_background_image: linear-gradient(134deg, #165DC4 0%, #165DC4 100%);
  --setphone_count_color: #ffffff;
  --setphone_light_background_color: #A6C5E5;
  --setphone_light_color: #ffffff;
  --contact_span_color: #467DB9;
  --adm_dialog_btn_color: #1677ff;
  --adm_dialog_btn_bold_color: #1677ff;
  --adm_picker_header_button_color: #1677ff;
}

:root.meituan_style {
  --button_background_color: linear-gradient(134deg, #FFE74D 0%, #FFDD19 100%);
  --button_border_color: #FFE74D;
  --button_color: #000000;
  --main_address: #181818;
  --main_place: #FF6A00;
  --main_fixed_color: #FF6A00;
  --main_endaddr_add_pathway_grey_background: #FFFFFF;
  --main_endaddr_add_pathway_grey_color: #D1D1D1;
  --estimate_car_count_color: #FF6A00;
  --estimate_level_background: rgba(255,228,59);
  --servertype_underline: #FFDD00;
  --runing_background: rgba(0, 0, 0, 0.05);
  --runing_tip_color: #2099ff;
  --runing_but_color: #000000;
  --runing_but_border_color: #FFDD00;
  --runing_but_background_color: #FFDD00;
  --runing_dispatch_span: #FF6A00;
  --runing_showWait5s_color: #FF6A00;
  --runing_showWait5s_circle_color: #FF6A00;
  --runing_addModel_btn_color: #898989;
  --recommendpath_header_font_weight: normal;
  --recommendpath_header_color: #141414;
  --recommendpath_place_rigth_color: #141414;
  --recommendpath_h2_span_color: #141414;
  --manualChoose_carlevel_active_color: #141414;
  --manualChoose_carlevel_active_underline_color: #FFDD00;
  --manualChoose_carlevel_span_background_color: #FFDD00;
  --manualChoose_carlevel_span_color: #000000;
  --manualChoose_car_checked_image: url(./assets/checked_mt.png);
  --manualChoose_car_coupon_color: #FF6A00;
  --manualChoose_button_disabled_background_image: linear-gradient(98deg, #FFE74D 0%, #FFDD19 40%);
  --manualChoose_button_background_image: linear-gradient(98deg, #FFE74D 0%, #FFDD19 100%);
  --manualChoose_button_interval_color: #FF6A00;
  --manualChoose_button_count_color: #FF6A00;
  --countDown_waitingTime_color: #FF6A00;
  --runingView_distance_color: #FF6A00;
  --runingView_time_color: #FF6A00;
  --runingView_small_bell: url(./assets/small_bell_mt.png);
  --runingView_star_color: #FF6A00;
  --runingView_cancel_tip: #FF6A00;
  --order_circular_green: #42a737;
  --order_circular_red: #FF6A00;
  --order_unpaid_background_color: #FFDD00;
  --order_unpaid_color: #141414;
  --order_blue: #141414;
  --confirm_go_confirm_pay_color: #EAD86B;
  --confirm_yes_confirm_btn_color: #8A8A8A;
  --payments_tab_active_color: #141414;
  --payments_tab_line_background: #FFDD00;
  --payments_checkbox_border-color: #FFE74D;
  --payments_checkbox_background_color: #FFE74D;
  --payments_checkbox_color: #141414;
  --payments_pay_btn_background_image: linear-gradient(134deg, #FFE74D 0%, #FFDD19 100%);
  --payments_pay_btn_color: #141414;
  --payments_footbar_child_color: #141414;
  --payments_body_adm_auto_center_content: #F0F0F0;
  --orderdetails_pay_amount: #141414;
  --orderdetails_unit: #898989;
  --orderdetails_evaluate_span: #141414;
  --orderdetails_expenses: #898989;
  --orderdetails_driver_level: #FF6A00;
  --orderdetails_bule_name: #141414;
  --orderdetails_label_bule_color: #141414;
  --orderdetails_label_bule_background_color: rgba(255,221,0,0.10);
  --orderdetails_label_bule_border_color: rgba(255,221,0,1);
  --orderdetails_complaint_background_color: #F2F2F2;
  --orderdetails_complaint_span_background_color: #FF6A00;
  --orderdetails_prepaid_color: #141414;
  --orderdetails_prepaid_background_color: rgba(255,221,0,1);
  --orderdetails_paid_span_color: #FF6A00;
  --orderdetails_complaint_details_step_background_color: #141414;
  --orderdetails_confirm_btn_yes_background: rgba(255,221,0,1);
  --orderdetails_confirm_btn_yes_font_size: #141414;
  --serviceCenter_details_p_color: #141414;
  --serviceCenter_details_type: #E7E7E7;
  --serviceCenter_pay_type_color: #141414;
  --serviceCenter_pay_type_background_color: #FFDD00;
  --serviceCenter_begin_span_background_color: #42A737;
  --serviceCenter_end_span_background_color: #FF6A00;
  --serviceCenter_fee_cell_bule_color: #141414;
  --serviceCenter_fee_cell_bule_background_color: rgba(255,221,0,0.10);
  --serviceCenter_fee_cell_bule_border_color: rgba(255,221,0,1);
  --serviceCenter_badge_color: #141414;
  --complaintsDetails_fee_cell_bule_color: #141414;
  --complaintsDetails_fee_cell_bule_background_color: rgba(255,221,0,0.10);
  --complaintsDetails_fee_cell_bule_border_color: rgba(255,221,0,1);
  --expenseDetails_car_source_span: #141414;
  --expenseDetails_feedback_dev: #FF6A00;
  --expenseDetails_red_color: #FF6A00;
  --address_index_bar_background_color: #FF6A00;
  --address_cancel_color: #141414;
  --habitAddress_empty_background_image: linear-gradient(134deg, #FFE74D 0%, #FFDD19 100%);
  --habitAddress_empty_color: #141414;
  --setphone_count_background_image: linear-gradient(134deg, #FFE74D 0%, #FFDD19 100%);
  --setphone_count_color: #000000;
  --setphone_light_background_color: #F9ECA1;
  --setphone_light_color: #939393;
  --contact_span_color: #000000;
  --adm_dialog_btn_color: #898989;
  --adm_dialog_btn_bold_color: #141414;
  --adm_picker_header_button_color: #141414;
}