.none {
  width: 100%;
  padding: 0.4rem;
}

.none .message {
  font-size: 0.426667rem;
  font-weight: 500;
  color: #455060;
  padding: 0 0 0.133333rem 0;
}

.none .suggestion {
  color: #455060;
  margin-bottom: 0.4rem;
}

.none .box_time {
  width: 100%;
  background-color: #eef3f8;
  border-radius: 0.266667rem;
  padding: 0.08rem 0.213333rem 0.08rem 0.213333rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.266667rem;
}

.none .box_time span {
  font-size: 0.346667rem;
  color: #455060;
  display: inline-block;
}

.none .box_time .waitForTime {
  margin-left: 0.213333rem;
}

.none .box_time .bell {
  margin-left: 0.133333rem;
}

.none .box_time .servicePhone {
  color: #2f7dcd;
}

.none :global .adm-button {
  background-color: #2f7dcd;
  border: none;
  border-radius: 1.333333rem;
  font-weight: 400;
  margin: 0.533333rem 0 0.266667rem 0;
}