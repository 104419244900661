.body {
  font-size: 0.373333rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 0.4rem;
  background-color: #FFFFFF;
  z-index: 100;
}

.body h2,
.body h3 {
  margin: 0;
  font-weight: 500;
}

.body h3 {
  margin-top: 0.266667rem;
  font-size: 0.426667rem;
}

.body h2 {
  font-size: 0.48rem;
}

.body p {
  font-size: 0.373333rem;
}