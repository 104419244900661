.container {
  background-color: #f5f5f5;
  position: fixed;
  height: 100%;
  width: 100%;
  border: 0.026667rem solid transparent;
  overflow: scroll;
  padding: 0 0.266667rem;
  padding-bottom: env(safe-area-inset-bottom);
  background: url(../assets/scenes_bg.png) no-repeat 100% 0% #f2f3f6;
}

.container .search {
  margin: 0.4rem 0;
  position: relative;
}

.container .search .searchbtn {
  position: absolute;
  right: 0.08rem;
  padding: 0.133333rem 0.266667rem;
  top: 0.053333rem;
  background-color: #ECF1F9;
  color: #5685BB;
  border-radius: 0.133333rem;
  font-size: 0.32rem;
  z-index: 100;
}

.container .totalamount {
  font-size: 0.373333rem;
  line-height: 0.426667rem;
  padding: 0.266667rem 0;
  color: #000000;
  font-weight: 500;
  background-color: #ffffff;
  padding: 0.266667rem 0.4rem;
  display: flex;
  justify-content: space-between;
  border-radius: 0.213333rem;
}

.nodata {
  width: 100%;
  margin-top: 6.133333rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.nodata .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #999999;
}