.container .carlevel {
  background-color: #F1F3F5;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
}

.container .carlevel .beforchoose {
  width: 2.133333rem;
  padding: 0.4rem;
  flex-shrink: 0;
  flex-grow: 0;
}

.container .carlevel .beforchoose>div {
  height: 1.333333rem;
  padding: 0.213333rem;
  text-align: center;
  border-radius: 0.266667rem;
  background-color: #eef3f8;
  color: #3a4656;
  font-size: 0.32rem;
}

.container .carlevel .beforchoose>div.enable {
  background-image: var(--button_background_color);
  color: var(--button_color);
}

.container .carlevel .beforchoose_en {
  width: 1.226667rem;
  padding: 0.4rem 0 0.4rem 0;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0 0.053333rem 0 0.053333rem;
}

.container .carlevel .beforchoose_en>div {
  padding: 0.213333rem;
  text-align: center;
  border-radius: 0.266667rem;
  background-color: #eef3f8;
  color: #3a4656;
  font-size: 0.32rem;
}

.container .carlevel .beforchoose_en>div.enable {
  background-color: #2F7DCD;
  color: #ffffff;
}

.container .carlevel .level {
  padding: 0 0.533333rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.333333rem;
  transition: color 0.3s, font-weight 0.3s, padding 0.3s, -webkit-transform 0.3s;
  transition: color 0.3s, font-weight 0.3s, transform 0.3s, padding 0.3s;
  transition: color 0.3s, font-weight 0.3s, transform 0.3s, padding 0.3s, -webkit-transform 0.3s;
}

.container .carlevel .level .name {
  font-size: 0.32rem;
  color: #141414;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.container .carlevel .level .name span {
  background: var(--estimate_level_background);
  color: var(--manualChoose_carlevel_active_color);
  letter-spacing: 0;
  font-weight: 500;
  border-radius: 0.133333rem;
  width: 0.373333rem;
  height: 0.32rem;
  display: inline-block;
  text-align: center;
  font-size: 0.266667rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .carlevel .level .tip {
  font-size: 0.32rem;
  color: #141414;
  line-height: 0.32rem;
  margin-top: 0.133333rem;
}

.container .carlevel .level.active {
  background-color: #ffffff;
  padding: 0.533333rem;
}

.container .carlevel .level.active .name {
  color: var(--manualChoose_carlevel_active_color);
  font-size: 0.32rem;
  font-weight: 600;
}

.container .carlevel .level.active .tip {
  color: var(--manualChoose_carlevel_active_color);
  font-size: 0.333333rem;
}

.container .carlevel .level.active .underline {
  background-color: var(--manualChoose_carlevel_active_underline_color);
  width: 0.08rem;
  height: 0.4rem;
  position: absolute;
  left: 0;
  top: 0.48rem;
}

.container .carlevel .level_en {
  padding: 0 0.133333rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.333333rem;
  transition: color 0.3s, font-weight 0.3s, padding 0.3s, -webkit-transform 0.3s;
  transition: color 0.3s, font-weight 0.3s, transform 0.3s, padding 0.3s;
  transition: color 0.3s, font-weight 0.3s, transform 0.3s, padding 0.3s, -webkit-transform 0.3s;
}

.container .carlevel .level_en .name {
  font-size: 0.32rem;
  color: #141414;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.container .carlevel .level_en .name span {
  background: var(--estimate_level_background);
  color: var(--manualChoose_carlevel_active_color);
  letter-spacing: 0;
  font-weight: 500;
  border-radius: 0.133333rem;
  width: 0.373333rem;
  height: 0.32rem;
  display: inline-block;
  text-align: center;
  font-size: 0.266667rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .carlevel .level_en .tip {
  font-size: 0.32rem;
  color: #141414;
  line-height: 0.32rem;
  margin-top: 0.133333rem;
}

.container .carlevel .level_en.active {
  background-color: #ffffff;
  padding: 0.533333rem;
}

.container .carlevel .level_en.active .name {
  color: #5685BB;
  font-size: 0.32rem;
  font-weight: 600;
}

.container .carlevel .level_en.active .tip {
  color: #2F7DCD;
}

.container .carlevel .level_en.active .underline {
  background-color: var(--manualChoose_carlevel_active_underline_color);
  width: 0.08rem;
  height: 0.4rem;
  position: absolute;
  left: 0;
  top: 0.48rem;
}

.container .carlevel .level_en.activeEn {
  background-color: #ffffff;
  padding: 0.133333rem;
}

.container .carlevel .level_en.activeEn .name {
  color: #5685BB;
  font-size: 0.32rem;
  font-weight: 600;
}

.container .carlevel .level_en.activeEn .tip {
  color: #2F7DCD;
}

.container .carlevel .level_en.activeEn .underline {
  background-color: var(--manualChoose_carlevel_active_underline_color);
  width: 0.08rem;
  height: 0.4rem;
  position: absolute;
  left: 0;
  top: 0.48rem;
}

.container .carlevel_mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.container .carlevel_chartered {
  background-color: #ffffff;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
}

.container .carlevel_chartered .beforchoose {
  width: 2.133333rem;
  padding: 0.4rem;
  flex-shrink: 0;
  flex-grow: 0;
}

.container .carlevel_chartered .beforchoose>div {
  height: 1.333333rem;
  padding: 0.213333rem;
  text-align: center;
  border-radius: 0.266667rem;
  background-color: #eef3f8;
  color: #3a4656;
  font-size: 0.32rem;
}

.container .carlevel_chartered .beforchoose>div.enable {
  background-color: #2F7DCD;
  color: #ffffff;
}

.container .carlevel_chartered .level {
  padding: 0 0.533333rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.333333rem;
  transition: color 0.3s, font-weight 0.3s, padding 0.3s, -webkit-transform 0.3s;
  transition: color 0.3s, font-weight 0.3s, transform 0.3s, padding 0.3s;
  transition: color 0.3s, font-weight 0.3s, transform 0.3s, padding 0.3s, -webkit-transform 0.3s;
}

.container .carlevel_chartered .level .name {
  font-size: 0.373333rem;
  color: #141414;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.container .carlevel_chartered .level .name span {
  background: var(--estimate_level_background);
  color: var(--manualChoose_carlevel_active_color);
  letter-spacing: 0;
  font-weight: 500;
  border-radius: 0.133333rem;
  width: 0.373333rem;
  height: 0.32rem;
  display: inline-block;
  text-align: center;
  font-size: 0.266667rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .carlevel_chartered .level .tip {
  font-size: 0.32rem;
  color: #141414;
  line-height: 0.32rem;
  margin-top: 0.133333rem;
}

.container .carlevel_chartered .level.active {
  background-color: #ffffff;
  padding: 0.533333rem;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.container .carlevel_chartered .level.active .name {
  color: var(--manualChoose_carlevel_active_color);
  font-size: 0.373333rem;
}

.container .carlevel_chartered .level.active .tip {
  color: var(--manualChoose_carlevel_active_color);
  font-size: 0.333333rem;
}

.container .carlevel_chartered .level.active .underline {
  background-color: var(--manualChoose_carlevel_active_underline_color);
  width: 0.08rem;
  height: 0.4rem;
  position: absolute;
  left: 0.08rem;
  top: 0.48rem;
}

.container .carlevel_chartered .level_en {
  padding: 0 0.133333rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.333333rem;
  transition: color 0.3s, font-weight 0.3s, padding 0.3s, -webkit-transform 0.3s;
  transition: color 0.3s, font-weight 0.3s, transform 0.3s, padding 0.3s;
  transition: color 0.3s, font-weight 0.3s, transform 0.3s, padding 0.3s, -webkit-transform 0.3s;
}

.container .carlevel_chartered .level_en .name {
  font-size: 0.373333rem;
  color: #141414;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.container .carlevel_chartered .level_en .name span {
  background: var(--estimate_level_background);
  color: var(--manualChoose_carlevel_active_color);
  letter-spacing: 0;
  font-weight: 500;
  border-radius: 0.133333rem;
  width: 0.373333rem;
  height: 0.32rem;
  display: inline-block;
  text-align: center;
  font-size: 0.266667rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .carlevel_chartered .level_en .tip {
  font-size: 0.32rem;
  color: #141414;
  line-height: 0.32rem;
  margin-top: 0.133333rem;
}

.container .carlevel_chartered .level_en.active {
  background-color: #ffffff;
  padding: 0.533333rem;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.container .carlevel_chartered .level_en.active .name {
  color: #2F7DCD;
  font-size: 0.373333rem;
}

.container .carlevel_chartered .level_en.active .tip {
  color: #2F7DCD;
}

.container .carlevel_chartered .level_en.active .underline {
  background-color: var(--manualChoose_carlevel_active_underline_color);
  width: 0.08rem;
  height: 0.4rem;
  position: absolute;
  left: 0.08rem;
  top: 0.48rem;
}

.container .carlevel_chartered .level_en.activeEn {
  background-color: #ffffff;
  padding: 0.133333rem;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.container .carlevel_chartered .level_en.activeEn .name {
  color: #2F7DCD;
  font-size: 0.373333rem;
}

.container .carlevel_chartered .level_en.activeEn .tip {
  color: #2F7DCD;
}

.container .carlevel_chartered .level_en.activeEn .underline {
  background-color: var(--manualChoose_carlevel_active_underline_color);
  width: 0.08rem;
  height: 0.4rem;
  position: absolute;
  left: 0.08rem;
  top: 0.48rem;
}

.container .cars_wrap {
  position: relative;
  flex: 1 1;
}

.container .cars_wrap .notRecommended {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2.986667rem;
  padding: 0.133333rem 0.266667rem;
  color: #ff9611;
  background-color: #fff6eb;
}

.container .cars {
  background-color: #ffffff;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.533333rem;
  scroll-behavior: smooth;
  padding-bottom: 5.333333rem;
}

.container .cars .car_smart {
  padding: 0 0.266667rem 0.32rem 0.266667rem;
}

.container .cars .car_smart_padding_top {
  padding-top: 0.32rem;
  border-bottom: solid 0.026667rem #EEF3F8;
}

.container .cars .car_smart_border_bottom {
  padding-top: 0.32rem;
}

.container .cars .carpannel {
  border-top: solid 0.026667rem #EEF3F8;
  padding: 0.213333rem 0.266667rem;
}

.container .cars .carpannel::-webkit-scrollbar {
  display: none;
}

.container .cars .carpannel .head {
  height: 1.066667rem;
  line-height: 1.066667rem;
  font-size: 0.4rem;
  color: #141414;
  display: flex;
  font-weight: 500;
}

.container .cars .carpannel .head>div:nth-child(1) {
  flex: auto;
  display: flex;
  align-items: center;
}

.container .cars .carpannel .head>div:nth-child(2) {
  flex-shrink: 0;
  text-align: right;
}

.container .cars .carpannel .head>div:nth-child(3) {
  flex-shrink: 0;
  text-align: right;
  padding: 0.266667rem 0 0.266667rem 0.133333rem;
}

.container .cars .carpannel .head .deduction_key {
  font-weight: normal;
  font-size: 0.32rem;
  margin-left: 0.266667rem;
  display: inline-block;
}

.container .cars .carpannel .head .deduction_value {
  font-size: 0.32rem;
  display: inline-block;
  margin-left: 0.08rem;
}

.container .cars .carpannel .head .valuation {
  font-weight: normal !important;
  font-size: 0.346667rem;
  margin-left: 0.266667rem;
  color: #999999;
}

.container .cars .carpannel .head .rightness {
  font-weight: normal !important;
  font-size: 0.373333rem;
  margin-left: 0.266667rem;
  color: #5685BB;
}

.container .cars .carpannel .head .tip {
  font-size: 0.32rem;
  color: #3C4858;
  margin-left: 0.266667rem;
  flex-shrink: 0;
}

.container .cars .carpannel .carlist {
  height: auto;
}

.container .cars .carpannel .carlist .car {
  min-height: 1.2rem;
  display: flex;
  font-size: 0.373333rem;
  color: #141414;
}

.container .cars .carpannel .carlist .car>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container .cars .carpannel .carlist .car>:nth-child(1) {
  width: 1.013333rem;
  flex-shrink: 0;
}

.container .cars .carpannel .carlist .car>:nth-child(1) img {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  overflow: hidden;
  border: solid 0.026667rem #EEF3F8;
}

.container .cars .carpannel .carlist .car>:nth-child(2) {
  flex: auto;
}

.container .cars .carpannel .carlist .car>:nth-child(3) {
  min-width: 3.2rem;
  flex-shrink: 0;
  text-align: right;
  font-size: 0.266667rem;
  color: #9DA3AB;
}

.container .cars .carpannel .carlist .car>:nth-child(3) span {
  margin: 0 0.08rem;
}

.container .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(1) .span_cn {
  font-size: 0.48rem;
  color: #141414;
  font-weight: 500;
  vertical-align: sub;
}

.container .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(1) .span_en {
  font-size: 0.426667rem;
  color: #141414;
  font-weight: 500;
  vertical-align: sub;
}

.container .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(2) {
  height: auto;
  line-height: 0.533333rem;
  color: #a3a8b0;
}

.container .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(2) span {
  color: var(--manualChoose_car_coupon_color);
}

.container .cars .carpannel .carlist .car>:nth-child(4) {
  flex-shrink: 0;
  align-items: flex-end;
  padding: 0.266667rem 0 0.266667rem 0.133333rem;
}

.container .cars .car_bottom {
  margin-bottom: 0.266667rem;
}

.container .cars .car_type {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container .cars .car_type .left {
  display: flex;
  flex-direction: column;
}

.container .cars .car_type .left .car_source {
  font-size: 0.373333rem;
  font-weight: 500;
}

.container .cars .car_type .left .car_desc {
  font-size: 0.266667rem;
  color: #9DA3AB;
  font-weight: 400;
  margin-top: 0.053333rem;
}

.container .cars .car_type .left .car_select {
  color: #9DA3AB;
}

.container .cars .car_type .left .car_count {
  font-family: PingFangSC-Medium;
  font-size: 0.293333rem;
  color: var(--estimate_car_count_color);
  font-weight: 500;
}

.container .cars .car_type .right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .cars .car_type .right .car_estimate {
  font-size: 0.266667rem;
  color: #9DA3AB;
}

.container .cars .car_type .right .car_estimate .span_cn {
  font-size: 0.48rem;
  color: #141414;
  font-weight: 500;
  vertical-align: sub;
}

.container .cars .car_type .right .car_estimate .span_en {
  font-size: 0.426667rem;
  color: #141414;
  font-weight: 500;
  vertical-align: sub;
}

.container .cars .car_type .right>div:nth-child(2) {
  flex-shrink: 0;
  text-align: right;
  padding: 0.266667rem 0 0.266667rem 0.133333rem;
}

.container .btncont {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #FFFFFF;
  box-shadow: 0 0 0.16rem 0 rgba(60,72,88,0.2);
  height: calc(2.986667rem + env(safe-area-inset-bottom));
  padding: 0.533333rem 0.4rem 0.533333rem 0.4rem;
  padding-bottom: env(safe-area-inset-bottom);
}

.container .btncont .inner_phone {
  text-align: center;
  height: 0.8rem;
  line-height: 0.666667rem;
  padding: 0.08rem 0;
  font-weight: bolder;
  display: block;
}

.container .btncont .inner_phone span {
  background: url(../../assets/backbtn.png) 95% center no-repeat;
  background-size: auto 90%;
}

.container .btncont .cell {
  display: flex;
  justify-content: space-around;
}

.container .btncont .selectType {
  text-align: center;
  padding: 0.266667rem 0;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .btncont .selectType span {
  margin-right: 0.106667rem;
  font-size: 0.32rem;
  font-weight: normal;
}

.container .btncont .inner_phone_none {
  display: none;
}

.container .btncont .btn {
  border-radius: 0.266667rem;
  background-image: var(--manualChoose_button_disabled_background_image);
  padding-bottom: 0.16rem;
  padding: 0 0.213333rem 0.213333rem 0.213333rem;
}

.container .btncont .btn.active {
  background-image: var(--manualChoose_button_background_image);
  border-radius: 0.266667rem;
}

.container .btncont .btn>:nth-child(1) {
  font-size: 0.533333rem;
  color: var(--button_color);
  text-align: center;
  height: 0.933333rem;
  line-height: 1.066667rem;
}

.container .btncont .btn>:nth-child(1) span {
  font-size: 0.32rem;
}

.container .btncont .btn>:nth-child(1) span span {
  color: var(--manualChoose_button_interval_color);
}

.container .btncont .btn>:nth-child(2) {
  font-size: 0.32rem;
  color: var(--button_color);
  text-align: center;
}

.container .btncont .btn>:nth-child(2) span {
  color: var(--manualChoose_button_count_color);
}

.container .btncont .btn_bus {
  height: 1.6rem;
  border-radius: 0.266667rem;
  background-image: var(--manualChoose_button_disabled_background_image);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .btncont .btn_bus.active {
  background-image: var(--manualChoose_button_background_image);
}

.container .btncont .btn_bus>:nth-child(1) {
  font-size: 0.533333rem;
  color: var(--button_color);
  text-align: center;
}

.container .btncont .btn_bus>:nth-child(1) span {
  font-size: 0.32rem;
}

.container .btncont .btn_bus>:nth-child(1) span span {
  color: var(--manualChoose_button_interval_color);
}

.container .btncont_top {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #FFFFFF;
  box-shadow: 0 0 0.16rem 0 rgba(60,72,88,0.2);
  height: calc(2.986667rem + env(safe-area-inset-bottom));
  padding: 0 0.4rem 0.533333rem 0.4rem;
  padding-bottom: env(safe-area-inset-bottom);
}

.container .btncont_top .inner_phone {
  text-align: center;
  height: 0.8rem;
  line-height: 0.666667rem;
  padding: 0.08rem 0;
  font-weight: bolder;
  display: block;
}

.container .btncont_top .inner_phone span {
  background: url(../../assets/backbtn.png) 95% center no-repeat;
  background-size: auto 90%;
}

.container .btncont_top .cell {
  display: flex;
  justify-content: space-around;
}

.container .btncont_top .selectType {
  text-align: center;
  padding: 0.266667rem 0;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .btncont_top .selectType span {
  margin-right: 0.106667rem;
  font-size: 0.32rem;
  font-weight: normal;
}

.container .btncont_top .inner_phone_none {
  display: none;
}

.container .btncont_top .btn {
  border-radius: 0.266667rem;
  background-image: var(--manualChoose_button_disabled_background_image);
  padding: 0 0.213333rem 0.213333rem 0.213333rem;
}

.container .btncont_top .btn.active {
  background-image: var(--manualChoose_button_background_image);
}

.container .btncont_top .btn>:nth-child(1) {
  font-size: 0.533333rem;
  color: var(--button_color);
  text-align: center;
  height: 0.933333rem;
  line-height: 1.066667rem;
}

.container .btncont_top .btn>:nth-child(1) span {
  font-size: 0.32rem;
}

.container .btncont_top .btn>:nth-child(1) span span {
  color: var(--manualChoose_button_interval_color);
}

.container .btncont_top .btn>:nth-child(2) {
  font-size: 0.32rem;
  color: var(--button_color);
  text-align: center;
}

.container .btncont_top .btn>:nth-child(2) span {
  color: var(--manualChoose_button_count_color);
}

.container .btncont_top .btn_bus {
  height: 1.6rem;
  border-radius: 0.266667rem;
  background-image: var(--manualChoose_button_disabled_background_image);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .btncont_top .btn_bus.active {
  background-image: var(--manualChoose_button_background_image);
}

.container .btncont_top .btn_bus>:nth-child(1) {
  font-size: 0.533333rem;
  color: var(--button_color);
  text-align: center;
}

.container .btncont_top .btn_bus>:nth-child(1) span {
  font-size: 0.32rem;
}

.container .btncont_top .btn_bus>:nth-child(1) span span {
  color: var(--manualChoose_button_interval_color);
}

.container .cbx>div {
  width: 0.533333rem;
  height: 0.533333rem;
  border: solid 0.026667rem #C8C8CD;
  border-radius: 0.266667rem;
}

.container .cbx.checked>div {
  background: var(--manualChoose_car_checked_image) no-repeat 0% 0%;
  background-size: 100% 100%;
  border: none;
}

.container .car_box {
  display: flex;
  height: 68vh;
}

.container .smart_wrap .smart_car {
  padding: 0.48rem 0.48rem 0.266667rem 0.48rem;
  display: flex;
  justify-content: space-between;
}

.container .smart_wrap .smart_car .select {
  font-family: PingFangSC-Medium;
  font-size: 0.4rem;
  color: #141414;
  font-weight: 400;
}

.container .smart_wrap .smart_car .all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .smart_wrap .smart_car .all .title {
  font-family: PingFangSC-Medium;
  font-size: 0.4rem;
  color: #141414;
  font-weight: 400;
  margin: 0;
  margin-right: 0.213333rem;
}

.container .smart_wrap .smart_car_btn {
  padding: 0.4rem;
  box-shadow: 0 0 0.16rem 0 rgba(60,72,88,0.2);
}

.container .smart_car_list {
  height: 6.24rem;
  padding: 0 0.48rem 0.266667rem 0.48rem;
  overflow: scroll;
}

.container .smart_car_list .car {
  min-height: 1.2rem;
  display: flex;
  font-size: 0.373333rem;
  color: #141414;
}

.container .smart_car_list .car>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container .smart_car_list .car>:nth-child(1) {
  width: 1.013333rem;
  flex-shrink: 0;
}

.container .smart_car_list .car>:nth-child(1) img {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  overflow: hidden;
  border: solid 0.026667rem #EEF3F8;
}

.container .smart_car_list .car>:nth-child(2) {
  flex: auto;
}

.container .smart_car_list .car>:nth-child(3) {
  min-width: 3.2rem;
  flex-shrink: 0;
  text-align: right;
  font-size: 0.266667rem;
  color: #9DA3AB;
}

.container .smart_car_list .car>:nth-child(3) span {
  margin: 0 0.08rem;
}

.container .smart_car_list .car>:nth-child(3)>div:nth-child(1) span {
  font-size: 0.48rem;
  color: #141414;
  font-weight: 500;
  vertical-align: sub;
}

.container .smart_car_list .car>:nth-child(3)>div:nth-child(2) {
  height: auto;
  line-height: 0.533333rem;
  color: #a3a8b0;
}

.container .smart_car_list .car>:nth-child(3)>div:nth-child(2) span {
  color: var(--manualChoose_car_coupon_color);
}

.container .smart_car_list .car>:nth-child(4) {
  width: 0.8rem;
  flex-shrink: 0;
  align-items: flex-end;
}

.box {
  text-align: center;
}

.box .msg {
  font-size: 0.32rem;
  margin-bottom: 0;
  text-align: left;
}

.rules {
  border-bottom: 0.093333rem solid var(--button_border_color);
}

.title {
  display: inline-block;
  margin: 0.213333rem 0;
  font-size: 0.346667rem;
}

.container :global {
  position: relative;
}

.container :global .adm-modal-body .adm-space {
  display: none;
}

.container :global .adm-modal-body .adm-modal-title {
  margin-bottom: 0;
  font-size: 0.453333rem;
}

.container :global tr td,
.container :global tr th {
  border: #c4c4c4 0.026667rem solid;
}

.container :global td {
  font-size: 0.32rem;
  padding: 0.133333rem;
}

.container :global th {
  font-size: 0.346667rem;
}

.container :global th span {
  font-size: 0.32rem;
  font-weight: normal;
}

.container :global tr td:first-child {
  color: #5997d7;
  font-weight: 500;
}

.container :global .adm-modal-wrap {
  min-width: 95% !important;
}

.container :global .adm-popup-body-position-bottom {
  border-top-left-radius: 0.266667rem !important;
  border-top-right-radius: 0.266667rem !important;
}

.set_phone {
  padding: 0.133333rem 0.4rem 0.4rem 0.4rem;
}

.set_phone .title {
  text-align: center;
  font-size: 0.426667rem;
  font-weight: bolder;
  display: block;
}

.set_phone .phone {
  height: 1.2rem;
  display: flex;
  background-color: #f9f9f9;
  border-radius: 0.266667rem;
  margin-bottom: 0.4rem;
  padding: 0 0.266667rem;
}

.cell :global .adm-image {
  margin-right: 0.08rem;
}

.cars :global .adm-mask {
  z-index: 100;
}

.cars :global .adm-modal-wrap {
  z-index: 101;
}

.cars :global .adm-modal {
  z-index: 1002;
}

.btn_details {
  font-size: 0.373333rem;
  color: #000000;
  font-weight: 500;
}

.btn_close {
  font-size: 0.373333rem;
  color: #898989;
  font-weight: 400;
}