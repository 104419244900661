.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f7f7f7;
  border: 0.026667rem solid transparent;
  overflow-y: auto;
}

.container .noOrder {
  height: 25vh;
  margin: auto;
  padding-top: 20vh;
  text-align: center;
}

.container .noOrder>div:nth-child(1) img {
  width: 20%;
}

.container .noOrder>div:nth-child(2) {
  margin-top: 0.4rem;
  font-size: 0.373333rem;
  color: #aaaaaa;
}

.container .trip {
  border-top: solid 0.026667rem #d7d7d7;
  border-bottom: solid 0.026667rem #d7d7d7;
  margin-top: 0.533333rem;
  background-color: #ffffff;
  padding: 0 0.266667rem;
  color: #a3a3a3;
}

.container .trip .title {
  height: 1.066667rem;
  line-height: 1.066667rem;
  display: flex;
  border-bottom: solid 0.026667rem #d7d7d7;
}

.container .trip .title>div:nth-child(1) {
  flex: auto;
}

.container .trip .title>div:last-child {
  color: #000000;
  text-align: right;
  display: flex;
  align-items: center;
}

.container .trip .title .status img {
  width: 0.32rem;
  height: 0.32rem;
  margin-left: 0.133333rem;
}

.container .trip .body {
  padding: 0.266667rem 0;
  line-height: 0.533333rem;
}