.container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.container .tips {
  position: absolute;
  left: 10vw;
  width: 80vw;
  top: 20vh;
  text-align: center;
  font-weight: bold;
}

.container .tips img {
  width: 75%;
}

.container .tips>div:nth-child(2) {
  font-size: 0.346667rem;
  text-align: center;
  color: #000000;
  margin-top: 0.266667rem;
}

.container .tips>div:last-child {
  font-size: 0.346667rem;
  text-align: center;
  margin-top: 0.266667rem;
}

.container .tips>div:last-child span {
  color: #1b61c5;
}