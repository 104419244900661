.container {
  display: flex;
  flex-direction: column;
}

.container .logo {
  text-align: center;
  padding: 0.666667rem 0 0 0;
}

.container .logo img {
  width: 21%;
}

.container .wxs {
  background-color: #e6eeff;
  color: #537fdc;
  text-align: center;
  padding: 0.133333rem 0.4rem;
  display: inline;
  margin: auto;
  margin-top: 0.666667rem;
  border-radius: 0.4rem;
}

.container .form {
  padding: 0.4rem 0;
}

.container .form :global .adm-list-item-content {
  padding: 0;
  margin-right: 0.32rem;
  border-bottom: solid 0.026667rem #eeeeee;
}

.container .form .verificationCode button {
  width: 2.4rem;
  font-size: 0.32rem;
}

.container .form .captcha {
  width: 2.666667rem;
}

.container .form .captcha img {
  width: 100%;
  margin-top: 0.133333rem;
}

.container .btn {
  padding: 0.4rem 0.32rem;
}

.container .register {
  padding: 0.4rem 0.4rem 0 0.4rem;
}

.container .register>div {
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 2.666667rem;
  background-color: #edf1f6;
  height: 0.96rem;
  border-radius: 0.48rem;
  line-height: 0.96rem;
  justify-content: center;
  align-items: center;
}

.container .register>div img {
  width: 0.48rem;
  height: 0.48rem;
}

.container .register>div span {
  padding-left: 0.133333rem;
  color: #5279ab;
}

.container .agreement {
  padding: 0 0.4rem;
}

.container .agreement span {
  color: #5279ab;
}

.container .LoginMode {
  text-align: center;
  color: #1677ff;
  font-size: 0.4rem;
}