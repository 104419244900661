.container {
  background: #ffffff;
  box-shadow: 0 0 0.426667rem 0 rgba(60,72,88,0.1);
  border-radius: 0.426667rem;
  overflow: hidden;
}

.container .head {
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.4rem;
  overflow-x: auto;
}

.container .head .tabs {
  margin: 0 0.4rem;
  height: 100%;
  white-space: nowrap;
}

.container .head .tabs .tab {
  text-align: left;
  display: inline-block;
  margin-right: 0.666667rem;
  color: #3c4858;
  position: relative;
}

.container .head .tabs .tab.active {
  font-weight: bold;
}

.container .head .tabs .tab.active .underline {
  background: var(--servertype_underline);
  border-radius: 0.053333rem;
  height: 0.106667rem;
  width: 0.533333rem;
  position: absolute;
  bottom: 0.133333rem;
}

.container .body .line {
  display: flex;
  min-height: 1.2rem;
  position: relative;
}

.container .body .line .icon {
  padding: 0 0.266667rem 0 0.4rem;
}

.container .body .line .icon img {
  width: 0.4rem;
  height: 0.4rem;
  margin-top: 0.426667rem;
}

.container .body .line .icon .index {
  width: 0.4rem;
  height: 0.4rem;
  margin-top: 0.426667rem;
  text-align: center;
  background: #e5e5e5;
  border-radius: 50%;
  color: #3c4858;
}

.container .body .line .content {
  flex: auto;
  border-bottom: solid 0.026667rem #d9d9d9;
  font-size: 0.373333rem;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.container .body .line .content>div:nth-child(1) {
  flex-shrink: 0;
}

.container .body .line .content .where {
  color: #9b9b9b;
}

.container .body .line .content .multipleUses {
  position: absolute;
  bottom: 0;
  left: 0.533333rem;
  z-index: 5;
  color: #29aa69;
  font-size: 0.266667rem;
  font-weight: bolder;
}

.container .body .line .content.sence {
  display: flex;
}

.container .body .line .content.sence .name {
  flex: auto;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.container .body .line .content.sence .time {
  flex: auto;
}

.container .body .line .content.sence .more {
  padding: 0 0.4rem 0 0.133333rem;
  text-align: right;
  margin-top: 0.106667rem;
}

.container .body .line .content.sence .more img {
  width: 0.186667rem;
}

.container .body .line .content.sence .tip {
  color: #9b9b9b;
  font-size: 0.32rem;
  line-height: 0.373333rem;
  position: relative;
}

.container .body .line .wrap {
  position: absolute;
  right: 0.266667rem;
  padding: 0.213333rem;
  overflow: hidden;
}

.container .body .line .wrap .add_pathway {
  border-radius: 0.133333rem;
  font-size: 0.373333rem;
  background: #ffffff;
  padding: 0.133333rem 0.266667rem;
  -webkit-filter: drop-shadow(0 0 0.08rem rgba(0,0,0,0.1));
  filter: drop-shadow(0 0 0.08rem rgba(0,0,0,0.1));
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container .body .line .wrap .add_pathway img {
  height: 0.453333rem;
  margin-right: 0.133333rem;
}

.container .body .line .wrap .add_pathway_grey {
  border-radius: 0.133333rem;
  font-size: 0.373333rem;
  background: var(--main_endaddr_add_pathway_grey_background);
  padding: 0.133333rem 0.266667rem;
  -webkit-filter: drop-shadow(0 0 0.08rem rgba(0,0,0,0.1));
  filter: drop-shadow(0 0 0.08rem rgba(0,0,0,0.1));
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--main_endaddr_add_pathway_grey_color);
}

.container .body .line .wrap .add_pathway_grey img {
  height: 0.453333rem;
  margin-right: 0.133333rem;
}

.container .body .line .close {
  position: absolute;
  top: 0;
  right: 0.186667rem;
  bottom: 0;
  width: 1.066667rem;
  background: url(../../assets/pathway_del.png) 50% 50% no-repeat;
  background-size: 50%;
}

.container .body>div:not(:only-child):last-of-type .content {
  border-bottom: none;
}

.scene {
  background-color: #f5f5f5;
  height: 100%;
  width: 100vw;
  overflow: auto;
  padding: 0 0.4rem;
  background: url(../../assets/scenes_bg.png) no-repeat 100% 0% #f2f3f6;
}

.scene .search {
  margin: 0.4rem 0;
  position: relative;
}

.scene .search .searchbtn {
  position: absolute;
  right: 0.08rem;
  padding: 0.133333rem 0.266667rem;
  top: 0.053333rem;
  background-color: #ECF1F9;
  color: #5685BB;
  border-radius: 0.133333rem;
  font-size: 0.32rem;
  z-index: 100;
}

.scene .totalamount {
  font-size: 0.373333rem;
  line-height: 0.426667rem;
  padding: 0.266667rem 0;
  color: #000000;
  font-weight: 500;
  background-color: #ffffff;
  padding: 0.266667rem 0.4rem;
  display: flex;
  justify-content: space-between;
  border-radius: 0.213333rem;
}

.scene .null_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6.666667rem;
  flex-direction: column;
  color: #999999;
}

.scene .cell_date {
  min-width: 1.813333rem;
}

.air {
  padding: 0.533333rem 0.533333rem 1.066667rem 0.533333rem;
  position: relative;
}

.air .title {
  font-size: 0.533333rem;
}

.air .ok {
  color: #2099ff;
  font-size: 0.373333rem;
  position: absolute;
  right: 0.533333rem;
  top: 0.533333rem;
}

.air .ok.disable {
  color: #c9c9c9;
}

.air .tip {
  font-size: 0.346667rem;
  margin-top: 0.266667rem;
}

.air .input {
  margin-top: 0.266667rem;
}

.air .input input {
  width: 100%;
  height: 0.8rem;
  line-height: 0.8rem;
  text-indent: 0.266667rem;
  border: none;
  border: solid 0.026667rem #d9d9d9;
  border-radius: 0.133333rem;
  font-size: 0.373333rem;
}

.container :global .adm-picker-view-column-item-label {
  overflow: unset;
  text-overflow: unset;
  white-space: nowrap;
}