.train {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
}

.train>div:nth-child(1) {
  padding: 0.4rem 0.4rem 0 0.4rem;
}

.train>div:nth-child(2) {
  padding: 0 0.4rem 0.4rem 0.4rem;
  flex: auto;
  overflow: auto;
}

.train .trainInfo {
  background-color: #ffffff;
  border-radius: 0.133333rem;
}

.train .trainInfo .content {
  height: 1.2rem;
  display: flex;
}

.train .trainInfo .content .icon {
  padding: 0 0.266667rem 0 0.4rem;
}

.train .trainInfo .content .icon img {
  width: 0.493333rem;
  height: 0.493333rem;
  margin-top: 0.32rem;
}

.train .trainInfo .content .input {
  flex: auto;
  display: flex;
  padding-right: 0.533333rem;
}

.train .trainInfo .content .input input {
  flex-grow: 1;
  font-size: 0.426667rem;
}

.train .trainInfo .line {
  border-bottom: 0.026667rem solid #f5f8fb;
}

.train .trainInfo input {
  border: none;
}

.train .btn {
  margin: 0.266667rem 0 0.533333rem 0;
}

.train .btn .searchbtn {
  height: 1.066667rem;
  background-color: #1677ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.133333rem;
  color: #ffffff;
  font-size: 0.426667rem;
}

.train .trainitem {
  background-color: #ffffff;
  border-radius: 0.133333rem;
  padding: 0.266667rem;
  font-size: 0.32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.266667rem;
}

.train .trainitem>div {
  flex: 1 1;
}

.train .trainitem>div:nth-child(2) {
  text-align: center;
}

.train .trainitem>div:nth-child(3) {
  display: flex;
  flex-direction: row-reverse;
}

.train .trainitem>div:nth-child(3) .usebtn {
  background-color: #1677ff;
  color: #ffffff;
  padding: 0.08rem 0.213333rem;
  border-radius: 0.533333rem;
}

.train .trainitem:last-child {
  margin-bottom: 0;
}