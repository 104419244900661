.wrap {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
}

.wrap .box_details {
  width: 100%;
  height: 100%;
}

.wrap .box_details .popupContainer {
  margin: 0 0.266667rem;
}

.wrap .box_details .popupContainer .cost_description {
  font-weight: 600;
  font-size: 0.373333rem;
  padding: 0.266667rem 0;
  border-bottom: 0.026667rem solid #edeff1;
}

.wrap .box_details .popupContainer .pay_description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.373333rem;
  font-weight: 500;
  padding: 0.266667rem 0 0 0;
}

.wrap .box_details .popupContainer .illustrate {
  color: #9B9B9B;
  margin-top: 0.106667rem;
}

.wrap .box_details .popupContainer .box {
  padding: 0.213333rem;
  border-radius: 0.213333rem;
  background-color: #F9F9F9;
  margin-top: 0.213333rem;
}

.wrap .box_details .popupContainer .box .cell {
  display: flex;
  justify-content: space-between;
}

.wrap .box_details .popupContainer .box .cell span {
  font-size: 0.346667rem;
}

.wrap .box_details .popupContainer .box .cell .mark_color {
  color: #FD822A;
  font-weight: 500;
}

.wrap .box_details .popupContainer .total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.373333rem;
  font-weight: 600;
  padding: 0.266667rem 0 0 0;
}

.wrap .box_details .order_detalis {
  width: 100%;
  position: absolute;
  z-index: 111111;
  bottom: 0;
}

.wrap .box_details .order_detalis .details {
  width: 100%;
  padding: 0 0.266667rem 0.266667rem 0.266667rem;
}

.wrap .box_details .order_detalis .details .head .head_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrap .box_details .order_detalis .details .head .head_top div {
  display: flex;
  align-items: center;
}

.wrap .box_details .order_detalis .details .head .cancel_state {
  font-size: 0.453333rem;
  font-weight: 500;
  margin-bottom: 0.266667rem;
}

.wrap .box_details .order_detalis .details .head .prepaid {
  border-radius: 0.133333rem;
  background: var(--orderdetails_prepaid_background_color);
  width: -webkit-max-content;
  width: max-content;
  font-size: 0.32rem;
  padding: 0.08rem 0.213333rem 0.08rem 0.213333rem;
  color: var(--orderdetails_prepaid_color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.16rem;
  margin-top: 0.133333rem;
}

.wrap .box_details .order_detalis .details .head .prepaid span {
  margin-right: 0.106667rem;
}

.wrap .box_details .order_detalis .details .title {
  font-size: 0.373333rem;
  color: #3C4858;
  font-weight: 500;
}

.wrap .box_details .order_detalis .details .pay_amount {
  font-size: 0.48rem;
  font-weight: 500;
  color: var(--orderdetails_pay_amount);
}

.wrap .box_details .order_detalis .details .unit {
  font-size: 0.373333rem;
  color: var(--orderdetails_unit);
  font-weight: 500;
}

.wrap .box_details .order_detalis .details .question_circle {
  width: 0.4rem;
  height: 0.4rem;
  margin-left: 0.106667rem;
}

.wrap .box_details .order_detalis .details .paid {
  font-size: 0.346667rem;
  margin-right: 0.133333rem;
}

.wrap .box_details .order_detalis .details .paid span {
  color: var(--orderdetails_paid_span_color);
  font-size: 0.346667rem;
  font-weight: 500;
}

.wrap .box_details .order_detalis .details .pay_succeed {
  width: 0.4rem;
  height: 0.4rem;
  margin-left: 0.106667rem;
}

.wrap .box_details .order_detalis .details .box_cell {
  display: flex;
}

.wrap .box_details .order_detalis .details .amount_display {
  display: flex;
  align-items: center;
}

.wrap .box_details .order_detalis .details .amount_display .self_pay {
  color: #8E8E8E;
  font-size: 0.346667rem;
}

.wrap .box_details .order_detalis .details .amount_display .self_pay span {
  color: black;
  font-size: 0.32rem;
  margin-left: 0.133333rem;
  font-weight: 500;
}

.wrap .box_details .order_detalis .details .discounts {
  font-size: 0.32rem;
  color: #ffffff;
  background-color: #f5a623;
  padding: 0.026667rem 0.133333rem 0.026667rem 0.133333rem;
  border-radius: 0.106667rem;
  margin-left: 0.133333rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.133333rem;
}

.wrap .box_details .order_detalis .details .show_discounts {
  font-size: 0.32rem;
  color: #ffffff;
  background-color: #f5a623;
  padding: 0.026667rem 0.133333rem 0.026667rem 0.133333rem;
  border-radius: 0.106667rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.133333rem;
}

.wrap .box_details .order_detalis .details .expenses {
  font-size: 0.346667rem;
  color: var(--orderdetails_expenses);
  margin-left: 1.333333rem;
}

.wrap .box_details .order_detalis .details .exceed {
  border-radius: 0.106667rem;
  font-size: 0.32rem;
  color: #F34747;
  margin-top: 0.053333rem;
  padding: 0.08rem 0.213333rem 0.08rem 0.213333rem;
  background-color: #fdecec;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.16rem;
  margin-top: 0.133333rem;
}

.wrap .box_details .order_detalis .details .cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap .box_details .order_detalis .details .cell .left {
  display: flex;
  align-items: center;
}

.wrap .box_details .order_detalis .details .cell .left .oval {
  width: 1.333333rem;
  height: 1.333333rem;
}

.wrap .box_details .order_detalis .details .cell .left .oval img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.wrap .box_details .order_detalis .details .cell .left .msg {
  margin-left: 0.266667rem;
}

.wrap .box_details .order_detalis .details .cell .left .msg div {
  margin-top: 0.053333rem;
  margin-bottom: 0.053333rem;
  display: flex;
  align-items: center;
}

.wrap .box_details .order_detalis .details .cell .left .msg div span {
  color: #3C4858;
  font-size: 0.346667rem;
  font-weight: 500;
  margin-right: 0.133333rem;
}

.wrap .box_details .order_detalis .details .cell .left .msg div .driver_level {
  color: var(--orderdetails_driver_level);
}

.wrap .box_details .order_detalis .details .cell .left .msg p {
  color: #3C4858;
  margin: 0;
  margin-bottom: 0.053333rem;
}

.wrap .box_details .order_detalis .details .cell .left .msg span {
  color: #3C4858;
}

.wrap .box_details .order_detalis .details .cell .right {
  width: 1.066667rem;
  height: 1.066667rem;
}

.wrap .box_details .order_detalis .details .cell .right img {
  width: 100%;
  height: 100%;
}

.wrap .box_details .order_detalis .details .evaluate .name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap .box_details .order_detalis .details .evaluate .name>div:nth-child(1)>span:nth-child(1) {
  font-size: 0.373333rem;
  color: #3C4858;
  font-weight: 500;
}

.wrap .box_details .order_detalis .details .evaluate .name>div:nth-child(1)>span:nth-child(2) {
  font-size: 0.32rem;
  color: #3C4858;
  display: inline-block;
  background: #ebecee;
  border-radius: 0.133333rem;
  margin-left: 0.266667rem;
  padding: 0.026667rem 0.106667rem;
}

.wrap .box_details .order_detalis .details .evaluate .name>div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap .box_details .order_detalis .details .evaluate .name>div:nth-child(2) span {
  color: var(--orderdetails_evaluate_span);
  font-size: 0.32rem;
  margin-right: 0.266667rem;
}

.wrap .box_details .order_detalis .details .evaluate .name>div:nth-child(2) .score_img {
  width: 0.666667rem;
  height: 0.666667rem;
}

.wrap .box_details .order_detalis .details .evaluate .reaction {
  display: flex;
  justify-content: space-around;
  margin-top: 0.266667rem;
}

.wrap .box_details .order_detalis .details .evaluate .reaction div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrap .box_details .order_detalis .details .evaluate .reaction div img {
  width: 0.746667rem;
  height: 0.746667rem;
  margin-bottom: 0.106667rem;
}

.wrap .box_details .order_detalis .details .evaluate .reaction div span {
  font-size: 0.32rem;
  color: #9DA3AB;
  font-weight: 400;
}

.wrap .box_details .order_detalis .details .evaluate .bule_name {
  color: var(--orderdetails_bule_name) !important;
  font-weight: 500;
}

.wrap .box_details .order_detalis .details .occur_evaluation {
  padding: 0.133333rem 0 0.133333rem 0;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}

.wrap .box_details .order_detalis .details .occur_evaluation span {
  padding: 0.133333rem 0.533333rem;
  color: var(--orderdetails_label_bule_color);
  background: var(--orderdetails_label_bule_background_color);
  border: 0.026667rem solid var(--orderdetails_label_bule_border_color);
  border-radius: 0.133333rem;
  margin-bottom: 0.266667rem;
}

.wrap .box_details .order_detalis .details .occur_evaluation div {
  margin-top: 0.4rem;
  color: #3C4858;
}

.wrap .box_details .order_detalis .details .occur_evaluation::after {
  content: '';
  width: 2.533333rem;
}

.wrap .box_details .order_detalis .details .evaluate {
  color: #3C4858;
}

.wrap .box_details .order_detalis .details .bule_name {
  color: #2F7DCD;
}

.wrap .box_details .order_detalis .details .label_box {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: wrap;
}

.wrap .box_details .order_detalis .details .label {
  background: rgba(255,255,255,0.1);
  border: 0.026667rem solid #edeff1;
  border-radius: 0.133333rem;
  padding: 0.133333rem 0.906667rem;
  margin-top: 0.266667rem;
}

.wrap .box_details .order_detalis .details .label_bule {
  background: var(--orderdetails_label_bule_background_color);
  border: 0.026667rem solid var(--orderdetails_label_bule_border_color);
  border-radius: 0.133333rem;
  padding: 0.133333rem 0.906667rem;
  margin-top: 0.266667rem;
  color: var(--orderdetails_label_bule_color);
}

.wrap .box_details .order_detalis .details .reason {
  font-size: 0.346667rem;
}

.wrap .box_details .order_detalis .details .text textarea {
  border: 0.026667rem solid #edeff1;
  padding: 0.133333rem;
  border-radius: 0.053333rem;
  font-size: 0.32rem;
  margin-top: 0.266667rem;
  height: 1.76rem !important;
}

.wrap .box_details .order_detalis .details .btn {
  margin: 0 0.32rem;
  padding-bottom: 0.266667rem;
}

.wrap .box_details .order_detalis .details .btn button {
  margin-top: 0.533333rem;
}

.wrap .box_details .order_detalis .details .complaint {
  background: var(--orderdetails_complaint_background_color);
  border-radius: 0.133333rem;
  color: #3C4858;
  font-weight: 400;
  padding: 0.213333rem 0.266667rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.426667rem;
  font-size: 0.32rem;
}

.wrap .box_details .order_detalis .details .complaint .max_width {
  max-width: 63.5%;
}

.wrap .box_details .order_detalis .details .complaint .span {
  background: var(--orderdetails_complaint_span_background_color);
  border-radius: 0.426667rem;
  font-size: 0.32rem;
  color: #FFFFFF;
  font-weight: 400;
  padding: 0.106667rem 0.213333rem;
}

.wrap .box_details .order_detalis .details .details_title {
  font-size: 0.373333rem;
  color: #3C4858;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap .box_details .order_detalis .details .details_content {
  position: relative;
}

.wrap .box_details .order_detalis .details .details_content p {
  font-family: PingFangSC-Regular;
  font-size: 0.346667rem;
  color: #3C4858;
  line-height: 0.586667rem;
  font-weight: 400;
  margin: 0.16rem 0;
}

.wrap .box_details .order_detalis .details .details_content div {
  font-family: PingFangSC-Regular;
  font-size: 0.346667rem;
  color: #3C4858;
  line-height: 0.586667rem;
  font-weight: 400;
  display: flex;
}

.wrap .box_details .order_detalis .details .details_content div div {
  margin-left: 0.533333rem;
  font-family: PingFangSC-Regular;
  font-size: 0.346667rem;
  color: #2F7DCD;
  line-height: 0.533333rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap .box_details .order_detalis .details .details_content .details_content {
  position: absolute;
  right: 0.106667rem;
  bottom: 0;
  color: #FF7514;
  align-items: center;
}

.wrap .box_details .order_detalis .details .details_content p:nth-child(1) {
  margin-top: 0;
}

.wrap .box_details .order_detalis .details .details_content p:last-child {
  margin-bottom: 0;
}

.wrap .box_details .order_detalis .details .order_cancel {
  font-size: 0.373333rem;
  color: #3C4858;
  font-weight: 500;
}

.wrap .box_details .order_detalis .details .order_cancel .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap .box_details .order_detalis .details .order_cancel p {
  font-size: 0.346667rem;
  color: #3C4858;
  font-weight: 400;
  margin: 0;
  margin-top: 0.266667rem;
}

.wrap .box_details .order_detalis .details .cancel_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrap .box_details .order_detalis .details .cancel_box .cell_box div {
  display: flex;
  align-items: center;
}

.wrap .box_details .order_detalis .details .cancel_box .cell_box div p {
  font-size: 0.346667rem;
  color: #3C4858;
  font-weight: 400;
  margin: 0.133333rem 0;
}

.wrap .box_details .order_detalis .details .cancel_box .cell_box div img {
  width: 0.32rem;
  height: 0.32rem;
  margin-right: 0.266667rem;
}

.wrap .box_details .order_detalis .details .cancel_box .customer_service img {
  width: 1.066667rem;
  height: 1.066667rem;
}

.wrap .box_details .order_detalis .details .complaint_details {
  padding: 0.266667rem;
  background-color: #ffffff;
  box-shadow: 0 0.16rem 0.426667rem 0 rgba(60,72,88,0.2);
  border-radius: 0.213333rem;
  margin-bottom: 0.266667rem;
}

.wrap .box_details .order_detalis .details .complaint_details .schedule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.426667rem;
}

.wrap .box_details .order_detalis .details .complaint_details .schedule span {
  font-size: 0.373333rem;
  color: #3C4858;
  font-weight: 500;
}

.wrap .box_details .order_detalis .details .investigation {
  width: 74%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.266667rem;
  background-color: #ffffff;
  margin-bottom: 0.266667rem;
  box-shadow: 0 0.16rem 0.42667rem 0 rgba(60,72,88,0.2);
  padding: 0.213333rem 0 0.213333rem 0.213333rem;
}

.wrap .box_details .order_detalis .details .investigation .box {
  display: flex;
  align-items: center;
}

.wrap .box_details .order_detalis .details .investigation .box img {
  width: 0.426667rem;
  height: 0.426667rem;
  margin-left: 0.106667rem;
}

.wrap .box_details .order_detalis .details .investigation .box .message {
  margin-left: 0.16rem;
}

.wrap .box_details .order_detalis .details .investigation .confirm_btn {
  display: flex;
  margin-right: 0.266667rem;
}

.wrap .box_details .order_detalis .details .investigation .confirm_btn .no {
  background: #E9E9E9;
  padding: 0.053333rem 0.213333rem 0.053333rem 0.213333rem;
  box-shadow: 0 0.08rem 0.16rem 0 rgba(0,0,0,0.05);
  border-radius: 0.213333rem;
  margin-right: 0.106667rem;
  font-size: 0.266667rem;
}

.wrap .box_details .order_detalis .details .investigation .confirm_btn .yes {
  color: var(--orderdetails_confirm_btn_yes_font_size);
  background: var(--orderdetails_confirm_btn_yes_background);
  padding: 0.053333rem 0.213333rem 0.053333rem 0.213333rem;
  box-shadow: 0 0.08rem 0.16rem 0 rgba(0,0,0,0.05);
  border-radius: 0.213333rem;
  margin-left: 0.106667rem;
  font-size: 0.266667rem;
}

.wrap .box_details :global .adm-popup {
  z-index: 111112;
}

.wrap .box_details :global .adm-popup .adm-popup-body-position-bottom {
  border-top-left-radius: 0.266667rem;
  border-top-right-radius: 0.266667rem;
  padding-bottom: 0.4rem;
}

.wrap .box_details .order_detalis :global .adm-dialog-wrap {
  width: 78vw;
}

.wrap .box_details .order_detalis :global .adm-popover {
  width: 5.066667rem;
}

.wrap .box_details .order_detalis :global .adm-popover .adm-popover-inner-content {
  color: #3C4858;
  font-size: 0.32rem;
}

.wrap .box_details .order_detalis :global .adm-popover .adm-popover-arrow {
  display: none;
}

.wrap .approval {
  font-size: 0.32rem;
  display: inline-block;
  padding-top: 0.266667rem;
  color: #9B9B9B;
}

.wrap .appraise {
  max-height: 6.933333rem;
}

.wrap .count {
  font-size: 0.373333rem;
  color: #3C4858;
  font-weight: 500;
}

.wrap .expand {
  font-size: 0.32rem;
  color: #2F7DCD;
  text-decoration: underline;
}

.wrap .anew_submit {
  font-size: 0.32rem;
  color: #ff4f3a;
  display: inline-block;
  padding: 0.026667rem 0.16rem 0 0.16rem;
  border-radius: 0.533333rem;
  border: 0.026667rem solid #ff4f3a;
}

.wrap .approval_time {
  color: #999999;
}

.wrap .approval_title {
  display: flex;
  justify-content: space-between;
}

.wrap .approval_name {
  display: flex;
  align-items: center;
  margin-bottom: 0.133333rem;
}

.wrap .approval_name span {
  font-size: 0.32rem;
}

.wrap .approval_name img {
  width: 0.373333rem;
  height: 0.373333rem;
  margin-left: 0.266667rem;
}

.wrap .approval_name :global .adm-ellipsis {
  font-size: 0.32rem;
}

.wrap .deployment_img {
  width: 0.32rem;
  height: 0.32rem;
  display: block;
  margin-left: 0.266667rem;
}

.wrap .box_approved :global .adm-step-icon-container img {
  width: 0.48rem;
}

.wrap .box_approved :global .adm-steps-vertical {
  padding: 0;
}

.wrap .box_approved :global .adm-step-title {
  font-size: 0.386667rem !important;
  color: #3C4858;
  font-weight: 500;
}

.wrap :global .adm-collapse {
  margin-bottom: 0.266667rem;
}

.wrap :global .adm-list {
  --active-background-color: '';
}

.wrap :global .adm-list-body {
  border-radius: 0.186667rem;
  box-shadow: 0 0.16rem 0.426667rem 0 rgba(60,72,88,0.2);
}

.wrap :global .adm-button {
  padding: 0.213333rem 0;
  margin-top: 0.266667rem;
}

.wrap :global .adm-list-item-content-arrow {
  display: none;
}

.wrap :global .adm-list-item-content-main {
  max-height: 13.333333rem !important;
  overflow-y: auto !important;
}

.wrap .complaint_details :global .adm-step-icon-container img {
  width: 0.533333rem;
  height: 0.533333rem;
  margin: 0 0.16rem;
}

.wrap .complaint_details :global .adm-step-title {
  color: #3C4858;
  font-size: 0.32rem !important;
}

.wrap .complaint_details :global .adm-step-indicator::after {
  background-color: var(--orderdetails_complaint_details_step_background_color) !important;
}

.prompt :global .adm-dialog-content {
  display: inline !important;
}

.prompt :global .adm-dialog-content .adm-text-area {
  padding: 0.133333rem;
  border: 0.013333rem solid #bbb;
}

.count :global .adm-step {
  margin-top: 0.266667rem;
}

.count :global .adm-step-content {
  padding-bottom: 0 !important;
}

.count :global .adm-step-indicator::after {
  background-color: #1677ff;
}

.confirmPay {
  text-align: center;
  color: #9f9f9f;
}

.show_red :global .adm-text-area textarea::-webkit-input-placeholder {
  color: red;
}

.show_red :global .adm-text-area textarea:-ms-input-placeholder {
  color: red;
}

.show_red :global .adm-text-area textarea::placeholder {
  color: red;
}

.edit {
  margin-top: 0.266667rem;
  font-size: 0.32rem;
}

.edit span {
  color: red;
}

.btn_details {
  font-size: 0.373333rem;
  color: #000000;
  font-weight: 500;
}

.btn_close {
  font-size: 0.373333rem;
  color: #898989;
  font-weight: 400;
}

.alert_message {
  text-align: center;
}

.alert_message .code {
  color: var(--payments_body_adm_auto_center_content);
}

.tip_message {
  color: red;
  font-size: 0.32rem;
  margin-top: 0.133333rem;
}