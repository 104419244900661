.invoiceCell {
  width: 100%;
  background-color: #FFFFFF;
  margin-top: 0.266667rem;
  padding: 0.32rem;
  border-top: 0.026667rem solid #D9D9D9;
  border-bottom: 0.026667rem solid #D9D9D9;
  position: relative;
}

.invoiceCell .price {
  color: #2D3034;
  display: flex;
  padding-bottom: 0.32rem;
  border-bottom: 0.026667rem solid #D9D9D9;
  margin-bottom: 0.32rem;
}

.invoiceCell .price .header {
  flex: 1 1;
  text-align: center;
}

.invoiceCell .price .header .amount {
  font-size: 0.586667rem;
  font-weight: 500;
}

.invoiceCell .price .header p {
  margin: 0;
  color: #9B9B9B;
  padding-bottom: 0.16rem;
  font-size: 0.346667rem;
}

.invoiceCell .price .header em {
  font-style: inherit;
  font-size: 0.32rem;
}

.invoiceCell .message p {
  margin: 0;
  margin-bottom: 0.16rem;
  color: #9B9B9B;
}

.invoiceCell :global .adm-checkbox-icon-checked {
  text-align: center;
}