.main {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
}

.amapContainer {
  width: 100vw;
  height: 100%;
}

.main_jd {
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
}

.amapContainer_jd {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.mapMask {
  height: 100%;
  width: 100vw;
  position: absolute;
  background: #f1f1f1;
  z-index: 1;
  text-align: right;
}

.markerFrom {
  z-index: 1;
  position: absolute;
  top: calc(50vh - 0.746667rem);
  left: calc(50vw - 0.266667rem);
  width: 0.506667rem;
  height: 0.746667rem;
}

@-webkit-keyframes container-zoom-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes container-zoom-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.case {
  z-index: 1;
  width: -webkit-max-content;
  width: max-content;
  min-height: 0.8rem;
  position: relative;
  padding: 0.213333rem;
  border-radius: 0.213333rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.10667rem 0.16rem rgba(0,0,0,0.06);
  top: calc(50vh - 2.266667rem);
  -webkit-animation: container-zoom-in .3s ease;
  animation: container-zoom-in .3s ease;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
}

.case .place {
  color: var(--main_place);
  font-size: 0.266667rem;
}

.case .address {
  color: var(--main_address);
  font-size: 0.346667rem;
  font-weight: 500;
}

.separate {
  z-index: 1;
  width: -webkit-max-content;
  width: max-content;
  min-height: 0.8rem;
  position: relative;
  padding: 0.213333rem;
  border-radius: 0.213333rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.10667rem 0.16rem rgba(0,0,0,0.06);
  top: calc(50vh - 1.893333rem);
  -webkit-animation: container-zoom-in .3s ease;
  animation: container-zoom-in .3s ease;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}

.separate .aboard {
  color: #9DA3AB;
  font-size: 0.346667rem;
}

.carryon {
  z-index: 1;
  width: -webkit-max-content;
  width: max-content;
  min-height: 0.8rem;
  position: relative;
  padding: 0.213333rem;
  border-radius: 0.213333rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.10667rem 0.16rem rgba(0,0,0,0.06);
  top: calc(50vh - 1.893333rem);
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}

.carryon .aboard {
  color: #9DA3AB;
  font-size: 0.346667rem;
}

.station {
  z-index: 1;
  width: -webkit-max-content;
  width: max-content;
  min-height: 0.8rem;
  position: relative;
  padding: 0.213333rem;
  border-radius: 0.213333rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.10667rem 0.16rem rgba(0,0,0,0.06);
  top: calc(50vh - 2.266667rem);
  margin: 0 auto;
  text-align: center;
  -webkit-animation: container-zoom-in .3s ease;
  animation: container-zoom-in .3s ease;
  overflow: hidden;
}

.station .title {
  color: #3C4858;
  font-size: 0.346667rem;
}

.station .fixed {
  color: var(--main_fixed_color);
  font-size: 0.32rem;
}

.shape {
  z-index: 1;
  height: 0;
  width: 0;
  position: relative;
  border-color: #ffffff transparent transparent transparent;
  border-style: solid;
  top: calc(50vh - 1.973333rem);
  border-width: 0.213333rem;
  -webkit-animation: container-zoom-in .3s ease;
  animation: container-zoom-in .3s ease;
  margin: 0 auto;
}

.bottomShape {
  z-index: 1;
  height: 0;
  width: 0;
  position: relative;
  border-color: #ffffff transparent transparent transparent;
  border-style: solid;
  -webkit-animation: container-zoom-in .3s ease;
  animation: container-zoom-in .3s ease;
  top: calc(50vh - 2.32rem);
  border-width: 0.213333rem;
  margin: 0 auto;
}

.triangle {
  z-index: 1;
  height: 0;
  width: 0;
  position: relative;
  border-color: #ffffff transparent transparent transparent;
  -webkit-animation: container-zoom-in .3s ease;
  animation: container-zoom-in .3s ease;
  border-style: solid;
  top: calc(50vh - 2.293333rem);
  border-width: 0.213333rem;
  margin: 0 auto;
}

.menuWarp {
  position: absolute;
  top: 0.213333rem;
  left: 0.133333rem;
  z-index: 2;
}

.menuWarp .menuIcon {
  width: 1.28rem;
  height: 1.28rem;
}

.menuWarp .menuIcon .box {
  width: 1.28rem;
  height: 1.28rem;
  border-radius: 0.533333rem;
  display: inline-block;
  margin-bottom: 0.266667rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuWarp .menuIcon .box.micon {
  background: url(../assets/menu.png) center no-repeat;
  background-size: 100% 100%;
}

.callCar {
  position: absolute;
  bottom: 0.533333rem;
  left: 0.266667rem;
  right: 0.266667rem;
  height: auto;
  z-index: 2;
}

.callCar .maprestoration {
  height: 1.2rem;
  width: 1.2rem;
  text-align: right;
  position: absolute;
  right: 0;
  top: -1.333333rem;
}

.callCar .maprestoration img {
  width: 1.2rem;
  height: 1.2rem;
}

.bottom_replace {
  width: 100%;
  height: 0.533333rem;
  background-color: transparent;
  position: absolute;
  bottom: 0;
}

.fence {
  position: absolute;
  bottom: 0.533333rem;
  left: 0.266667rem;
  right: 0.266667rem;
  height: auto;
  z-index: 3;
}

.hide {
  display: none;
}

.fencePoint {
  position: absolute !important;
  z-index: 1;
  top: calc(50vh - 0.146667rem);
  left: calc(50vw - 0.146667rem);
  width: 0.293333rem;
  height: 0.293333rem;
}

.circle {
  width: 0.293333rem;
  height: 0.293333rem;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0.026667rem 0.053333rem 0 rgba(60,72,88,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.greenCircle {
  width: 0.266667rem;
  height: 0.266667rem;
  background-color: #42A737;
  border-radius: 50%;
  border: 0.04rem solid #fff;
}

.location {
  max-width: 4rem;
  min-width: 2.666667rem;
  height: 0.4rem;
  text-align: left;
  position: absolute;
  color: #42A737;
  font-size: 0.32rem;
  bottom: -0.4rem;
  text-shadow: -0.026667rem -0.053333rem 0.08rem #ffffff, -0.026667rem -0.053333rem 0.08rem #ffffff, -0.026667rem -0.053333rem 0.08rem #ffffff, -0.026667rem -0.053333rem 0.08rem #ffffff, -0.026667rem -0.053333rem 0.08rem #ffffff;
}

.pinAnima {
  -webkit-animation: bounce 1s;
  animation: bounce 1s;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-0.4rem);
    transform: translateY(-0.4rem);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-0.4rem);
    transform: translateY(-0.4rem);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.topBox {
  display: flex;
  align-items: center;
  width: 100vw;
}

.hcp {
  width: 93%;
  background: #FFFFFF;
  box-shadow: 0 0.053333rem 0.16rem 0 rgba(0,0,0,0.05);
  border-radius: 0.373333rem;
  margin-bottom: 0.213333rem;
}

.hcp .hcp_img {
  width: 0.48rem !important;
  height: 0.48rem !important;
}

.hcp span {
  margin-left: 0.106667rem;
  color: red;
}

.fail :global {
  background-color: rgba(0,0,0,0.1) !important;
}

.main :global .adm-badge-content {
  min-width: 0.32rem;
  font-size: 0.266667rem;
  padding: 0.053333rem 0.133333rem;
}

.failBody :global {
  padding-top: 0.32rem !important;
}

.failBody :global .adm-modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center !important;
  padding: 0 0.32rem 0.133333rem !important;
}

.failBody :global .adm-modal-content svg {
  vertical-align: sub;
}