.container {
  background-color: #f5f5f5;
  height: 100vh;
  width: 100vw;
  padding: 0 0.4rem;
  overflow-y: auto;
  background: url(../assets/scenes_bg.png) no-repeat 100% 0% #f2f3f6;
}

.container .details {
  padding: 0.266667rem 0;
}

.container .details .name {
  font-size: 0.4rem;
  padding: 0.266667rem 0;
  color: #000000;
  font-weight: 500;
}

.container .bordertop {
  border-top: 0.026667rem solid #e7e7e7;
}

.container .title {
  font-size: 0.346667rem;
  padding: 0.266667rem 0;
  color: #000000;
  font-weight: 500;
}

.container span {
  font-size: 0.32rem;
  color: #9b9b9b;
  min-width: 1.653333rem;
  line-height: 0.533333rem;
}

.container .timelimit {
  display: flex;
}

.container .timelimit>div:first-child span {
  display: inline-block;
}

.container .timelimit .timelist {
  align-items: baseline;
  display: flex;
  flex-direction: column;
}

.container .timelimit .timelist text:last-child {
  margin: 0;
}

.container .tips {
  border-top: 0.026667rem solid #e7e7e7;
  font-size: 0.32rem;
  padding-top: 0.266667rem;
  margin-top: 0.266667rem;
}