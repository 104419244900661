.notice {
  width: 80%;
  height: 0.746667rem;
  line-height: 0.746667rem;
  z-index: 2;
  background-color: white;
  border-radius: 0.373333rem;
  box-shadow: 0 0 0.266667rem #f0f0f0;
  margin-left: 0.266667rem;
}

.notice .line {
  display: flex;
}

.notice .line>div:nth-child(1) {
  width: 0.746667rem;
  text-align: center;
  font-size: 0.4rem;
  color: #0082ef;
}

.notice .line>div:nth-child(3) {
  width: 0.597333rem;
}

.notice .line>div:nth-child(2) {
  flex: auto;
  line-height: 0.746667rem !important;
}