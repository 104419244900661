.box {
  padding: 0 0.4rem;
}

.box .ticket {
  display: flex;
  min-height: 2.666667rem;
  margin-top: 0.4rem;
  overflow: hidden;
}

.box .ticket.company>div:nth-child(1) {
  background-image: linear-gradient(135deg, #53A1FD 0%, #2F7DCD 100%);
}

.box .ticket.company>div:nth-child(2) span {
  background-color: #2F7DCD;
}

.box .ticket.disable>div:nth-child(1) {
  background: #D0D0D0;
}

.box .ticket.disable>div:nth-child(2) span {
  background-color: #D0D0D0;
}

.box .ticket.frozen>div:nth-child(1) {
  opacity: 0.5;
}

.box .ticket.timeout {
  background: #fff url(../../assets/coupon_ysy.png) no-repeat 104% 50%;
  background-size: 20%;
}

.box .ticket>div:nth-child(1) {
  width: 2.666667rem;
  opacity: 0.88;
  background-image: linear-gradient(135deg, #FAD961 0%, #F7901C 100%);
  color: #ffffff;
  font-size: 0.853333rem;
  text-align: center;
  padding-top: 0.4rem;
  flex-shrink: 0;
  border-radius: 0.213333rem;
  z-index: 1;
  position: relative;
}

.box .ticket>div:nth-child(1) .postBorder {
  position: absolute;
  top: 0.266667rem;
  bottom: 0.266667rem;
  left: -0.133333rem;
  border-left: dotted 0.266667rem #f5f5f5;
}

.box .ticket>div:nth-child(1) span {
  font-size: 0.346667rem;
  position: relative;
  top: -0.32rem;
}

.box .ticket>div:nth-child(1) .tip {
  font-size: 0.346667rem;
}

.box .ticket>div:nth-child(2) {
  flex: auto;
  padding: 0.4rem 0.4rem 0.933333rem 0.4rem;
  font-size: 0.453333rem;
  color: #000000;
  border-radius: 0 0.213333rem 0.213333rem 0;
  border: 0.026667rem solid #dfdfdf;
  border-left: none;
  background-color: #ffffff;
  position: relative;
}

.box .ticket>div:nth-child(2) .topInnerRadius {
  position: absolute;
  left: -0.213333rem;
  top: -0.213333rem;
  width: 0.426667rem;
  height: 0.426667rem;
  border-radius: 50%;
  background-color: #f5f5f5;
  border: 0.026667rem solid #dfdfdf;
}

.box .ticket>div:nth-child(2) .leftInnerRadius {
  position: absolute;
  left: -0.213333rem;
  bottom: -0.213333rem;
  width: 0.426667rem;
  height: 0.426667rem;
  border-radius: 50%;
  background-color: #f5f5f5;
  border: 0.026667rem solid #dfdfdf;
}

.box .ticket>div:nth-child(2) span {
  background-color: #F7901C;
  font-size: 0.293333rem;
  color: #FFFFFF;
  text-align: center;
  padding: 0.026667rem 0.186667rem;
  border-radius: 0.266667rem;
  position: relative;
  top: -0.053333rem;
  margin-top: 0.16rem;
}

.box .ticket>div:nth-child(2) .allow {
  font-size: 0.293333rem;
  color: #9B9B9B;
  line-height: 0.586667rem;
  white-space: break-spaces;
}

.box .ticket>div:nth-child(2) .details {
  font-size: 0.346667rem;
  color: #9B9B9B;
  position: absolute;
  bottom: 0.266667rem;
}

.box .ticket>div:nth-child(3) {
  padding-top: 0.933333rem;
  padding-right: 0.4rem;
}

.box .case {
  display: flex;
  justify-content: left;
  align-items: center;
}

.box .normal {
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  width: 4.746667rem;
  flex: 1 1;
}

.box .limit {
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  width: 1.893333rem;
}

.box .useDetails {
  font-size: 0.32rem;
  margin: 0;
  flex: 1 1;
  text-align: right;
  color: #6492c4;
}

.box>.details {
  background: #ffffff;
  border-radius: 0 0 0.213333rem 0.213333rem;
  border: 0.026667rem solid #dfdfdf;
  border-top: none;
  padding: 0 0.4rem 0.4rem 0.4rem;
  color: #9B9B9B;
  overflow: hidden;
}

.box>.details>div {
  display: flex;
  margin-top: 0.213333rem;
}

.box>.details>div>div:nth-child(1) {
  flex-shrink: 0;
}

.box>.details>div>div:nth-child(2) {
  flex: auto;
}