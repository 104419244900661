.container {
  background-color: #f5f5f5;
  position: fixed;
  height: 100%;
  width: 100%;
  padding-top: 1.093333rem;
  overflow: scroll;
}

.container :global .adm-tabs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  background-color: #fafafa;
  z-index: 1;
}

.container :global .adm-tabs .adm-tabs-tab-active {
  color: var(--payments_tab_active_color);
}

.container :global .adm-tabs .adm-tabs-tab-line {
  color: var(--payments_tab_line_background);
  background: var(--payments_tab_line_background);
}

.container :global .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
  border-color: var(--payments_checkbox_border-color);
  background-color: var(--payments_checkbox_background_color);
  color: var(--payments_checkbox_color);
}

.container .body {
  position: relative;
  padding-bottom: 1.733333rem;
}

.container .body .footbar {
  height: calc(1.333333rem + env(safe-area-inset-bottom));
  line-height: 1.333333rem;
  display: flex;
  align-items: center;
  font-size: 0.373333rem;
  padding-left: 0.4rem;
  background-color: #fafafa;
  border-top: solid 0.026667rem #e1e1e1;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding-bottom: env(safe-area-inset-bottom);
}

.container .body .footbar :global .adm-checkbox {
  vertical-align: top;
}

.container .body .footbar :global .adm-checkbox .adm-checkbox-content {
  font-size: 0.373333rem;
}

.container .body .footbar>div:nth-child(2) {
  flex: auto;
  font-size: 0.533333rem;
  color: var(--payments_footbar_child_color);
  text-align: right;
  padding: 0 0.266667rem;
}

.container .body .footbar>div:nth-child(3) {
  background-image: var(--payments_pay_btn_background_image);
  color: var(--payments_pay_btn_color);
  width: 25vw;
  text-align: center;
}

.container .bodyPayed {
  position: relative;
  padding-bottom: 1.733333rem;
}

.container .bodyPayed .footbar {
  height: calc(1.333333rem + env(safe-area-inset-bottom));
  line-height: 1.333333rem;
  display: flex;
  align-items: center;
  font-size: 0.373333rem;
  padding-left: 0.4rem;
  background-color: #fafafa;
  border-top: solid 0.026667rem #e1e1e1;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  text-align: center;
  padding-bottom: env(safe-area-inset-bottom);
}

.container .bodyPayed .footbar>div {
  flex: 1 1;
}

.container .noneOrders {
  position: absolute;
  left: 30vw;
  width: 40vw;
  top: 20vh;
  text-align: center;
}

.container .noneOrders img {
  width: 50%;
}

.container .noneOrders>div:last-child {
  font-size: 0.346667rem;
  text-align: center;
  color: #9f9f9f;
  margin-top: 0.266667rem;
}

.container .message {
  text-align: center;
}

.container .message .code {
  color: var(--payments_body_adm_auto_center_content);
}

.service {
  text-align: center;
  color: #9f9f9f;
}

.service span {
  color: #1677ff;
}

.loadingbox {
  display: flex;
  height: 0.8rem;
  align-items: center;
  justify-content: center;
}

.loading {
  text-align: center;
  color: #ccccd0;
}

.confirmPay {
  text-align: center;
  color: #9f9f9f;
}

:global .adm-auto-center-content {
  word-break: break-word;
}

:global .adm-dialog-body .adm-dialog-action-row .adm-button {
  color: var(--adm_dialog_btn_color);
}

:global .adm-dialog-body .adm-dialog-action-row .adm-dialog-button-bold {
  color: var(--adm_dialog_btn_bold_color);
}