.invoice {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}

.invoice .bar {
  width: 100%;
  position: fixed;
  height: calc(1.333333rem + env(safe-area-inset-bottom));
  background-color: #fafafa;
  display: flex;
  bottom: 0;
  padding-left: 0.32rem;
  justify-content: space-between;
  padding-bottom: env(safe-area-inset-bottom);
  border-top: 0.026667rem solid #eeeeee;
}

.invoice .bar .left {
  display: flex;
  width: 100%;
  flex: 1 1;
  justify-content: space-between;
  border-bottom: 0.026667rem solid #eeeeee;
}

.invoice .bar .left .price {
  padding-right: 0.32rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.invoice .bar .left .price span {
  color: #3480ce;
  font-weight: 600;
  margin-top: 0.053333rem;
}

.invoice .bar .right {
  display: flex;
}

.invoice .loadingbox {
  display: flex;
  height: 0.8rem;
  align-items: center;
  justify-content: center;
}

.invoice .loading {
  text-align: center;
  color: #ccccd0;
}

.invoice .box {
  padding-bottom: 1.066667rem;
}

.invoice .box p {
  margin: 0;
}

.invoice .box .price {
  font-size: 0.373333rem;
  margin-bottom: 0.133333rem;
}

.invoice .box .time {
  font-size: 0.346667rem;
  color: #89898A;
}

.invoice .trip {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #89898A;
  font-size: 0.346667rem;
}

.invoice .dateSelect {
  width: 100%;
  height: calc(1.333333rem + env(safe-area-inset-bottom));
  position: fixed;
  bottom: 0;
  background-color: #FAFAFA;
  display: flex;
  border-top: 0.026667rem solid #eeeeee;
  z-index: 200;
  padding-bottom: env(safe-area-inset-bottom);
}

.invoice .dateSelect div {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.026667rem solid #eeeeee;
  flex: 1 1;
  font-size: 0.4rem;
}

.invoice .dateSelect div:nth-child(1) {
  border-right: 0.026667rem solid #eeeeee;
}

.invoice :global .adm-tabs-tab-list {
  width: 100%;
  position: fixed;
  background-color: #FFFFFF;
  z-index: 1;
}

.invoice :global .adm-tabs-tab {
  font-size: 0.373333rem;
}

.invoice :global .adm-tabs-content {
  overflow: hidden;
  background-color: #f5f5f5;
  padding: 0.986667rem 0 1.333333rem 0;
  height: 100vh;
  overflow: auto;
}

.invoice :global .adm-tabs-content .adm-empty {
  height: 100%;
}

.invoice :global .adm-tabs-content .adm-empty .adm-empty-image-container img {
  width: 2.266667rem;
  height: 2.026667rem;
}

.invoice :global .adm-tabs-content .adm-empty .adm-empty-description {
  font-size: 0.32rem;
}

.invoice :global .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
}

.invoice :global .adm-list {
  margin-top: 0.266667rem;
}

.right :global .adm-button {
  border-radius: 0;
}