.concierge .title {
  font-size: 0.426667rem;
  color: #000000;
  text-align: center;
  height: 1.2rem;
  line-height: 1.2rem;
  font-weight: bold;
  position: relative;
  border-bottom: solid 0.026667rem #f7f7f7;
}

.concierge .title>div {
  position: absolute;
  top: 0;
  right: 0.266667rem;
}

.concierge .waiting {
  line-height: 1.2rem;
  text-align: center;
}

.concierge .waiting>div:nth-child(1) {
  border-bottom: solid 0.026667rem #f7f7f7;
  font-size: 0.373333rem;
}

.concierge .waiting>div:nth-child(2) {
  font-size: 0.346667rem;
  color: #2f7dcd;
}

.concierge .info>div:nth-child(1) {
  border-bottom: solid 0.026667rem #f7f7f7;
  font-size: 0.373333rem;
  display: flex;
  padding: 0.266667rem;
}

.concierge .info>div:nth-child(1)>div:nth-child(1) {
  width: 1.333333rem;
  height: 1.333333rem;
}

.concierge .info>div:nth-child(1)>div:nth-child(1) img {
  width: 100%;
  height: 100%;
}

.concierge .info>div:nth-child(1)>div:nth-child(2) {
  padding: 0.133333rem 0.133333rem 0.133333rem 0.266667rem;
  flex: auto;
}

.concierge .info>div:nth-child(1)>div:nth-child(2)>div:nth-child(1) {
  font-weight: bold;
}

.concierge .info>div:nth-child(1)>div:nth-child(2)>div:nth-child(2) {
  color: #8c8c8c;
}

.concierge .info>div:nth-child(1)>div:nth-child(3) {
  line-height: 1.2rem;
  color: #4b8ed3;
}

.concierge .info>div:nth-child(2) {
  display: flex;
  line-height: 1.2rem;
  text-align: center;
  font-size: 0.373333rem;
}

.concierge .info>div:nth-child(2)>div:nth-child(1) {
  flex: 1 1;
  background-color: #ffffff;
  color: #000000;
}

.concierge .info>div:nth-child(2)>div:nth-child(2) {
  flex: 1 1;
  background-color: #2f7dcd;
  color: #ffffff;
}