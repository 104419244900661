.box {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: env(safe-area-inset-bottom);
}

.box .map {
  width: 100%;
  height: 5.866667rem;
  line-height: 5.866667rem;
  position: relative;
}

.box .map .amapContainer {
  width: 100vw;
  height: 100%;
  background-color: antiquewhite;
  position: relative;
}

.box .map .amapContainer .amap-marker-label {
  border: none;
  max-width: 2.666667rem;
  white-space: normal;
}

.box .map .beginText {
  color: #3d4959;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  max-width: 5.333333rem;
  min-width: 2.666667rem;
  text-align: left;
}

.box .orderdetails {
  width: 100%;
  padding: 0.4rem 0.266667rem 0.266667rem 0.266667rem;
  border-top: 0.026667rem solid #e7e7e7;
  border-left: 0.026667rem solid #e7e7e7;
  border-right: 0.026667rem solid #e7e7e7;
  background-color: #ffffff;
}

.box .orderdetails .pricebox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.4rem;
}

.box .orderdetails .pricebox .cell {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box .orderdetails .pricebox .cell span {
  font-size: 0.533333rem;
  font-weight: 500;
  color: #292c31;
  text-align: center;
}

.box .orderdetails .pricebox .cell .carSources {
  font-size: 0.346667rem;
  color: #9B9B9B;
}

.box .orderdetails .pricebox .cell .warn {
  font-size: 0.32rem;
  color: #e64340;
}

.box .orderdetails .cellBtn {
  text-align: center;
}

.box .orderdetails .cellBtn .prepaid {
  height: 0.533333rem;
  background: rgba(66,167,55,0.1);
  border: 0.026667rem solid rgba(66,167,55,0.5);
  border-radius: 0.333333rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0.533333rem;
  font-size: 0.32rem;
  color: #42A737;
  line-height: 0.48rem;
  font-weight: 400;
  display: inline-block;
  padding: 0 0.106667rem;
}

.box .orderdetails .cellBtn {
  text-align: center;
}

.box .orderdetails .cellBtn .finish {
  height: 0.533333rem;
  background: #eaf2fa;
  border: 0.026667rem solid rgba(47,125,205,0.5);
  border-radius: 0.333333rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0.533333rem;
  font-size: 0.32rem;
  color: #448ad2;
  line-height: 0.48rem;
  font-weight: 400;
  display: inline-block;
  padding: 0 0.106667rem;
}

.box .orderdetails .operation {
  width: 100%;
  height: 1.066667rem;
  line-height: 1.066667rem;
  text-align: center;
  display: flex;
  border-top: 0.026667rem solid #e7e7e7;
  border-bottom: 0.026667rem solid #e7e7e7;
  justify-content: space-around;
}

.box .orderdetails .operation span {
  font-size: 0.346667rem;
  font-weight: 500;
  margin-right: 0.266667rem;
}

.box .orderdetails .operationEmpty {
  border-bottom: 0.026667rem solid #e7e7e7;
}

.box .orderdetails .msg {
  color: #a6a6a6;
  font-size: 0.346667rem;
}

.box .orderdetails .msg p {
  margin: 0.213333rem 0;
  word-break: break-all;
}

.box .orderdetails .msg p:last-child {
  margin-bottom: 0;
}

.box .first {
  border-bottom: 0.026667rem solid #D9D9D9;
  padding-bottom: 0.266667rem;
}

.box .last {
  padding-bottom: 0.266667rem;
}

.box .extraServices {
  position: relative;
  padding-bottom: 0.266667rem;
  overflow: hidden;
  border-top: 0.026667rem solid #eeeeee;
}

.box .service_box {
  margin-bottom: 0.266667rem;
}

.box .service_box .housekeeper {
  font-size: 0.32rem;
  color: #8ea8c8;
  margin-top: 0.133333rem;
}

.box .box_expenses {
  display: flex;
  align-items: baseline;
}

.box .lastApproveState {
  color: black;
  border-bottom: 0.026667rem solid #eeeeee;
  padding-bottom: 0.32rem;
}

.box .discounts {
  margin: 0;
}

.box .pass {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2.186667rem;
  height: 1.733333rem;
}

.box .refuse {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2.186667rem;
  height: 1.733333rem;
}

.box .marking {
  color: red;
}

.approval {
  font-size: 0.32rem;
  display: inline-block;
  padding-top: 0.266667rem;
  color: #9B9B9B;
}

.deduction .key {
  color: #a6a6a6 !important;
}

.deduction span {
  padding-bottom: 0 !important;
}

.collapse :global .adm-list-item-content-main {
  font-weight: 500;
  font-size: 0.4rem;
}

.collapse :global .adm-list-item-content-main p {
  font-size: 0.346667rem !important;
  font-weight: normal;
}

.collapse :global .adm-list-item-content-main span {
  font-size: 0.346667rem !important;
  color: black;
  display: inline-block;
  padding-bottom: 0.32rem;
  padding-top: 0.16rem;
}

.collapse :global .adm-list-item-content-main em {
  font-style: normal;
}

.collapse :global .adm-collapse-panel-content .adm-list-item-content {
  padding-top: 0;
  padding-bottom: 0;
}

.collapse :global .adm-list-item-content-arrow {
  font-size: 0.32rem;
}

.collapse :global .adm-text-area-wrapper textarea {
  height: 3.066667rem;
  border: 0.013333rem solid #c0c0c0;
  font-size: 0.32rem;
  background-color: white;
  padding: 0.186667rem;
}

.collapse :global .adm-collapse-panel-content-inner .adm-list-item .adm-list-item-content .adm-list-item-content-main {
  padding-top: 0;
  font-size: 0.373333rem;
}

.collapse :global .adm-collapse-panel-content-inner .adm-list-item .adm-list-item-content .adm-list-item-content-main p {
  font-size: 0.346667rem;
}

.collapse :global .adm-collapse-panel-content-inner .adm-list-item .adm-list-item-content .adm-list-item-content-main div {
  position: relative;
}

.collapse :global .adm-collapse-panel-content-inner .adm-list-item .adm-list-item-content .adm-list-item-content-main div p {
  margin: 0.266667rem 0 0 0;
  font-size: 0.346667rem;
}

.collapse :global .adm-collapse-panel-content-inner .adm-list-item .adm-list-item-content span {
  font-size: 0.32rem;
  color: #000000;
}

.prompt :global .adm-dialog-content {
  display: inline !important;
}

.prompt :global .adm-dialog-content .adm-text-area {
  padding: 0.133333rem;
  border: 0.013333rem solid #bbb;
}

.amapContainer :global(.amap-marker-label) {
  border: none;
  width: 2.666667rem;
  white-space: normal;
  background-color: transparent;
}