.activityContent {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 13.333333rem;
  overflow: hidden;
  text-align: center;
}

.activityContent .box {
  width: 6.666667rem;
  margin: 0 auto;
  border-radius: 0.266667rem;
}

.activityContent .box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.266667rem;
}

.activityContent .close {
  margin-top: 0.4rem;
}