.container {
  background: #ffffff;
  box-shadow: 0 0 0.426667rem 0 rgba(60,72,88,0.2);
  border-radius: 0.186667rem;
  overflow: hidden;
  padding-top: 0.266667rem;
  padding: 0.266667rem 0.4rem 0 0.4rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container h2 {
  margin: 0;
  font-weight: 500;
  color: #3C4858;
  font-size: 0.426667rem;
  padding-top: 0.16rem;
}

.container h2 span {
  color: var(--recommendpath_h2_span_color);
}

.container .header {
  display: flex;
  justify-content: space-between;
}

.container .header h2 {
  font-weight: var(--recommendpath_header_font_weight);
  color: var(--recommendpath_header_color);
}

.container .goback {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.container .place {
  display: flex;
  font-size: 0.373333rem;
  margin-top: 0.16rem;
}

.container .place .left {
  color: #9DA3AB;
}

.container .place .rigth {
  color: var(--recommendpath_place_rigth_color);
  font-weight: var(--recommendpath_header_font_weight);
}

.container .nill {
  color: #3C4858;
  text-align: center;
  height: 1.013333rem;
  line-height: 1.013333rem;
}

.container :global .adm-button.adm-button-large {
  padding-top: 0.213333rem;
  padding-bottom: 0.213333rem;
  font-size: 0.373333rem;
}

.hide {
  display: none;
  -webkit-animation: hidebox 0.2s;
  animation: hidebox 0.2s;
}

.show {
  display: block;
  -webkit-animation: bounce 0.2s;
  animation: bounce 0.2s;
}

@-webkit-keyframes bounce {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes hidebox {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@keyframes hidebox {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}