.box {
  width: 100%;
  padding: 0.266667rem;
  border-radius: 0.133333rem;
  display: flex;
  background-color: white;
  margin-bottom: 0.266667rem;
}

.box .address {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box .address>div {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  line-height: normal;
}

.box .address>div>p {
  width: 3.333333rem;
  margin: 0;
  font-size: 0.32rem;
  color: #9d9d9d;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.box>div:nth-child(1)::after {
  width: 0.04rem;
  height: 0.533333rem;
  background-color: #e3e5e7;
  content: '';
  margin: 0 0.266667rem 0 0.266667rem;
}