.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  padding-bottom: 0.4rem;
  overflow: auto;
  font-size: 0.373333rem !important;
}

.container TextArea::-webkit-input-placeholder {
  font-size: 0.373333rem;
}

.container TextArea:-ms-input-placeholder {
  font-size: 0.373333rem;
}

.container TextArea::placeholder {
  font-size: 0.373333rem;
}

.container .ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 5.333333rem;
  text-align: right;
}

.container .serial_number {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  margin: 0.266667rem 0.32rem 0 0.32rem;
  border-radius: 0.213333rem;
}

.container .block {
  margin-top: 0.4rem;
}

.container .block :global .adm-list-item-content {
  height: 1.306667rem !important;
}

.container .block .phone>input {
  text-align: right;
  text-overflow: ellipsis;
}

.container .block .addressBook span {
  flex-shrink: 0;
  margin-left: 0.133333rem;
}

.container .block .addressBook .addressImg {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 0.266667rem;
  padding-left: 0.133333rem;
  border-left: 0.026667rem solid #eeeeee;
}

.container .block .addressBook :global .adm-list-item-content-main {
  height: 100%;
  padding: 0;
}

.container .block .scene :global .adm-list-item-content-extra {
  overflow: hidden !important;
}

.container .project_search {
  display: flex;
  align-items: center;
  background-color: white;
  margin: 0 0.266667rem 0 0.133333rem;
}

.container .project_search .retrieval {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  font-size: 0.373333rem;
}

.container .project_search span {
  margin-left: 0.053333rem;
}

.container .project_code {
  flex: 1 1;
  text-align: right;
}

.container .project_num {
  display: flex;
  justify-content: center;
  padding: 0.266667rem 0.213333rem 0.266667rem 0.266667rem;
  background-color: white;
  border-radius: 0.213333rem;
}

.container .project_num .num {
  flex: none;
  font-size: 0.4rem;
}

.container .added {
  padding-left: 0.4rem;
  background-color: #ffffff;
  margin-left: 0.32rem;
  margin-right: 0.32rem;
  border-radius: 0.213333rem;
}

.container .added .head {
  font-size: 0.346667rem;
  color: #ababab;
  height: 0.933333rem;
  line-height: 0.933333rem;
}

.container .added .item {
  border-top: solid 0.026667rem #eaeaea;
  display: flex;
  padding: 0.266667rem 0;
}

.container .added .item>div:nth-child(1) {
  flex: auto;
}

.container .added .item>div:nth-child(1)>div {
  height: 0.533333rem;
  line-height: 0.533333rem;
  font-size: 0.426667rem;
  color: #000000;
}

.container .added .item>div:nth-child(1)>span {
  line-height: 0.533333rem;
  color: #B0B5BC;
}

.container .added .item>div:nth-child(2) {
  width: 1.866667rem;
  min-width: 1.866667rem;
  font-size: 0.373333rem;
  color: #B0B5BC;
  letter-spacing: 0;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
}

.container .added .item>div:nth-child(3) {
  width: 0.8rem;
  min-width: 0.8rem;
  padding-top: 0.32rem;
}

.container .tbx {
  text-align: right;
  border: none;
}

.container .projects {
  position: absolute;
  top: 1.386667rem;
  right: 0.4rem;
  width: 5.333333rem;
  background-color: #ffffff;
  z-index: 50;
  box-shadow: 0 0 0.16rem 0 rgba(60,72,88,0.2);
  max-height: 4.266667rem;
  overflow: auto;
}

.container .projects>div {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 0.8rem;
  font-size: 0.373333rem;
  padding: 0 0.266667rem;
  width: 5.333333rem;
}

.container .projects>div div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.container .agreement {
  padding: 0.266667rem 0.533333rem 0.266667rem 0.4rem;
}

.container .agreement span {
  font-size: 0.32rem;
  color: #5685BB;
}

.container .footbar {
  padding: 0.4rem;
  background-color: #f5f5f5;
  padding-bottom: env(safe-area-inset-bottom);
}

.container .footbar>button:nth-child(2) {
  margin-top: 0.266667rem;
}

.container :global .adm-list-item-content-main {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.container :global .adm-list-item-content-prefix {
  width: auto;
}

.container :global .adm-list-body {
  overflow: inherit;
}

.container :global .adm-list-item-content-extra {
  width: 62%;
  text-overflow: ellipsis;
  white-space: pre;
  text-align: -webkit-right;
  padding-left: 0.053333rem;
}

.container :global .adm-list-item-content-extra div {
  word-break: break-word;
  overflow: hidden;
  box-sizing: content-box;
}

.container .last {
  border-bottom: 0.026667rem solid #D9D9D9;
}

.container .carbon {
  position: relative;
  background-color: #ffffff;
  margin-top: 0.4rem;
  padding: 0 0.266667rem;
  margin-right: 0.32rem;
  margin-left: 0.32rem;
  border-radius: 0.213333rem;
}

.container .carbon .title {
  display: flex;
  justify-content: left !important;
  align-items: center;
  color: #aaafb6;
  font-size: 0.373333rem;
  padding: 0.266667rem 0;
  border-bottom: 0.026667rem solid #eeeeee;
}

.container .carbon .title span {
  margin-left: 0.266667rem;
}

.container .carbon .prompt {
  width: 84%;
  position: absolute;
  top: -4.026667rem;
  left: 0.96rem;
  background-color: white;
  border-radius: 0.133333rem;
  padding: 0.266667rem 0.4rem 0.266667rem 0.266667rem;
  border: 0.026667rem solid #dcebd6;
  -webkit-animation: container-zoom-in .3s ease;
  animation: container-zoom-in .3s ease;
}

.container .carbon .prompt .suggestion {
  width: 80%;
}

.container .carbon .prompt .suggestion img {
  width: 100%;
  height: 100%;
}

.container .carbon .prompt .occupySpace {
  width: 100%;
  height: 0.533333rem;
  border-bottom: 0.026667rem solid #dcebd6;
  position: relative;
}

.container .carbon .prompt .occupySpace div {
  width: 0.533333rem;
  height: 0.533333rem;
  position: absolute;
  right: -0.133333rem;
  top: 0.053333rem;
}

.container .carbon .prompt .occupySpace div img {
  width: 100%;
  height: 100%;
}

.container .carbon .prompt .content {
  margin-top: 0.133333rem;
  font-size: 0.32rem;
  color: #999999;
}

.container .carbon .prompt .know {
  font-size: 0.32rem;
  color: #999999;
  text-decoration: underline;
  margin-top: 0.666667rem;
}

.container .carbon .prompt .triangle {
  z-index: 1;
  height: 0;
  width: 0;
  position: absolute;
  border-color: #ffffff transparent transparent transparent;
  -webkit-animation: container-zoom-in .3s ease;
  animation: container-zoom-in .3s ease;
  border-style: solid;
  top: 3.733333rem;
  left: 0.666667rem;
  border-width: 0.213333rem;
  margin: 0 auto;
}

.container .carbonBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.266667rem 0;
}

.container .carbonBar .msg {
  color: #42a737;
  font-size: 0.4rem;
}

.container .carbonBar :global .adm-checkbox {
  --icon-size: 0.48rem;
}

.container .carbonBar :global .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
  border-color: #42a737;
  background-color: #42a737;
}

.container .replace_box {
  max-width: 100%;
  margin: 0.266667rem 0.32rem 0 0.32rem;
  border-radius: 0.213333rem;
  display: flex;
  background-color: #ffff;
}

.container .replace_box .content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.373333rem 0.32rem 0.373333rem 0.32rem;
  flex: 1 1;
}

.container .replace_box :global .adm-popup-body-position-bottom {
  background-color: #F5F5F5;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.container .service_car {
  margin: 0.373333rem 0.266667rem 0.373333rem 0.266667rem;
}

.container .service_car .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container .service_car .title>div {
  font-weight: 500;
  font-size: 0.426667rem;
}

.container .service_car .confirm_btn {
  margin-top: 1.066667rem;
  height: 1.066667rem;
}

.container .service_car .cancel_btn {
  margin-top: 0.4rem;
  height: 1.066667rem;
}

.container .service_car .card {
  margin-top: 0.4rem;
}

.container .service_car .card :global .adm-list-item-content {
  height: 1.306667rem !important;
}

.container .service_car .card .phone>input {
  text-align: left;
  text-overflow: ellipsis;
  font-size: 0.373333rem;
}

.container .service_car .card .addressImg {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 0.266667rem;
  padding-left: 0.133333rem;
  border-left: 0.026667rem solid #eeeeee;
}

.container .service_car .card .addressImg span {
  flex-shrink: 0;
  margin-left: 0.133333rem;
}

.container .service_car .card :global .adm-list-item-content-main {
  height: 100%;
  padding: 0;
}

.container .service_car .card :global .adm-list-card {
  margin: 0;
}

.go_confirm_pay {
  color: var(--confirm_go_confirm_pay_color);
  font-size: 0.48rem;
  font-weight: normal;
}

.yes_confirm_btn {
  color: var(--confirm_yes_confirm_btn_color);
}

.pop_title {
  padding: 0.266667rem;
}

.project_num :global .adm-input input {
  text-align: right;
  font-size: 0.373333rem;
  padding-left: 0.266667rem;
}

@-webkit-keyframes container-zoom-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes container-zoom-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.btn_details {
  font-size: 0.373333rem;
  color: #000000;
  font-weight: 500;
}

.btn_close {
  font-size: 0.373333rem;
  color: #898989;
  font-weight: 400;
}