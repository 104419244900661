.container {
  display: flex;
  flex-direction: column;
  background: #F5F5F5;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.container .tips {
  position: absolute;
  left: 10vw;
  width: 80vw;
  top: 20vh;
  text-align: center;
}

.container .tips img {
  width: 20%;
}

.container .tips>div:nth-child(2) {
  font-size: 0.426667rem;
  text-align: center;
  color: #000000;
  margin-top: 0.266667rem;
}

.container .tips>div:last-child {
  font-size: 0.346667rem;
  text-align: center;
  color: #9f9f9f;
  margin-top: 0.266667rem;
}