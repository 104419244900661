.card {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
  overflow-y: auto;
}

.card .box {
  display: flex;
}

.card .box .right {
  flex: 1 1;
  padding-left: 0.4rem;
}

.card .box .right p {
  margin: 0;
  font-size: 0.373333rem;
  font-weight: 500;
  margin-top: 0.213333rem;
}

.card .box .right span {
  font-size: 0.346667rem;
  font-weight: 500;
}

.card .score {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0.533333rem;
}

.card .score span {
  font-size: 0.32rem;
  color: #f5ae37;
  margin: 0.133333rem 0 0 0;
}

.card .score p {
  font-size: 0.373333rem;
  font-weight: 500;
  margin: 0;
  margin-top: 0.266667rem;
}

.card .text textarea {
  border: 0.013333rem solid #bbb;
  padding: 0.133333rem;
  border-radius: 0.053333rem;
  font-size: 0.32rem;
  margin-top: 0.533333rem;
  height: 2.346667rem !important;
}

.card .show {
  position: relative;
  top: 0;
  font-size: 0.32rem;
  color: #f5ae37;
  margin: 0.133333rem 0 0 0;
  -webkit-animation: show-item 0.2s ease-in forwards;
  animation: show-item 0.2s ease-in forwards;
}

.card .hide {
  position: relative;
  top: 0;
  font-size: 0.32rem;
  color: #f5ae37;
  margin: 0.133333rem 0 0 0;
  -webkit-animation: hide-item 0.2s ease-in forwards;
  animation: hide-item 0.2s ease-in forwards;
}

@-webkit-keyframes show-item {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes show-item {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes hide-item {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@keyframes hide-item {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.card .btn {
  margin: 0 0.32rem;
}

.card .btn button {
  background-color: #2f7dcd;
  margin-top: 0.533333rem;
  margin-bottom: 0.533333rem;
}

.card .complaint {
  width: 100%;
}

.card .complaint .title {
  width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0.746667rem;
}

.card .complaint .title .processing {
  color: #6290c3;
}

.card .complaint .title .colorgrey {
  color: #a2a2a2;
}

.card .complaint .strip {
  width: 100%;
  padding: 0.266667rem 1.12rem;
}

.card .complaint .strip .progress {
  display: flex;
  justify-content: center;
}

.card .complaint .strip .progress img {
  width: 100%;
  object-fit: scale-down;
}

.card .complaint .msg {
  color: #467db9;
  font-size: 0.4rem;
  font-weight: 500;
  display: inline-block;
  margin: 0.533333rem 0 0.4rem 0;
}

.card .complaint .verb {
  color: #ababab;
  font-size: 0.4rem;
  font-weight: 500;
  display: inline-block;
  margin: 0.533333rem 0 0.4rem 0;
}

.card :global .adm-card {
  margin: 0.4rem 0.32rem 0 0.32rem;
  box-shadow: 0 0 0.266667rem #e3e3e3;
  border-radius: 0.106667rem;
}

.card :global .adm-card .adm-card-header-title {
  font-size: 0.373333rem;
  font-weight: 500;
}

.card :global .adm-card .adm-card-header {
  border: none;
}

.processingBox :global .adm-card {
  margin: 0.4rem 0.32rem 0 0.32rem;
  box-shadow: 0 0 0.266667rem #e3e3e3;
  border-radius: 0.106667rem;
}

.processingBox :global .adm-card .adm-card-header-title {
  font-size: 0.373333rem;
  font-weight: 500;
}

.processingBox :global .adm-card .adm-card-header {
  border-bottom: 0.026667rem solid #eeeeee;
}

.content :global .adm-card-body {
  padding: 0.133333rem 0 0.4rem 0 !important;
}

.content :global .adm-card-header {
  padding-bottom: 0;
}

.content :global p {
  margin: 0;
  font-size: 0.32rem;
  font-weight: 500;
}

.progress :global .adm-progress-bar {
  width: 100% !important;
}

.progress :global .adm-progress-bar-trail {
  height: 0.133333rem !important;
}

.progress :global .adm-progress-bar-path {
  height: 0.133333rem !important;
  background-color: #4c75a8 !important;
}

.selector :global .adm-selector {
  min-height: 1.92rem;
}

.selector :global .adm-selector .adm-selector-item {
  font-size: 0.346667rem;
  padding: 0.133333rem 0.213333rem 0.133333rem 0.213333rem;
  border: 0.026667rem solid #dcdcdc;
  background-color: #ffffff;
}

.selector :global .adm-selector .adm-selector-item-active {
  border: 0.026667rem solid #1677ff;
  background-color: #e7f1ff;
}