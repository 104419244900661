.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  top: 0;
  left: 0;
}

.container .loading {
  font-size: 0.533333rem;
  text-align: center;
  padding: 0.266667rem;
  color: #2f7dcd;
}

.container .tip {
  font-size: 0.4rem;
  text-align: center;
  color: #000000;
  width: 90vw;
  margin: auto;
}

.container .orderInfo {
  background-color: #ffffff;
  border-top: solid 0.026667rem #e2e2e2;
  border-bottom: solid 0.026667rem #e2e2e2;
  padding: 0 0.4rem;
  margin-top: 0.8rem;
}

.container .orderInfo .title {
  height: 1.333333rem;
  line-height: 1.333333rem;
  border-bottom: solid 0.026667rem #e2e2e2;
  font-size: 0.426667rem;
  color: #000000;
}

.container .orderInfo .body {
  padding: 0.266667rem 0;
  font-size: 0.373333rem;
  color: #9B9B9B;
}

.container .orderInfo .body>div {
  display: flex;
  padding: 0.133333rem 0;
}

.container .orderInfo .body>div>div:nth-child(1) {
  width: 2.666667rem;
}

.container .orderInfo .body>div>div:nth-child(2) {
  flex: auto;
  text-align: right;
}

.container .pickup {
  background-color: #ffffff;
  border-top: solid 0.026667rem #e2e2e2;
  border-bottom: solid 0.026667rem #e2e2e2;
  padding: 0 0.4rem;
  margin-top: 0.8rem;
  line-height: 1.2rem;
  font-size: 0.426667rem;
  color: #000000;
  display: flex;
}

.container .pickup>div:nth-child(1) {
  flex: auto;
}

.container .pickup>div:nth-child(2) {
  text-align: right;
  font-size: 0.373333rem;
  color: #9B9B9B;
}

.container .toolbar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  padding: 0.4rem;
}

.container .toolbar>div:nth-child(1) {
  text-align: center;
  padding-bottom: 0.4rem;
  color: #9b9b9b;
}

.container .toolbar>div:nth-child(1) img {
  width: 0.373333rem;
  position: relative;
  top: 0.08rem;
}

.container .toolbar>div:nth-child(1) span {
  color: #2f7dcd;
}