.wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrap .top {
  text-align: center;
  overflow: hidden;
  flex: 1 1;
}

.wrap .top>span {
  margin-top: 1.066667rem;
  font-weight: 500;
  font-size: 0.373333rem;
  display: inline-block;
}

.wrap .top .paySucceed {
  text-align: center;
  margin-top: 0.8rem;
  font-size: 0.426667rem;
  color: #2D3034;
  line-height: 0.533333rem;
  font-weight: 500;
  overflow: hidden;
}

.wrap .top .paySucceed :global .adm-image {
  margin: 0 auto;
  margin-bottom: 0.4rem;
  margin-top: 0.533333rem;
}

.wrap .top .money {
  margin-top: 0.533333rem;
  font-weight: 600;
  font-size: 0.586667rem;
}

.wrap .top .countDown {
  margin-top: 0.533333rem;
  display: flex;
  font-size: 0.373333rem;
  justify-content: center;
  align-items: center;
}

.wrap .top .countDown>span {
  color: #2F7DCD;
  font-weight: 400;
  display: inline-block;
  margin-top: 0.053333rem;
}

.wrap .top .orderPay {
  width: 7.653333rem;
  color: #F12C20;
  font-size: 0.373333rem;
  font-weight: 400;
  margin: 0 auto;
  margin-top: 0.533333rem;
}

.wrap .top .payBox {
  padding: 0 0.533333rem;
  margin-top: 0.8rem;
}

.wrap .top .payBox .message {
  display: flex;
  justify-content: space-between;
  font-size: 0.4rem;
  margin-bottom: 0.266667rem;
}

.wrap .top .payBox .message :first-child {
  color: #999999;
}

.wrap .look {
  margin-bottom: 0.8rem;
}

.wrap .pay {
  margin-bottom: 0.533333rem;
}

.wrap :global .adm-button-primary {
  width: 95%;
  padding: 0.266667rem 0.586667rem;
  margin: 0 auto;
  margin-top: 0.8rem;
  font-size: 0.373333rem;
  margin-bottom: 0.4rem;
}

.wrap :global .adm-button-default {
  width: 95%;
  padding: 0.266667rem 0.586667rem;
  margin: 0 auto;
  font-size: 0.373333rem;
  background-color: #ffffff;
}

.maskOrder :global .adm-dialog-content {
  text-align: center;
}

.maskOrder :global .adm-dialog-content span {
  color: #467DB9;
}

.maskOrder :global .adm-dialog-footer .adm-dialog-action-row button:nth-child(1) {
  color: #000000 !important;
}

.maskOrder :global .adm-dialog-footer .adm-dialog-action-row button:nth-child(2) {
  font-weight: normal !important;
}