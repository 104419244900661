.container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: #FFFFFF;
  z-index: 100;
}

.container .scene {
  background-color: #f5f5f5;
  height: 100%;
  width: 100%;
  overflow: auto;
}