.jin_center {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.jin_center span {
  font-size: 0.373333rem;
  width: 100%;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  font-size: 0.4rem;
  display: inline-block;
  text-align: right;
}

.jin_center .center {
  color: #C3C3C3;
}

.jin_center_readonly {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  color: #C3C3C3;
}

.jin_center_readonly span {
  font-size: 0.373333rem;
  width: 100%;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  font-size: 0.4rem;
  display: inline-block;
  text-align: right;
}

.jin_center_readonly .center {
  color: #C3C3C3;
}

.searchBox {
  padding: 0.266667rem;
  background-color: #ffffff;
}