.preApply {
  background-color: #f5f5f5;
  height: 100%;
  width: 100vw;
  overflow: auto;
  padding: 0 0.4rem 0.4rem 0.4rem;
}

.preApply .newApply {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.266667rem;
}

.noneData {
  position: absolute;
  left: 33vw;
  width: 33vw;
  top: 30vh;
  text-align: center;
}

.noneData img {
  width: 100%;
}

.noneData>div:last-child {
  font-size: 0.346667rem;
  text-align: center;
  color: #9f9f9f;
  margin-top: 0.266667rem;
}