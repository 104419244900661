.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
  top: 0;
  left: 0;
}

.container .bus {
  width: 100%;
  height: 100%;
}

.container .messagebox {
  position: absolute;
  top: 0;
  left: 0.266667rem;
  right: 0.266667rem;
}

.container .messagebox.indent {
  left: 1.6rem;
}

.container .messagebox>div {
  margin-top: 0.266667rem;
  background: #fff url(../assets/info.png) 0.266667rem 50% no-repeat;
  background-size: 5%;
  border-radius: 0.186667rem;
  padding: 0.213333rem 0.266667rem 0.213333rem 0.8rem;
}

.container .amapContainer {
  width: 100vw;
  height: 100vh;
  background-color: antiquewhite;
  position: relative;
}

.container .amapContainer :global .amap-marker-label {
  border: none;
  background: none;
}

.container .amapContainer :global .lab {
  height: 1.066667rem;
  padding: 0 0.266667rem;
  border-radius: 1.066667rem;
  background-color: white;
  line-height: 1.066667rem;
  font-size: 0.373333rem;
  box-shadow: 0 0 0.533333rem 0 #c1c1c1;
}

.container .menuIcon {
  position: absolute;
  top: 0.213333rem;
  left: 0.133333rem;
  width: 1.28rem;
  height: 1.28rem;
  border-radius: 0.533333rem;
  display: inline-block;
  background: url(../assets/menu.png) center no-repeat;
  background-size: 100% 100%;
}

.container .menuIcon .box {
  width: 1.28rem;
  height: 1.28rem;
}

.container .waiting {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  padding-bottom: calc(-0.4rem + env(safe-area-inset-bottom));
  z-index: 2;
}

.container .waitingPanel {
  width: 92%;
  justify-content: center;
  display: flex;
  align-items: center;
  position: fixed !important;
  bottom: 2%;
  left: 4%;
  border-radius: 0.266667rem;
  position: relative;
  background-color: #ffffff;
  padding-bottom: calc(-0.4rem + env(safe-area-inset-bottom));
}

.container .noneDriverReply {
  height: auto;
  position: relative;
  background-color: #ffffff;
  padding-bottom: calc(-0.4rem + env(safe-area-inset-bottom));
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
}

.container .over {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f5f5f5;
}

.container .over .img {
  width: 40%;
  height: 30%;
  margin: auto;
  margin-top: 30vh;
}

.container .over .img img {
  width: 100%;
}

.container .beginText {
  background-color: white;
  padding: 0.133333rem 0.266667rem;
  border-radius: 1.333333rem;
  box-shadow: 0 0 0.10667rem 0.16rem rgba(0,0,0,0.06);
  width: 3.2rem;
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  white-space: normal;
  display: flex;
  align-items: center;
}

.container .defaultText {
  background-color: white;
  padding: 0.133333rem 0.266667rem;
  border-radius: 1.333333rem;
  box-shadow: 0 0 0.10667rem 0.16rem rgba(0,0,0,0.06);
  width: -webkit-max-content;
  width: max-content;
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  white-space: normal;
  display: flex;
  align-items: center;
  margin-right: 0.106667rem;
}

.container .markerAddress {
  position: absolute;
  top: 30%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.container .markerAddress .address {
  padding: 0.266667rem 0.266667rem;
  border-radius: 1.333333rem;
  background: #ffffff;
  font-size: 0.346667rem;
  box-shadow: 0 0 0.026667rem 0.053333rem rgba(0,0,0,0.06);
  margin: 0 auto;
  text-align: center;
}

.models :global {
  text-align: center;
  color: #b8b8b8 !important;
}

.models :global .adm-dialog-content {
  color: #b8b8b8 !important;
}

.models :global button:nth-child(1) {
  color: var(--runing_addModel_btn_color) !important;
}

.container :global .adm-badge-content {
  min-width: 0.32rem;
  font-size: 0.266667rem;
  padding: 0.053333rem 0.133333rem;
}

.container :global .adm-progress-circle-svg>.adm-progress-circle-fill {
  stroke: var(--runing_showWait5s_circle_color);
}

.appointment :global .adm-dialog-content {
  margin: 0 auto;
  text-align: center;
}

.appointment :global .adm-dialog-content div {
  width: 5.12rem;
}