.Container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  padding: 0.266667rem;
}

.Container .box {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.373333rem;
  border-radius: 0.133333rem;
  margin-bottom: 0.4rem;
}

.Container .box>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0.213333rem 0;
}

.Container .box>div>span {
  font-weight: 500;
  font-size: 0.373333rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.Container .box>div .choose {
  color: #cccc;
}

.Container .box>div>div {
  background-image: var(--habitAddress_empty_background_image);
  color: var(--habitAddress_empty_color);
  padding: 0.08rem 0.266667rem 0.08rem 0.266667rem;
  border-radius: 0.08rem;
}

.Container .box>div>div.disable {
  background-color: #c9c9c9;
}

.Container .box>div:nth-child(1) {
  border-bottom: 0.026667rem solid #eeeeee;
}

.Container .box>div:nth-child(2) {
  padding-top: 0.266667rem;
  padding-bottom: 0;
}

.Container .tip {
  text-align: center;
  color: red;
}