html,
body {
  padding: 0;
  margin: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE、Edge */
  scrollbar-width: none;
  /* Firefox */
}

::-webkit-scrollbar {
  display: none;
  /* Chrome、Safari */
}

.amap-logo {
  display: none;
  opacity: 0 !important;
}

.amap-copyright {
  opacity: 0;
}

.amap-marker-label {
  color: #42A737;
  text-shadow: 0 0.053333rem #FFFFFE, 0.053333rem 0 #FFFFFF, -0.053333rem 0 #FFFFFF, 0 -0.053333rem #FFFFFF;
  font-size: 0.346667rem;
  border: none;
  background-color: transparent;
}

body {
  padding-bottom: env(safe-area-inset-bottom);
}

* {
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
}

input:focus {
  outline: none;
}