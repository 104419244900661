.containerMetting {
  display: flex;
  flex-direction: column;
  background: #F5F5F5;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.containerMetting .searchBar {
  padding: 0.266667rem;
}

.containerMetting .renderSearchRes {
  position: absolute;
  top: 1.386667rem;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: #fafafa;
  z-index: 2;
}

.containerMetting .renderSearchRes .searchRes {
  padding: 0.4rem;
  border-bottom: solid 0.026667rem #cdcdcd;
}

.containerMetting .renderSearchRes .searchRes div {
  color: #353535;
  font-size: 0.373333rem;
}

.containerMetting .renderSearchRes .searchRes>div:last-child {
  color: #ababab;
  font-size: 0.266667rem;
}