.loading {
  text-align: center;
  padding: 0.533333rem 0;
}

.content {
  display: flex;
  justify-content: center;
  margin-bottom: 0.8rem;
}

.content .msg {
  width: 77%;
  text-align: center;
  font-size: 0.373333rem;
}

.cell {
  width: 100%;
  background-color: #ffffff;
  padding: 0 0.666667rem;
  padding-bottom: 0.4rem;
}

.cell .dlt {
  font-size: 0.4rem;
  padding: 0.4rem 0;
}

.cell p {
  display: flex;
  justify-content: space-between;
  padding: 0.053333rem 0;
}

.cell p span {
  color: #9D9D9D;
}

.toolbar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  padding: 0.4rem;
}

.toolbar>div:nth-child(1) {
  text-align: center;
  padding-bottom: 0.4rem;
  color: #9b9b9b;
}

.toolbar>div:nth-child(1) img {
  width: 0.373333rem;
  position: relative;
  top: 0.08rem;
}

.toolbar>div:nth-child(1) span {
  color: #2f7dcd;
}