.details {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  padding-bottom: 2.666667rem;
}

.details .first {
  border-bottom: 0.026667rem solid #D9D9D9;
  padding-bottom: 0.266667rem;
}

.details .last {
  padding-bottom: 0.266667rem;
}

.details .pass {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2.186667rem;
  height: 1.733333rem;
}

.details .refuse {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2.186667rem;
  height: 1.733333rem;
}

.details .lastApproveState {
  color: black;
  border-bottom: 0.026667rem solid #D9D9D9;
  padding: 0.32rem 0;
  margin: 0;
}

.details .marking {
  color: red;
}

.extraServices {
  position: relative;
  padding-bottom: 0.266667rem;
  overflow: hidden;
  border-top: 0.026667rem solid #eeeeee;
}

.service_box {
  margin-bottom: 0.266667rem;
}

.service_box .housekeeper {
  font-size: 0.32rem;
  color: #8ea8c8;
  margin-top: 0.133333rem;
}

.box_expenses {
  display: flex;
  align-items: baseline;
}

.discounts {
  margin: 0;
}

.bar {
  width: 100%;
  position: fixed;
  height: calc(1.333333rem + env(safe-area-inset-bottom));
  background-color: #fafafa;
  display: flex;
  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom);
}

.bar .left {
  width: 50%;
  width: 50%;
  display: flex;
  justify-content: left;
  border-top: 0.026667rem solid #eeeeee;
  border-bottom: 0.026667rem solid #eeeeee;
  padding-left: 0.32rem;
}

.bar .right {
  display: flex;
  flex: 1 1;
}

.through {
  font-size: 0.4rem;
  font-weight: 600;
  text-align: center;
}

.details :global .adm-list {
  margin-top: 0.266667rem;
}

.details :global .adm-button {
  width: 50% !important;
}

.details :global .adm-dialog-content {
  display: inline !important;
}

.details :global .adm-dialog-content .adm-text-area {
  padding: 0.133333rem;
  border: 0.013333rem solid #bbb;
}

.details :global .adm-list-item-content .adm-list-item-content-main {
  font-size: 0.373333rem;
}

.details :global .adm-list-item-content .adm-list-item-content-main div {
  position: relative;
}

.details :global .adm-list-item-content .adm-list-item-content-main div p {
  margin: 0.266667rem 0 0 0;
  font-size: 0.346667rem;
}

.details :global .adm-list-item-content span {
  font-size: 0.32rem;
  color: #000000;
  margin-bottom: 0.48rem;
}

.details :global .adm-collapse-panel-content-inner .adm-list-item .adm-list-item-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.details :global .adm-collapse-panel-content-inner .adm-list-item .adm-list-item-content .adm-list-item-content-main {
  padding-top: 0;
  font-size: 0.373333rem;
}

.details :global .adm-collapse-panel-content-inner .adm-list-item .adm-list-item-content .adm-list-item-content-main p {
  font-size: 0.346667rem;
}

.details :global .adm-collapse-panel-content-inner .adm-list-item .adm-list-item-content .adm-list-item-content-main div {
  position: relative;
}

.details :global .adm-collapse-panel-content-inner .adm-list-item .adm-list-item-content .adm-list-item-content-main div p {
  margin: 0.266667rem 0 0 0;
  font-size: 0.346667rem;
}

.details :global .adm-collapse-panel-content-inner .adm-list-item .adm-list-item-content .adm-list-item-content-main em {
  font-style: normal;
}

.details :global .adm-collapse-panel-content-inner .adm-list-item .adm-list-item-content span {
  font-size: 0.32rem;
  color: #000000;
}

.maskApproval :global .adm-dialog-footer .adm-dialog-action-row button:nth-child(1) {
  color: #000000 !important;
}

.maskApproval :global .adm-dialog-footer .adm-dialog-action-row button:nth-child(2) {
  font-weight: normal !important;
}