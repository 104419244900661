.container {
  background-color: #f5f5f5;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.container .body {
  flex: auto;
  overflow: auto;
  padding: 0 0.4rem 0.4rem 0.4rem;
}

.container .body .nodata {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .body .nodata .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
}

.container .body .nodata .box img {
  width: 4.266667rem;
  height: 3.253333rem;
}

.container .foot {
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.373333rem;
  background-color: #fafafa;
  border-top: solid 0.026667rem #cacbcb;
  display: flex;
  flex-direction: row;
}

.container .foot>div {
  flex: 1 1;
  text-align: center;
}

.container .foot>div:nth-child(2) {
  border-left: solid 0.026667rem #cacbcb;
}