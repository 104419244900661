.wrap {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  padding: 0.533333rem 0.4rem 0.533333rem 0.4rem;
  display: flex;
  flex-direction: column;
}

.wrap .box .rules {
  font-size: 0.426667rem;
  color: #3C4858;
  font-weight: 500;
  margin-bottom: 0.266667rem;
}

.wrap .box .payNum {
  margin-top: 0.533333rem;
  display: flex;
  justify-content: left;
  align-items: center;
  color: #3C4858;
  font-weight: 400;
  font-size: 0.32rem;
}

.wrap .box .payNum .copy {
  font-size: 0.346667rem;
  font-style: inherit;
  display: inline-block;
  padding: 0.053333rem 0.213333rem;
  border-radius: 0.106667rem;
  color: #2F7DCD;
  background-color: #f5f5f5;
  vertical-align: middle;
  margin-left: 0.213333rem;
}

.wrap .box>p {
  margin: 0;
  margin-top: 0.133333rem;
  font-size: 0.32rem;
  color: #9B9B9B;
  font-weight: 400;
}

.wrap .progress {
  flex: 1 1;
}

.wrap .timeDate {
  color: #a4a7ab;
  font-size: 0.346667rem;
  margin-top: 0.08rem;
  font-weight: 400;
}

.wrap .money {
  font-weight: 500;
}

.wrap .accept {
  font-family: PingFangSC-Medium;
  font-size: 0.426667rem;
  font-weight: 500;
}

.wrap .refund {
  font-size: 0.346667rem;
  color: #a4a7ab;
  font-weight: 400;
  margin-top: 0.08rem;
}

.wrap .customerService {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.426667rem;
  color: #3C4858;
  font-weight: 400;
  margin-bottom: 1.066667rem;
}

.wrap .customerService>img {
  width: 0.533333rem;
  height: 0.533333rem;
  margin-right: 0.133333rem;
}

.wrap :global .adm-step-status-finish .adm-step-icon-container {
  color: #2F7DCD;
}

.wrap :global .adm-step-status-finish .adm-step-content .adm-step-title {
  color: #3C4858;
  font-weight: 500;
}

.wrap :global .adm-step-status-process .adm-step-icon-container {
  color: #2F7DCD;
  font-weight: 500;
}

.wrap :global .adm-step-status-wait .adm-step-icon-container {
  color: #e5e5e5;
}

.wrap :global .adm-step-status-wait .adm-step-content .adm-step-title {
  font-weight: 500;
}

.wrap :global .adm-step-status-process .adm-step-title {
  color: #2f7dcd !important;
  font-weight: 500;
}