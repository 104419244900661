.scene {
  margin-top: 0.266667rem;
  background: #ffffff;
  padding: 0 0.266667rem;
  color: #9b9b9b;
  border-radius: 0.213333rem;
  font-size: 0.346667rem;
}

.scene .name {
  font-size: 0.4rem;
  line-height: 0.426667rem;
  padding: 0.266667rem 0;
  color: #000000;
  display: flex;
  justify-content: space-between;
}

.scene .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.266667rem;
}

.scene .info .arrow {
  width: 0.4rem;
  height: 0.4rem;
}

.scene .info .date {
  display: flex;
}

.scene .info .date .datelist {
  display: flex;
  flex-direction: column;
}

.scene .info .date .cell_date {
  min-width: 1.813333rem;
}

.scene .abstract {
  padding: 0 0 0.266667rem 0;
}

.scene .watch {
  border-top: solid 0.026667rem #f2f2f2;
  display: flex;
  padding: 0.133333rem 0;
  font-size: 0.293333rem;
}

.scene .watch>div:first-child {
  flex: auto;
}

.scene .watch>div:last-child {
  width: 0.8rem;
}

.scene .watch>div:last-child img {
  width: 0.346667rem;
  opacity: 0.7;
}

.scene .detail {
  border-top: solid 0.026667rem #f2f2f2;
  padding: 0.266667rem 0;
  position: relative;
}

.scene .detail .up {
  position: absolute;
  right: 0;
  width: 0.8rem;
}

.scene .detail .up img {
  width: 0.346667rem;
  opacity: 0.7;
}

.scene .content {
  line-height: 0.453333rem;
  font-size: 0.346667rem;
  font-family: monospace;
}

.scene .content .line {
  display: flex;
  flex-direction: row;
}

.scene .content .line .kname {
  max-width: 3.733333rem;
  flex-shrink: 0;
}

.scene .content .line .vtext {
  flex: auto;
}