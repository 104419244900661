.wrap {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
  overflow-y: auto;
}

.dateSelect {
  width: 100%;
  position: fixed;
  top: 0;
  line-height: 1.28rem;
  background-color: #FAFAFA;
  display: flex;
  align-items: center;
  z-index: 200;
  box-shadow: 0 0.106667rem 0.266667rem 0 rgba(0,0,0,0.1);
}

.dateSelect .line {
  width: 0.04rem;
  height: 0.373333rem;
  background-color: #C7C7CC;
}

.dateSelect .cell {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  font-size: 0.4rem;
  border-bottom: 0.026667rem solid #eeeeee;
}

.box {
  margin: 0 0.266667rem 0 0.266667rem;
  margin-top: 1.6rem;
  background-color: #ffffff;
  background: #FAFAFA;
  box-shadow: 0 0.106667rem 0.266667rem 0 rgba(0,0,0,0.1);
  border-radius: 0.266667rem;
  padding: 0.266667rem;
}

.box .lattice {
  display: flex;
  flex-direction: column;
  width: 3.68rem;
  margin-bottom: 0.266667rem;
  margin-right: 0.48rem;
}

.box .lattice div {
  font-size: 0.4rem;
  font-weight: 500;
}

.box .lattice span {
  color: #adb3c8;
  display: inline-block;
  margin-bottom: 0.133333rem;
}

.box .lattice .green_cell {
  color: #47a93c;
}

.box .lattice .orange_cell {
  color: #ffa82d;
}

.box .lattice .blue_cell {
  color: #3171f4;
}

.box .lattice .lightBlue_cell {
  color: #94b6f9;
}

.box .borderRight {
  width: 0.066667rem;
  height: 1.066667rem;
  background-color: #eaf1ff;
}

.box .parentBox {
  display: flex;
  justify-content: space-between;
}

th {
  border-bottom: 0.026667rem solid #eaeaea;
  padding-bottom: 0.266667rem;
}

td {
  font-size: 0.32rem;
  padding: 0.266667rem;
}

th span {
  font-size: 0.32rem;
  font-weight: normal;
}

tbody tr:nth-child(2) {
  color: #43a738;
}

tbody tr:nth-child(3) {
  color: #407cf5;
}

tbody tr:nth-child(4) {
  color: #ffaa32;
}

.card {
  padding: 0.266667rem;
}

.card :global .adm-empty {
  height: 6.666667rem !important;
}