.container {
  display: flex;
  flex-direction: column;
  background: #F5F5F5;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.container .bg_meeting {
  width: 100%;
  height: 4.48rem;
  background: url("../../assets/bg_meeting.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container .bg_meeting span {
  color: #ffffff;
  font-size: 0.533333rem;
  font-weight: 600;
}

.container .box {
  position: absolute;
  top: 18%;
  left: 0;
  width: 100%;
}

.container .msg {
  padding: 0 0.32rem;
  color: #3C4858;
  font-size: 0.373333rem;
  font-weight: 500;
}

.container .description {
  font-size: 0.373333rem;
}

.container .money {
  font-size: 0.426667rem;
  color: #000000;
}

.container .btn {
  background: #F5F5F5;
  padding: 0 0.533333rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.866667rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container :global .adm-button {
  background-color: #3c4555;
  border-color: #3c4555;
}