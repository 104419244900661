.container {
  display: flex;
  flex-direction: column;
  background: #F5F5F5;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding-bottom: env(safe-area-inset-bottom);
}

.container .info {
  background: url(../assets/account_bg.png) no-repeat 50% 50%;
  background-size: 100% 100%;
  color: #ffffff;
}

.container .info .companyName {
  font-size: 0.586667rem;
  padding: 0.666667rem 0.666667rem 0 0.666667rem;
}

.container .info .balance {
  margin-top: 0.8rem;
  display: flex;
}

.container .info .balance>div {
  flex: 1 1;
  padding: 0 0.666667rem 0 0.666667rem;
}

.container .info .balance>div>div:nth-child(1) {
  font-size: 0.4rem;
}

.container .info .balance>div>div:nth-child(2) {
  font-size: 0.746667rem;
  line-height: 1.6rem;
}

.container .info .balance>div>div:nth-child(2) span {
  font-size: 0.426667rem;
}

.container .info .change {
  background-color: rgba(255,255,255,0.3);
  display: flex;
  line-height: 1.6rem;
  text-align: center;
  font-size: 0.426667rem;
}

.container .info .change>div {
  flex: 1 1;
}

.container .info .change>div:nth-child(1) {
  border-right: 0.026667rem solid #789bc5;
}

.container .body {
  flex: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.container .body .head {
  height: 1.2rem;
  text-align: center;
  line-height: 1.2rem;
  font-size: 0.4rem;
  background: #FFFFFF;
  box-shadow: 0 0 0 0 #D4D4D4;
  font-weight: bold;
}

.container .body .colhead {
  display: flex;
}

.container .body .colhead>div:nth-child(1) {
  width: 1.866667rem;
  text-align: center;
}

.container .body .colhead>div:nth-child(2) {
  flex: auto;
  text-align: center;
}

.container .body .colhead>div:nth-child(3) {
  width: 1.866667rem;
}

.container .body>:nth-child(2) {
  flex: auto;
  font-size: 0.373333rem;
  overflow: auto;
  height: 0;
}

.container .body>:nth-child(2)>div {
  height: 1.066667rem;
  line-height: 1.066667rem;
}

.container .body>:nth-child(2)>div>div:last-child {
  text-align: center;
}

.container .body>:nth-child(2)>div:nth-child(even) {
  background-color: #ffffff;
}

.container .body>:nth-child(2) .inc {
  color: #F98919;
}

.container .body>:nth-child(2) .inc::before {
  content: '+';
}

.container .body>:nth-child(2) .sub {
  color: #4B81C4;
}

.container .body>:nth-child(2) .sub::before {
  content: '-';
}

.container .body .footbar {
  height: 1.333333rem;
  border-top: solid 0.026667rem #d7d7d7;
  background-color: #fafafa;
  box-shadow: 0 0 0 0 #B2B2B2;
  display: flex;
  text-align: center;
  line-height: 1.333333rem;
  font-size: 0.4rem;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.container .body .footbar>div {
  flex: 1 1;
}

.container .body .footbar>div:nth-child(1) {
  border-right: solid 0.026667rem #d7d7d7;
}

.container .noneData {
  position: absolute;
  left: 33vw;
  width: 33vw;
  top: 20vh;
  text-align: center;
}

.container .noneData img {
  width: 100%;
}

.container .noneData>div:last-child {
  font-size: 0.346667rem;
  text-align: center;
  color: #9f9f9f;
  margin-top: 0.266667rem;
}