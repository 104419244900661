.body {
  font-size: 0.373333rem;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 0.4rem;
  background-color: #FFFFFF;
  z-index: 100;
}

.body .title {
  font-size: 0.533333rem;
  margin-top: 0;
}

.body div {
  margin-top: 0.4rem;
}