.approvalcell {
  background-color: #FFFFFF;
  padding: 0.32rem;
  position: relative;
}

.approvalcell :global .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
  background-color: #5685bb;
}

.approvalcellBorderRadius {
  background-color: #FFFFFF;
  padding: 0.32rem;
  position: relative;
  border-radius: 0.266667rem;
}

.approvalcellBorderRadius :global .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
  background-color: #5685bb;
}

.approvalcellBorderRadius :global .adm-dialog-wrap {
  width: 78vw;
}

.price {
  color: #2D3034;
  display: flex;
}

.price .show {
  flex: 1 1;
  font-size: 0.586667rem;
  text-align: center;
  padding-right: 0.533333rem;
  font-weight: 500;
}

.price .show em {
  font-style: inherit;
  font-size: 0.346667rem;
}

.price .noShow {
  flex: 1 1;
  font-size: 0.586667rem;
  text-align: center;
  font-weight: 500;
}

.price .noShow em {
  font-style: inherit;
  font-size: 0.346667rem;
}

.abnormalBox {
  text-align: center;
}

.cancel_fee {
  display: flex;
  justify-content: center;
  margin-top: 0.106667rem;
}

.cancel_fee div {
  text-align: center;
  color: #F5A623 !important;
  border: 0.026667rem solid #F5A623;
  font-size: 0.32rem;
  padding: 0 0.133333rem;
  border-radius: 0.133333rem;
  margin-left: 0.133333rem;
  font-weight: 400;
}

.abnormal {
  color: #EC5F50;
  font-size: 0.346667rem;
  text-align: center;
  text-decoration: underline;
  margin: 0;
}

.middle {
  width: 100%;
  margin: 0.4rem 0 0.373333rem 0;
  padding: 0.4rem 0;
  display: flex;
  justify-content: space-between;
  border-top: 0.026667rem solid #D9D9D9;
  border-bottom: 0.026667rem solid #D9D9D9;
  font-size: 0.346667rem;
}

.middle div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle .usecar {
  width: 0.373333rem;
  height: 0.4rem;
}

.middle .cartype {
  width: 0.48rem;
  height: 0.373333rem;
}

.middle .city {
  width: 0.293333rem;
  height: 0.4rem;
}

.message {
  position: relative;
  font-size: 0.346667rem;
}

.message p {
  margin: 0;
  margin-bottom: 0.16rem;
  color: #9B9B9B;
}

.message p>span:nth-child(2) {
  word-break: break-all;
}

.message .icon {
  position: absolute;
  top: 1.413333rem;
  right: 0;
  color: #c9c9ce;
  font-weight: 500;
}

.pass {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
}

.refuse {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
}

.tip_message {
  color: red;
  font-size: 0.32rem;
  margin-top: 0.133333rem;
}