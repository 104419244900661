.container .carlevel {
  height: 2.133333rem;
  display: flex;
  background-color: #ffffff;
}

.container .carlevel .beforchoose {
  width: 2.133333rem;
  padding: 0.4rem;
  flex-shrink: 0;
  flex-grow: 0;
}

.container .carlevel .beforchoose>div {
  height: 1.333333rem;
  padding: 0.213333rem;
  text-align: center;
  border-radius: 0.266667rem;
  background-color: #eef3f8;
  color: #3a4656;
  font-size: 0.32rem;
}

.container .carlevel .beforchoose>div.enable {
  background-image: var(--button_background_color);
  color: var(--button_color);
}

.container .carlevel .beforchoose_en {
  width: 1.226667rem;
  padding: 0.4rem 0 0.4rem 0;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0 0.053333rem 0 0.053333rem;
}

.container .carlevel .beforchoose_en>div {
  padding: 0.266667rem 0;
  text-align: center;
  border-radius: 0.266667rem;
  background-color: #eef3f8;
  color: #3a4656;
  font-size: 0.32rem;
}

.container .carlevel .beforchoose_en>div.enable {
  background-color: #2F7DCD;
  color: #ffffff;
}

.container .carlevel .level {
  max-width: 1.866667rem;
  flex: 1 1;
  padding-left: 0.133333rem;
}

.container .carlevel .level .name {
  font-size: 0.426667rem;
  color: #3C4858;
  line-height: 0.426667rem;
  margin-top: 0.533333rem;
  display: flex;
  align-items: center;
}

.container .carlevel .level .name span {
  background-color: var(--manualChoose_carlevel_span_background_color);
  border-radius: 0.266667rem;
  width: 0.373333rem;
  height: 0.373333rem;
  display: inline-block;
  color: var(--manualChoose_carlevel_span_color);
  text-align: center;
  font-size: 0.266667rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .carlevel .level .tip {
  font-size: 0.32rem;
  color: #3C4858;
  line-height: 0.32rem;
  margin-top: 0.133333rem;
}

.container .carlevel .level.active .name {
  color: var(--manualChoose_carlevel_active_color);
  font-size: 0.44rem;
}

.container .carlevel .level.active .tip {
  color: var(--manualChoose_carlevel_active_color);
  font-size: 0.333333rem;
}

.container .carlevel .level.active .underline {
  background-color: var(--manualChoose_carlevel_active_underline_color);
  width: 0.8rem;
  height: 0.08rem;
  border-radius: 0.08rem;
  margin: 0.186667rem 0 0 0.053333rem;
}

.container .carlevel .level_en {
  flex: 1 1;
  margin: 0 0 0 0.026667rem;
}

.container .carlevel .level_en .name {
  font-size: 0.426667rem;
  color: #3C4858;
  line-height: 0.426667rem;
  margin-top: 0.533333rem;
  display: flex;
  align-items: center;
}

.container .carlevel .level_en .name span {
  background-color: var(--manualChoose_carlevel_span_background_color);
  border-radius: 0.266667rem;
  width: 0.373333rem;
  height: 0.373333rem;
  display: inline-block;
  color: var(--manualChoose_carlevel_span_color);
  text-align: center;
  font-size: 0.266667rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .carlevel .level_en .tip {
  font-size: 0.32rem;
  color: #3C4858;
  line-height: 0.32rem;
  margin-top: 0.133333rem;
}

.container .carlevel .level_en.active .name {
  color: #5685BB;
}

.container .carlevel .level_en.active .tip {
  color: #5685BB;
}

.container .carlevel .level_en.active .underline {
  background-color: #5685BB;
  width: 0.8rem;
  height: 0.08rem;
  border-radius: 0.08rem;
  margin: 0.186667rem 0 0 0.053333rem;
}

.container .carlevel_chartered {
  height: 1.6rem;
  display: flex;
  justify-content: space-evenly;
  background-color: #ffffff;
}

.container .carlevel_chartered .beforchoose {
  width: 2.133333rem;
  padding: 0.4rem;
  flex-shrink: 0;
  flex-grow: 0;
}

.container .carlevel_chartered .beforchoose>div {
  height: 1.333333rem;
  padding: 0.213333rem;
  text-align: center;
  border-radius: 0.266667rem;
  background-color: #eef3f8;
  color: #3a4656;
  font-size: 0.32rem;
}

.container .carlevel_chartered .beforchoose>div.enable {
  background-color: #2F7DCD;
  color: #ffffff;
}

.container .carlevel_chartered .level {
  max-width: 1.866667rem;
  flex: 1 1;
  padding-left: 0.133333rem;
}

.container .carlevel_chartered .level .name {
  font-size: 0.426667rem;
  color: #3C4858;
  line-height: 0.426667rem;
  margin-top: 0.533333rem;
}

.container .carlevel_chartered .level .name span {
  background-color: #5685BB;
  border-radius: 0.266667rem;
  width: 0.373333rem;
  height: 0.373333rem;
  display: inline-block;
  color: #ffffff;
  text-align: center;
  font-size: 0.266667rem;
  line-height: 0.373333rem;
  position: relative;
  top: -0.053333rem;
}

.container .carlevel_chartered .level .tip {
  font-size: 0.32rem;
  color: #3C4858;
  line-height: 0.32rem;
  margin-top: 0.133333rem;
}

.container .carlevel_chartered .level.active .name {
  color: #5685BB;
}

.container .carlevel_chartered .level.active .tip {
  color: #5685BB;
}

.container .carlevel_chartered .level.active .underline {
  background-color: #5685BB;
  width: 0.8rem;
  height: 0.08rem;
  border-radius: 0.08rem;
  margin: 0.186667rem 0 0 0.053333rem;
}

.container .cars {
  padding: 0 0.4rem;
  background-color: #ffffff;
  position: relative;
}

.container .cars .notRecommended {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.133333rem 0.266667rem;
  color: #ff9611;
  background-color: #fff6eb;
}

.container .cars .carpannel {
  border-top: solid 0.026667rem #EEF3F8;
  height: 5.866667rem;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.container .cars .carpannel::-webkit-scrollbar {
  display: none;
}

.container .cars .carpannel .head {
  height: 1.066667rem;
  line-height: 1.066667rem;
  font-size: 0.426667rem;
  color: #3C4858;
  display: flex;
  font-weight: bold;
}

.container .cars .carpannel .head>div:nth-child(1) {
  flex: auto;
}

.container .cars .carpannel .head>div:nth-child(2) {
  width: 2.4rem;
  flex-shrink: 0;
  text-align: right;
}

.container .cars .carpannel .head>div:nth-child(3) {
  width: 0.8rem;
  flex-shrink: 0;
  text-align: right;
  padding: 0.266667rem 0 0.266667rem 0.266667rem;
}

.container .cars .carpannel .head .deduction_key {
  font-weight: normal;
  font-size: 0.32rem;
  margin-left: 0.266667rem;
  display: inline-block;
}

.container .cars .carpannel .head .deduction_value {
  font-size: 0.32rem;
  display: inline-block;
  margin-left: 0.08rem;
}

.container .cars .carpannel .head .valuation {
  font-weight: normal !important;
  font-size: 0.346667rem;
  margin-left: 0.266667rem;
  color: #999999;
}

.container .cars .carpannel .head .rightness {
  font-weight: normal !important;
  font-size: 0.373333rem;
  margin-left: 0.266667rem;
  color: #5685BB;
}

.container .cars .carpannel .carlist {
  min-height: 4.8rem;
  padding-bottom: 1.333333rem;
}

.container .cars .carpannel .carlist .car {
  min-height: 1.2rem;
  display: flex;
  font-size: 0.373333rem;
  color: #3d4959;
}

.container .cars .carpannel .carlist .car>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container .cars .carpannel .carlist .car>:nth-child(1) {
  width: 1.2rem;
  flex-shrink: 0;
}

.container .cars .carpannel .carlist .car>:nth-child(1) img {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  overflow: hidden;
  border: solid 0.026667rem #EEF3F8;
}

.container .cars .carpannel .carlist .car>:nth-child(2) {
  flex: auto;
}

.container .cars .carpannel .carlist .car>:nth-child(3) {
  min-width: 3.2rem;
  flex-shrink: 0;
  text-align: right;
  font-size: 0.32rem;
}

.container .cars .carpannel .carlist .car>:nth-child(3) span {
  margin: 0 0.08rem;
}

.container .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(1) span {
  font-size: 0.533333rem;
  color: #455060;
}

.container .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(2) {
  height: auto;
  line-height: 0.533333rem;
  color: #a3a8b0;
}

.container .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(2) span {
  color: var(--manualChoose_car_coupon_color);
}

.container .cars .carpannel .carlist .car>:nth-child(4) {
  width: 0.8rem;
  flex-shrink: 0;
  align-items: flex-end;
}

.container .btncont {
  background: #FFFFFF;
  box-shadow: 0 0 0.16rem 0 rgba(60,72,88,0.2);
  height: calc(2.666667rem + env(safe-area-inset-bottom));
  padding: 0.533333rem 0.4rem 0.533333rem 0.4rem;
  padding-bottom: env(safe-area-inset-bottom);
}

.container .btncont .inner_phone {
  text-align: center;
  height: 0.8rem;
  line-height: 0.666667rem;
  padding: 0.08rem 0;
  font-weight: bolder;
  display: block;
}

.container .btncont .inner_phone span {
  background: url(../../assets/backbtn.png) 95% center no-repeat;
  background-size: auto 90%;
}

.container .btncont .cell {
  display: flex;
  justify-content: space-around;
}

.container .btncont .selectType {
  text-align: center;
  height: 0.8rem;
  padding: 0.08rem 0;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .btncont .selectType span {
  margin-right: 0.106667rem;
}

.container .btncont .inner_phone_none {
  display: none;
}

.container .btncont .btn {
  border-radius: 0.8rem;
  background-image: var(--manualChoose_button_disabled_background_image);
  padding-bottom: 0.16rem;
}

.container .btncont .btn.active {
  background-image: var(--manualChoose_button_background_image);
  border-radius: 0.266667rem;
}

.container .btncont .btn>:nth-child(1) {
  font-size: 0.533333rem;
  color: var(--button_color);
  text-align: center;
  height: 0.933333rem;
  line-height: 1.066667rem;
}

.container .btncont .btn>:nth-child(1) span {
  font-size: 0.32rem;
}

.container .btncont .btn>:nth-child(1) span span {
  color: var(--manualChoose_button_interval_color);
}

.container .btncont .btn>:nth-child(2) {
  font-size: 0.32rem;
  color: var(--button_color);
  text-align: center;
}

.container .btncont .btn>:nth-child(2) span {
  color: var(--manualChoose_button_count_color);
}

.container .btncont .btn_bus {
  height: 1.6rem;
  border-radius: 0.8rem;
  background-image: var(--manualChoose_button_disabled_background_image);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .btncont .btn_bus.active {
  background-image: var(--manualChoose_button_background_image);
}

.container .btncont .btn_bus>:nth-child(1) {
  font-size: 0.533333rem;
  color: var(--button_color);
  text-align: center;
}

.container .btncont .btn_bus>:nth-child(1) span {
  font-size: 0.32rem;
}

.container .btncont .btn_bus>:nth-child(1) span span {
  color: var(--manualChoose_button_interval_color);
}

.container .btncont_top {
  background: #FFFFFF;
  box-shadow: 0 0 0.16rem 0 rgba(60,72,88,0.2);
  height: calc(2.666667rem + env(safe-area-inset-bottom));
  padding: 0 0.4rem 0.533333rem 0.4rem;
  padding-bottom: env(safe-area-inset-bottom);
}

.container .btncont_top .inner_phone {
  text-align: center;
  height: 0.8rem;
  line-height: 0.666667rem;
  padding: 0.08rem 0;
  font-weight: bolder;
  display: block;
}

.container .btncont_top .inner_phone span {
  background: url(../../assets/backbtn.png) 95% center no-repeat;
  background-size: auto 90%;
}

.container .btncont_top .cell {
  display: flex;
  justify-content: space-around;
}

.container .btncont_top .selectType {
  text-align: center;
  height: 0.8rem;
  padding: 0.08rem 0;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .btncont_top .selectType span {
  margin-right: 0.106667rem;
}

.container .btncont_top .inner_phone_none {
  display: none;
}

.container .btncont_top .btn {
  border-radius: 0.8rem;
  background-image: var(--manualChoose_button_disabled_background_image);
  padding-bottom: 0.16rem;
}

.container .btncont_top .btn.active {
  background-image: var(--manualChoose_button_background_image);
}

.container .btncont_top .btn>:nth-child(1) {
  font-size: 0.533333rem;
  color: var(--button_color);
  text-align: center;
  height: 0.933333rem;
  line-height: 1.066667rem;
}

.container .btncont_top .btn>:nth-child(1) span {
  font-size: 0.32rem;
}

.container .btncont_top .btn>:nth-child(1) span span {
  color: var(--manualChoose_button_interval_color);
}

.container .btncont_top .btn>:nth-child(2) {
  font-size: 0.32rem;
  color: var(--button_color);
  text-align: center;
}

.container .btncont_top .btn>:nth-child(2) span {
  color: var(--manualChoose_button_count_color);
}

.container .btncont_top .btn_bus {
  height: 1.6rem;
  border-radius: 0.8rem;
  background-image: var(--manualChoose_button_disabled_background_image);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .btncont_top .btn_bus.active {
  background-image: var(--manualChoose_button_background_image);
}

.container .btncont_top .btn_bus>:nth-child(1) {
  font-size: 0.533333rem;
  color: var(--button_color);
  text-align: center;
}

.container .btncont_top .btn_bus>:nth-child(1) span {
  font-size: 0.32rem;
}

.container .btncont_top .btn_bus>:nth-child(1) span span {
  color: var(--manualChoose_button_interval_color);
}

.container .cbx>div {
  width: 0.533333rem;
  height: 0.533333rem;
  border: solid 0.026667rem #C8C8CD;
  border-radius: 0.266667rem;
}

.container .cbx.checked>div {
  background: var(--manualChoose_car_checked_image) no-repeat 0% 0%;
  background-size: 100% 100%;
  border: none;
}

.box {
  text-align: center;
}

.box .msg {
  font-size: 0.32rem;
  margin-bottom: 0;
  text-align: left;
}

.rules {
  border-bottom: 0.093333rem solid var(--button_border_color);
}

.title {
  display: inline-block;
  margin: 0.213333rem 0;
  font-size: 0.346667rem;
}

.container :global .adm-button {
  display: none;
}

.container :global .adm-modal-body .adm-space {
  display: none;
}

.container :global .adm-modal-body .adm-modal-title {
  margin-bottom: 0;
  font-size: 0.453333rem;
}

.container :global tr td,
.container :global tr th {
  border: #c4c4c4 0.026667rem solid;
}

.container :global td {
  font-size: 0.32rem;
  padding: 0.133333rem;
}

.container :global th {
  font-size: 0.346667rem;
}

.container :global th span {
  font-size: 0.32rem;
  font-weight: normal;
}

.container :global tr td:first-child {
  color: #5997d7;
  font-weight: 500;
}

.container :global .adm-modal-wrap {
  min-width: 95% !important;
}

.set_phone {
  padding: 0.133333rem 0.4rem 0.4rem 0.4rem;
}

.set_phone .title {
  text-align: center;
  font-size: 0.426667rem;
  font-weight: bolder;
  display: block;
}

.set_phone .phone {
  height: 1.2rem;
  display: flex;
  background-color: #f9f9f9;
  border-radius: 0.266667rem;
  margin-bottom: 0.4rem;
  padding: 0 0.266667rem;
}

.cell :global .adm-image {
  margin-right: 0.08rem;
}

.selectType :global .adm-popup-body-position-bottom {
  border-top-left-radius: 0.4rem !important;
  border-top-right-radius: 0.4rem !important;
}

.btn_details {
  font-size: 0.373333rem;
  color: #000000;
  font-weight: 500;
}

.btn_close {
  font-size: 0.373333rem;
  color: #898989;
  font-weight: 400;
}