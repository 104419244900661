.card {
  padding: 0.266667rem;
}

.title {
  font-size: 0.346667rem;
  font-weight: 500;
  border-bottom: 0.026667rem solid #efefef;
  padding-bottom: 0.32rem;
}

.echarts {
  width: 100%;
}

.card :global .adm-empty {
  height: 6.666667rem !important;
}