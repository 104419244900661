.payed {
  margin-top: 0.4rem;
  background-color: #ffffff;
  color: #9B9B9B;
  font-size: 0.373333rem;
}

.payed .title {
  display: flex;
  color: #000000;
  padding: 0.4rem;
  font-size: 0.373333rem;
  border-bottom: solid 0.026667rem #d9d9d9;
}

.payed .title>div:nth-child(1) {
  flex: auto;
  word-break: break-all;
}

.payed .title>div:nth-child(2) {
  min-width: 3.066667rem;
  text-align: right;
}

.payed .moneyReturn {
  color: #F5A623;
}

.payed .body {
  padding-left: 0.4rem;
}

.payed .body>div {
  display: flex;
  padding: 0.266667rem 0.4rem 0.266667rem 0;
  border-bottom: solid 0.026667rem #d9d9d9;
}

.payed .body>div>div:nth-child(1) {
  flex: auto;
}

.payed .body>div>div:nth-child(1)>div {
  padding: 0.08rem 0;
}

.payed .body>div>div:nth-child(2) {
  color: #000000;
}

.payed .payStyle {
  width: 0.533333rem;
  height: 0.533333rem;
}

.payed .payWxStyle {
  width: 0.666667rem;
  height: 0.666667rem;
}

.payed .foot {
  height: 1.466667rem;
  line-height: 1.466667rem;
  display: flex;
  color: #000000;
  padding: 0 0.4rem;
  font-size: 0.4rem;
}

.payed .foot>div:nth-child(1) {
  flex: auto;
  font-size: 0.426667rem;
}

.payed .foot>div:nth-child(2) {
  font-size: 0.64rem;
  display: flex;
  justify-content: center;
  align-items: center;
}