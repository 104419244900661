.showWait5s {
  text-align: center;
}

.showWait5s .msg {
  color: var(--runing_showWait5s_color);
}

.showWait5s .countDown {
  width: 0.8rem;
  height: 0.8rem;
  line-height: 0.72rem;
  margin: auto;
  margin-top: 0.4rem;
  border-radius: 100%;
}

.showWait5s .countdown {
  color: #42a737;
}

.showWait5s .energy {
  font-weight: 500;
  font-size: 0.4rem;
  margin: 0.266667rem 0 0.16rem 0;
}

.showWait5s .wait {
  color: #42a737;
  font-size: 0.32rem;
}

.showWait5s .waitBlue {
  color: var(--runing_showWait5s_color);
}