.modal_wrap {
  width: 100%;
}

.modal_wrap :global .adm-modal-wrap {
  min-width: 8.586667rem;
}

.modal_wrap :global .adm-modal-content {
  padding: 0 0.426667rem 0.32rem !important;
}

.modal_wrap :global .adm-button {
  margin-top: 0.266667rem;
}

.modal_wrap :global .adm-modal-body {
  background-image: linear-gradient(180deg, #E7EEF9 0%, #fff 24%, #fff 100%);
  box-shadow: 0 0 0.266667rem 0 rgba(60,80,100,0.15);
}

.modal_wrap .modal_box .modal_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.4rem;
  font-weight: 500;
  color: #121213;
  margin-bottom: 0.133333rem;
}

.modal_wrap .modal_box .modal_prompt {
  font-size: 0.32rem;
}

.modal_wrap .modal_box .message {
  font-size: 0.373333rem;
  padding: 0.266667rem 0.426667rem 0.266667rem 0.426667rem;
  margin: 0.266667rem 0 0.666667rem 0;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0 0.16rem 0.426667rem 0 rgba(60,72,88,0.2);
  border-radius: 0.266667rem;
}

.modal_wrap .modal_box .message span {
  font-size: 0.426667rem;
  color: var(--runing_dispatch_span);
}