.waitingTime {
  z-index: 100;
  position: absolute;
  top: 10%;
  left: calc(50vw - 2rem);
  width: 4rem;
  height: 1.333333rem;
  background-color: #ffffff;
  border-radius: 0.666667rem;
  padding: 0.4rem 0;
  box-shadow: 0 0.08rem 0.16rem 0 rgba(0,0,0,0.05);
}

.waitingTime>div {
  display: flex;
  flex-direction: row;
  color: #272727;
  line-height: 0.533333rem;
  font-size: 0.373333rem;
}

.waitingTime>div>div:nth-child(1) {
  border-right: solid 0.053333rem #d8d8d8;
  flex: 1 1;
  text-align: right;
  padding-right: 0.266667rem;
}

.waitingTime>div>div:nth-child(2) {
  color: var(--countDown_waitingTime_color);
  flex: 1 1;
  padding-left: 0.266667rem;
}