.orders {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}

.orders .dateSelect {
  width: 100%;
  position: fixed;
  top: 0;
  line-height: 1.28rem;
  background-color: #FAFAFA;
  display: flex;
  border-top: 0.026667rem solid #eeeeee;
  z-index: 200;
  align-items: center;
  box-shadow: 0 0.16rem 0.426667rem 0 rgba(60,72,88,0.2);
}

.orders .dateSelect div {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  font-size: 0.4rem;
  border-bottom: 0.026667rem solid #eeeeee;
  color: #3C4858;
}

.orders .dateSelect div img {
  width: 0.32rem;
  height: 0.32rem;
}

.orders .dateSelect span {
  background: #EBECEE;
  height: 0.533333rem;
  width: 0.053333rem;
  display: inline-block;
}

.orders .nodata {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orders .nodata .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
}

.orders .nodata .box img {
  width: 4.266667rem;
  height: 3.253333rem;
}

.orders .msg {
  width: 100%;
  height: 0.8rem;
  text-align: center;
  margin-top: 0.266667rem;
  line-height: 0.8rem;
  color: #ccccd0;
}

.orders .loadingbox {
  display: flex;
  height: 0.8rem;
  align-items: center;
  justify-content: center;
}

.orders .loading {
  text-align: center;
  color: #ccccd0;
}

.orders .orderlist {
  width: 100%;
  margin-top: 1.546667rem;
  padding: 0 0.266667rem 0 0.266667rem;
}

.orders .orderlist .box:nth-child(1) {
  margin-top: 0;
}

.isScroll {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  padding-bottom: 2.666667rem;
  background-color: #f5f5f5;
}

.orders :global .adm-mask {
  display: none !important;
}

.orders :global .adm-picker-header {
  display: none !important;
}

.orders :global .adm-infinite-scroll {
  padding: 0;
}

.orders :global .adm-button {
  background-color: #2F7DCD;
  border-radius: 0.08rem;
}

.status :global(.adm-space-item) svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.song {
  width: 100%;
}

.foot {
  z-index: 100;
  width: 100vw;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0.4rem 0.266667rem;
  border: 0.026667rem solid #ffffff;
  box-shadow: 0 -0.16rem 0.426667rem 0 rgba(60,72,88,0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.foot .foot_statistics {
  font-size: 0.373333rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.foot .foot_statistics span {
  color: #3483D0;
}

.order_invoice_btn {
  flex: 1 1;
}

.order_invoice_btn :global .adm-button {
  padding: 0.133333rem;
  border-radius: 1.333333rem;
}

.order_btn {
  width: 2.133333rem;
}

.order_btn :global .adm-button {
  padding: 0.133333rem;
  border-radius: 1.333333rem;
}

:global .adm-picker-header-button {
  color: var(--adm_picker_header_button_color);
}