.activityContent {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 75%;
  max-width: 13.333333rem;
  overflow: hidden;
  text-align: center;
}

.activityContent .box {
  width: 6.666667rem;
  height: auto;
  margin: 0 auto;
  border-radius: 0.266667rem;
  position: relative;
}

.activityContent .box img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 0.266667rem;
}

.activityContent .box .authentication {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
}

.activityContent .box .authentication div {
  width: 2.666667rem;
  margin-bottom: 0.373333rem;
}

.activityContent .close {
  margin-top: 0.4rem;
}