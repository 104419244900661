.container {
  background-color: #f5f5f5;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.container :global .adm-tabs {
  background-color: #fafafa;
}

.container .body {
  flex: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.container .body>div:nth-child(1) {
  flex: auto;
  overflow: auto;
  padding: 0 0.4rem 0.4rem 0.4rem;
}

.container .body>div:nth-child(1) .nodata {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.container .body>div:nth-child(1) .nodata .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  margin-top: 60%;
}

.container .body>div:nth-child(1) .nodata .box img {
  width: 4.266667rem;
  height: 3.253333rem;
}

.container .body .footbar {
  height: 1.2rem;
  line-height: 1.2rem;
  background-color: #fafafa;
  border-top: solid 0.026667rem #d9d9d9;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 0 0.266667rem;
}

.container .body .footbar>div:nth-child(1) {
  flex: auto;
}