.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: scroll;
  padding: 0.266667rem;
  display: flex;
  flex-direction: column;
}

.container .box {
  flex-shrink: 0;
}

.container .msg {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2F7DCD;
  box-shadow: 0 0.266667rem 0.533333rem 0 rgba(60,80,100,0.15);
  border-radius: 0.213333rem;
  font-size: 0.346667rem;
  color: #F5F5F5;
  padding: 0.16rem 0;
}

.container .msg img {
  width: 0.373333rem;
  height: 0.4rem;
  margin-right: 0.133333rem;
}

.container .standard {
  background: #FFFFFF;
  box-shadow: 0 0.266667rem 0.533333rem 0 rgba(60,80,100,0.15);
  border-radius: 0.213333rem;
  padding: 0.266667rem;
  margin-top: 0.266667rem;
}

.container .standard div {
  font-size: 0.373333rem;
  color: #141414;
  font-weight: 500;
}

.container .standard p {
  font-size: 0.346667rem;
  color: #141414;
  font-weight: 400;
}

.container .standard p:last-child {
  margin-bottom: 0;
}

.container .doubt {
  background: #FFFFFF;
  box-shadow: 0 0.266667rem 0.533333rem 0 rgba(60,80,100,0.15);
  border-radius: 0.213333rem;
  padding: 0.266667rem;
  margin-top: 0.266667rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container .doubt span:first-child {
  font-size: 0.346667rem;
  color: #141414;
  letter-spacing: 0;
  font-weight: 500;
}

.container .doubt span:last-child {
  font-size: 0.32rem;
  color: #FFFFFF;
  letter-spacing: 0;
  font-weight: 500;
  background: #2F7DCD;
  border-radius: 0.373333rem;
  padding: 0.133333rem 0.213333rem 0.133333rem 0.213333rem;
}

.container .checked {
  background: #FFFFFF;
  box-shadow: 0 0.266667rem 0.533333rem 0 rgba(60,80,100,0.15);
  border-radius: 0.213333rem;
  margin-top: 0.266667rem;
  padding: 0.266667rem;
}

.container .checked img {
  width: 0.4rem;
  height: 0.4rem;
}

.container .checked_state {
  display: flex;
  align-items: center;
}

.container .checked_state span {
  font-size: 0.373333rem;
  color: #141414;
  font-weight: 500;
  margin-left: 0.16rem;
}

.container .checked :global .adm-text-area textarea {
  font-size: 0.346667rem;
  color: #141414;
  letter-spacing: 0;
  font-weight: 400;
  background: #F9F9F9;
  border-radius: 0.133333rem;
  padding: 0.266667rem;
  margin-top: 0.266667rem;
}

.container .submit {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 1.066667rem;
}

.container :global .adm-button {
  background: #2F7DCD;
  border-radius: 0.213333rem;
  padding: 0.266667rem;
  font-weight: 500;
}

.container .tip_message {
  color: red !important;
  font-size: 0.32rem !important;
}