.container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 0.373333rem;
}

.container .info {
  background: url(../assets/account_bg.png) no-repeat 50% 50%;
  background-size: 100% 100%;
  padding: 0.666667rem 0 0.666667rem 0.666667rem;
}

.container .info .balance {
  flex: auto;
}

.container .info .balance .lab {
  opacity: 0.6;
  font-size: 0.373333rem;
  color: #FFFFFF;
  line-height: 0.586667rem;
}

.container .info .balance>div:nth-child(2) {
  font-size: 0.853333rem;
  color: #FFFFFF;
}

.container>div:nth-child(2) {
  flex: auto;
  overflow: auto;
  height: 0;
  padding: 0 0.4rem;
}

.container>div:nth-child(2)>div {
  margin: 0.4rem 0;
}

.container>div:nth-child(2) .lab {
  font-size: 0.4rem;
  font-weight: bold;
}

.container>div:nth-child(2) .amounts {
  overflow: hidden;
  padding: 0.4rem 0;
}

.container>div:nth-child(2) .amounts .amount {
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  background-color: #ffffff;
  border: solid 0.026667rem #D4D4D4;
  border-radius: 0.053333rem;
}

.container>div:nth-child(2) .amounts .amount.checked {
  background-color: #2F7DCD;
  color: #ffffff;
}

.container>div:nth-child(2) .otherAmount {
  border: solid 0.026667rem #D4D4D4;
  display: flex;
  height: 0.96rem;
  line-height: 0.96rem;
}

.container>div:nth-child(2) .otherAmount>div:nth-child(1) {
  width: 2.666667rem;
  padding-left: 0.266667rem;
}

.container>div:nth-child(2) .otherAmount>div:nth-child(2) {
  flex: auto;
}

.container>div:nth-child(2) .invitationCode {
  border: solid 0.026667rem #D4D4D4;
  height: 0.96rem;
  line-height: 0.96rem;
  margin-top: 0.4rem;
  padding: 0.16rem 0.266667rem;
}

.container>div:nth-child(2) span {
  color: #71a6dd;
}

.container>div:nth-child(2) .tip {
  color: #F34747;
  font-size: 0.32rem;
  margin-top: 0.266667rem;
}

.container .footbar {
  padding: 0.4rem;
}