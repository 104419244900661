.loading {
  margin-left: 45vw;
  margin-top: 40vh;
}

.pop_loading {
  margin-left: 45vw;
  margin-top: 50%;
}

.loading_move {
  width: 0.533333rem;
  height: 0.533333rem;
  margin: 0 auto;
  margin-top: 40vh;
}