.shareBar {
  padding: 0.4rem 0.4rem 0.8rem 0.4rem;
}

.shareBar .bar {
  border-radius: 0.266667rem;
  padding: 0.4rem;
  background-color: #ffffff;
  text-align: center;
}

.shareBar .bar .title {
  font-size: 0.4rem;
  font-weight: bold;
  padding-bottom: 0.533333rem;
}

.shareBar .bar .tools {
  display: flex;
  flex-direction: row;
}

.shareBar .bar .tools>div {
  flex: 1 1;
  overflow: hidden;
  line-height: 0.933333rem;
}

.shareBar .btn {
  width: 1.226667rem;
  height: 1.226667rem;
  border-radius: 0.186667rem;
  background-color: #0082ef;
  margin: auto;
}

.shareBar .btn img {
  width: 100%;
  height: 100%;
}

.shareBar .btn.green {
  background-color: #42a737;
}