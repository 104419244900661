.container {
  background-color: #f5f5f5;
  position: fixed;
  height: 100%;
  width: 100%;
  padding-top: 1.093333rem;
  overflow: scroll;
}

.container :global .adm-tabs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  background-color: #fafafa;
  z-index: 10;
}

.container .body {
  position: relative;
}

.container .body .noneCoupon {
  position: absolute;
  left: 33vw;
  width: 33vw;
  top: 20vh;
}

.container .body .noneCoupon img {
  width: 90%;
}

.container .body .noneCoupon>div:last-child {
  font-size: 0.346667rem;
  text-align: center;
  color: #9f9f9f;
  margin-top: 0.266667rem;
}