.trip {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
}

.trip .cell .time {
  color: #808080;
  font-size: 0.346667rem;
  padding: 0.32rem 0.32rem 0.32rem 0;
  border-bottom: 0.026667rem solid #D9D9D9;
}

.trip .cell .box {
  display: flex;
  padding: 0.32rem 0.32rem 0.32rem 0;
  justify-content: space-between;
  align-items: center;
}

.trip .cell .box>div:nth-child(1) {
  flex: 1 1;
}

.trip .cell .box>div:nth-child(1) p {
  margin: 0;
  font-size: 0.346667rem;
}

.trip .cell .box>div:nth-child(1)>p:nth-child(1) {
  margin-bottom: 0.32rem;
}

.trip .cell .box>div:nth-child(2) {
  font-size: 0.453333rem;
  font-weight: 500;
}

.trip .cell .box>div:nth-child(2) span {
  font-size: 0.346667rem;
  font-weight: normal;
}

.trip :global .adm-list {
  margin-top: 0.266667rem;
}

.trip :global .adm-list-item-content {
  padding: 0;
}

.trip :global .adm-list-item-content-main {
  padding: 0;
}