.appendCars {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.appendCars .cars {
  padding: 0 0.4rem;
  background-color: #ffffff;
  flex: auto;
  overflow: auto;
}

.appendCars .cars .carpannel {
  border-top: solid 0.026667rem #EEF3F8;
}

.appendCars .cars .carpannel .head {
  height: 1.066667rem;
  line-height: 1.066667rem;
  font-size: 0.426667rem;
  color: #3C4858;
  display: flex;
  font-weight: bold;
}

.appendCars .cars .carpannel .head>div:nth-child(1) {
  flex: auto;
}

.appendCars .cars .carpannel .head>div:nth-child(2) {
  width: 2.4rem;
  flex-shrink: 0;
  text-align: right;
}

.appendCars .cars .carpannel .head>div:nth-child(3) {
  width: 0.8rem;
  flex-shrink: 0;
  text-align: right;
  padding: 0.266667rem 0 0.266667rem 0.266667rem;
}

.appendCars .cars .carpannel .carlist .car {
  height: 1.2rem;
  display: flex;
  font-size: 0.373333rem;
  color: #3d4959;
}

.appendCars .cars .carpannel .carlist .car>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.appendCars .cars .carpannel .carlist .car>:nth-child(1) {
  width: 1.2rem;
  flex-shrink: 0;
}

.appendCars .cars .carpannel .carlist .car>:nth-child(1) img {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  margin-top: 0.186667rem;
  overflow: hidden;
  border: solid 0.026667rem #EEF3F8;
}

.appendCars .cars .carpannel .carlist .car>:nth-child(2) {
  flex: auto;
}

.appendCars .cars .carpannel .carlist .car>:nth-child(3) {
  min-width: 3.2rem;
  flex-shrink: 0;
  text-align: right;
  font-size: 0.32rem;
}

.appendCars .cars .carpannel .carlist .car>:nth-child(3) span {
  margin: 0 0.08rem;
}

.appendCars .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(1) span {
  font-size: 0.533333rem;
  color: #455060;
}

.appendCars .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(2) {
  height: auto;
  line-height: 0.533333rem;
  color: #a3a8b0;
}

.appendCars .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(2) span {
  color: #00ab19;
}

.appendCars .cars .carpannel .carlist .car>:nth-child(4) {
  width: 0.8rem;
  flex-shrink: 0;
  text-align: right;
  padding: 0.266667rem 0 0.266667rem 0.266667rem;
}

.appendCars .loading {
  padding: 0 0.4rem;
  background-color: #ffffff;
  flex: auto;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.4rem;
}

.appendCars .btncont {
  background: #FFFFFF;
  box-shadow: 0 0 0.16rem 0 rgba(60,72,88,0.2);
  height: 2.666667rem;
  padding: 0.533333rem 0.4rem;
}

.appendCars .btncont .btn {
  height: 1.6rem;
  border-radius: 0.266667rem;
  background-image: var(--manualChoose_button_disabled_background_image);
}

.appendCars .btncont .btn.active {
  background-image: var(--manualChoose_button_background_image);
  border-radius: 0.266667rem;
}

.appendCars .btncont .btn>:nth-child(1) {
  font-size: 0.533333rem;
  color: var(--button_color);
  text-align: center;
  height: 0.933333rem;
  line-height: 1.066667rem;
}

.appendCars .btncont .btn>:nth-child(1) span {
  font-size: 0.32rem;
}

.appendCars .btncont .btn>:nth-child(1) span span {
  color: var(--manualChoose_button_interval_color);
}

.appendCars .btncont .btn>:nth-child(2) {
  font-size: 0.32rem;
  color: var(--button_color);
  text-align: center;
  height: 0.533333rem;
}

.appendCars .btncont .btn>:nth-child(2) span {
  color: var(--manualChoose_button_count_color);
}

.appendCars .cbx>div {
  width: 0.533333rem;
  height: 0.533333rem;
  border: solid 0.026667rem #C8C8CD;
  border-radius: 0.266667rem;
}

.appendCars .cbx.checked>div {
  background: var(--manualChoose_car_checked_image) no-repeat 0% 0%;
  background-size: 100% 100%;
  border: none;
}