.runing {
  width: 100%;
}

.runing .displacement {
  width: 100%;
  background-color: #ecf6eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.213333rem;
  border-top-right-radius: 0.266667rem;
  border-top-left-radius: 0.266667rem;
}

.runing .displacement .cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.runing .displacement .cell :global .adm-image {
  margin-right: 0.213333rem;
}

.runing .departure_base {
  position: absolute;
  top: -0.213333rem;
}

.runing .departure {
  position: absolute;
  bottom: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  place-content: flex-end;
}

.runing .departure .set_out {
  width: -webkit-max-content;
  width: max-content;
  padding: 0.133333rem 0.186667rem 0.133333rem 0.186667rem;
  background-color: #ffffff;
  border-radius: 0.346667rem;
  color: #455060;
  box-shadow: 0 0 0.10667rem 0.16rem rgba(0,0,0,0.06);
}

.runing .departure .address {
  width: -webkit-max-content;
  width: max-content;
  text-align: center;
  margin-top: 0.266667rem;
  padding: 0.133333rem 0.186667rem 0.133333rem 0.186667rem;
  border-radius: 0.346667rem;
  background: #ffffff;
  color: #455060;
  font-size: 0.346667rem;
  box-shadow: 0 0 0.026667rem 0.053333rem rgba(0,0,0,0.06);
}

.runing .departure .remind {
  width: -webkit-max-content;
  width: max-content;
  padding: 0.133333rem 0.186667rem 0.133333rem 0.186667rem;
  background-color: #ffffff;
  border-radius: 0.346667rem;
  color: #455060;
  margin-top: 0.266667rem;
  display: flex;
  box-shadow: 0 0 0.10667rem 0.16rem rgba(0,0,0,0.06);
}

.runing .departure .message {
  padding: 0 0.266667rem;
  display: inline-block;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.32rem;
  background-color: white;
  border-radius: 0.8rem;
  margin-top: 0.266667rem;
  width: -webkit-max-content;
  width: max-content;
}

.runing .departure .message .distance {
  color: var(--runingView_distance_color);
  font-size: 0.373333rem;
}

.runing .departure .message .time {
  color: var(--runingView_time_color);
  font-size: 0.373333rem;
}

.runing .departure .message.small_bell {
  background: var(--runingView_small_bell) white 0.186667rem 50% no-repeat;
  background-size: 5%;
  padding-left: 0.666667rem;
}

.runing .departure .message.small_bell_one {
  background: var(--runingView_small_bell) white 0.186667rem 50% no-repeat;
  background-size: 6%;
  padding-left: 0.666667rem;
}

.runing .tip {
  height: 1.333333rem;
  width: 100%;
  position: absolute;
  top: -1.333333rem;
  left: 0;
  display: flex;
}

.runing .tip>div:nth-child(1) {
  flex: auto;
}

.runing .tip .message {
  margin: 0.266667rem;
  padding: 0 0.266667rem;
  display: inline-block;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.32rem;
  background-color: white;
  border-radius: 0.8rem;
}

.runing .tip .message .distance {
  color: #dfb45f;
  font-size: 0.373333rem;
}

.runing .tip .message .time {
  color: #77ac75;
  font-size: 0.373333rem;
}

.runing .tip .airportPickup {
  margin: 0.266667rem;
  padding: 0 0.266667rem;
  display: inline-block;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.32rem;
  background-color: white;
  border-radius: 0.8rem;
}

.runing .tip .airportPickup span {
  color: #438ad1;
}

.runing .driver {
  display: flex;
}

.runing .driver .avatar {
  padding: 0.4rem;
  flex-shrink: 0;
}

.runing .driver .avatar img {
  width: 1.333333rem;
  height: 1.333333rem;
}

.runing .driver .info {
  padding: 0.4rem 0 0 0;
  flex: auto;
  font-size: 0.346667rem;
  line-height: 0.586667rem;
}

.runing .driver .info>div:nth-child(1) {
  font-size: 0.426667rem;
  line-height: 0.64rem;
  font-weight: bold;
  display: flex;
}

.runing .driver .info>div:nth-child(1) .star {
  font-size: 0.346667rem;
  color: var(--runingView_star_color);
}

.runing .driver .info>div:nth-child(1) .starGreen {
  font-size: 0.346667rem;
  color: #42a737;
}

.runing .driver .phone {
  padding: 0.4rem;
  flex-shrink: 0;
}

.runing .driver .phone img {
  width: 1.2rem;
  height: 1.2rem;
}

.runing .cancel_tip {
  margin: 0 0.266667rem;
  padding: 0.266667rem;
  border-radius: 0.186667rem;
  position: relative;
  top: 0.266667rem;
  background-color: #f3f3f3;
  font-size: 0.346667rem;
}

.runing .cancel_tip>div>span {
  color: var(--runingView_cancel_tip);
  font-weight: bold;
}

.runing .toolbar {
  padding: 0.266667rem;
}

.runing .toolbar .tools {
  display: flex;
  border-top: 0.026667rem solid #D5D5D5;
  padding-top: 0.4rem;
}

.runing .toolbar .tools.no_border {
  border-top: none;
}

.runing .toolbar .tools .changeDest img {
  width: 0.373333rem;
}

.runing .toolbar .tools .changeBlack {
  color: #131415;
}

.runing .toolbar .tools .changeGrey {
  color: #C4C4C4;
}

.runing .toolbar .tools>div {
  flex: 1 1;
  flex-shrink: 0;
  text-align: center;
  font-size: 0.346667rem;
  color: #131415;
}

.runing .toolbar .tools>div img {
  width: 0.426667rem;
  height: 0.426667rem;
}

.runing .toolbar .tools>div>div:last-child {
  margin-top: 0.133333rem;
  border-right: 0.026667rem solid #D5D5D5;
}

.runing .toolbar .tools>div:last-child>div:last-child {
  margin-top: 0.133333rem;
  border-right: none;
}

.runing .grey_color {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.runing :global .adm-popup-body-position-bottom {
  border-top-left-radius: 0.266667rem;
  border-top-right-radius: 0.266667rem;
}

.prompt :global .adm-dialog-content {
  display: inline !important;
}

.prompt :global .adm-dialog-content .adm-text-area {
  padding: 0.133333rem;
  border: 0.013333rem solid #bbb;
}