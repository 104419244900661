.write {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.write .up {
  flex: 1 1;
}

.write .translation {
  flex: 1 1;
}

.write .companyWrap {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.write .grayColor {
  color: #cccccc;
  font-weight: normal;
}

.write .voiceTitle {
  width: 7.546667rem;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 0.08rem;
}

.write .defautBox {
  width: 100vw;
  text-align: right;
  font-size: 0.32rem !important;
}

.write .pop {
  position: absolute;
  top: 1.146667rem;
  right: 0.053333rem;
  z-index: 100;
  background-color: white;
  border-radius: 0.266667rem;
  padding: 0.213333rem;
  text-align: left;
  box-shadow: 0 0 0.213333rem #9e9e9e8c;
}

.write .pop p {
  font-size: 0.373333rem;
  font-weight: normal;
  padding-bottom: 0.053333rem;
}

.write .listCell {
  position: relative;
}

.write .cellDefault :global .adm-list-item-content {
  padding-right: 0;
}

.write .address {
  position: relative;
}

.pop {
  text-align: center;
}

.pop .title {
  position: relative;
}

.pop .title svg {
  position: absolute;
  top: 0;
  right: 0;
}

.pop .title .confirmTitle {
  font-size: 0.373333rem;
  font-weight: 500;
}

.pop .companyBox {
  margin-bottom: 0.266667rem;
}

.pop .companyName {
  text-align: left;
  font-size: 0.32rem !important;
  font-weight: 500;
  margin: 0;
  padding-bottom: 0.373333rem;
}

.pop .taxNumber {
  font-size: 0.32rem !important;
}

.prompt p {
  margin: 0;
  padding-bottom: 0.32rem;
  padding-top: 0.4rem;
  margin-bottom: 0.32rem;
  font-weight: 500;
  border-bottom: 0.026667rem solid #F5F5F5;
}

.write :global .adm-list-body {
  overflow: inherit;
}

.write :global .adm-text-area-element {
  height: 2.133333rem;
  padding: 0.266667rem;
}

.write :global .adm-list {
  margin-top: 0.266667rem;
  overflow: inherit;
}

.write :global .adm-button-block {
  width: 95%;
  padding: 0.186667rem 0.586667rem;
  margin: 0 auto;
  margin-bottom: 1.066667rem;
  font-size: 0.373333rem;
  background-color: #2f7dcd;
}

.listCell :global .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
}

.listCell :global .adm-list-item-content .adm-list-item-content-main div {
  font-size: 0.373333rem;
  font-weight: 500;
}

.listCell :global .adm-list-item-content .adm-list-item-content-main>div:nth-child(1) span {
  vertical-align: middle;
  color: red;
}

.listCell :global .adm-list-item-content .adm-list-item-content-main>div:nth-child(2) {
  font-size: 0.346667rem;
  overflow-y: scroll;
}

.listCell :global .adm-input {
  text-align: right;
  font-size: 0.346667rem;
  min-height: 0.533333rem;
}

.address :global .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
}

.address :global .adm-list-item-content .adm-list-item-content-main div {
  font-size: 0.373333rem;
  font-weight: 500;
}

.address :global .adm-list-item-content .adm-list-item-content-main>div:nth-child(1) {
  min-width: 1.6rem;
}

.address :global .adm-list-item-content .adm-list-item-content-main>div:nth-child(1) span {
  vertical-align: middle;
  color: red;
}

.address :global .adm-list-item-content .adm-list-item-content-main>div:nth-child(2) {
  font-size: 0.346667rem;
  overflow-y: scroll;
}

.address :global .adm-input {
  text-align: right;
  font-size: 0.346667rem;
  min-height: 0.533333rem;
  width: 5.333333rem !important;
}

.address :global .adm-input input {
  width: 5.333333rem !important;
}

.popItem :global .adm-list-item-content {
  padding: 0.266667rem 0.32rem 0.266667rem 0;
}

.popItem :global .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
}

.popItem :global .adm-list-item-content .adm-list-item-content-main div {
  font-size: 0.373333rem;
  font-weight: 500;
}

.popItem :global .adm-list-item-content .adm-list-item-content-main>div:nth-child(1) {
  color: #808080;
}

.popItem :global .adm-list-item-content .adm-list-item-content-main>div:nth-child(2) {
  font-size: 0.373333rem;
  flex: 1 1;
  text-align: left;
  padding-left: 0.8rem;
}

.pop :global .adm-button-block {
  width: 95%;
  padding: 0.266667rem 0.586667rem;
  margin: 0 auto;
  font-size: 0.373333rem;
  margin-top: 0.666667rem;
  background-color: #2f7dcd;
}

.note :global .adm-list-inner {
  padding: 0.266667rem;
}

.note :global .adm-list-inner .adm-text-area-wrapper textarea {
  font-size: 0.373333rem;
}

.prompt :global .adm-dialog-body {
  padding: 0 !important;
}

.prompt :global .adm-dialog-body .adm-dialog-body-message {
  padding: 0.266667rem 0.32rem;
}

.prompt :global .adm-dialog-body .adm-dialog-content .stateInvoice {
  text-align: center;
}

.prompt :global .adm-dialog-body .adm-dialog-body-header {
  width: 100%;
  text-align: center;
}

.voiceTitle :global .adm-input-wrapper {
  margin-right: 0.133333rem;
}

.popWrap :global .adm-popup-body {
  padding-top: 0;
}