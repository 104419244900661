.pay {
  margin-top: 0.4rem;
  padding: 0 0.4rem;
  background-color: #ffffff;
  color: #9B9B9B;
  font-size: 0.373333rem;
}

.pay .title {
  display: flex;
  align-items: center;
  padding: 0.4rem 0;
  border-bottom: solid 0.026667rem #d9d9d9;
}

.pay .title>div:nth-child(2) {
  text-align: center;
  flex: auto;
}

.pay .title>div:nth-child(2)>div:nth-child(1) {
  color: #000000;
}

.pay .title>div:nth-child(2)>div:nth-child(1) span {
  font-size: 0.64rem;
  font-weight: bold;
}

.pay .title>div:nth-child(2)>div:nth-child(2) {
  margin-top: 0.186667rem;
}

.pay .title>div:nth-child(2)>div:nth-child(2).alert {
  color: #EC5F50;
}

.pay .body {
  display: flex;
  padding: 0.266667rem 0;
  align-items: center;
}

.pay .body>div:nth-child(1) {
  flex: auto;
}

.pay .body>div:nth-child(1) div {
  line-height: 0.693333rem;
}