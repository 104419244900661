.report {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #f7f7f7;
  padding-bottom: env(safe-area-inset-bottom);
}

.report .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.266667rem 0 0.266667rem 0;
  border-bottom: 0.026667rem solid #D4D4D4;
  background-color: #f7f7f7;
  position: fixed;
  z-index: 100;
  top: 0;
}

.report .header div {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report .header .start {
  border-right: 0.026667rem solid #979797;
}

.report .header .time {
  font-size: 0.373333rem;
  margin-top: 0.08rem;
}

.report .header .statistics {
  color: #a6a6a6;
  margin: 0 0.08rem 0 0.16rem;
  max-width: 1.466667rem;
}

.report .header .echarts {
  width: 100%;
}

.report .card {
  padding: 0.266667rem;
}

.report .card .operation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.533333rem;
}

.report .card .operation :global .adm-button {
  padding: 0.08rem 0.293333rem;
  font-size: 0.346667rem;
}

.report>div:nth-child(2) {
  padding-top: 1.466667rem;
}

.card :global .adm-card-body {
  padding-top: 0.133333rem;
}

.card :global .adm-card {
  border-radius: 0.133333rem;
}

.card :global .adm-card .adm-card-header {
  border-bottom: none;
}

.card :global .adm-card .adm-card-header-title {
  font-size: 0.373333rem;
  font-weight: 500;
}

.report :global .adm-empty {
  height: 100%;
  padding: 1.386667rem 0;
}

.report :global .adm-empty .adm-empty-image-container img {
  width: 3.84rem;
  height: 2.933333rem;
}

.report :global .adm-empty .adm-empty-description {
  font-size: 0.32rem;
}