.container {
  background-color: white;
  border-radius: 0.133333rem;
  padding-left: 0.266667rem;
  padding-right: 0.266667rem;
  margin-bottom: 0.266667rem;
}

.container .cityName {
  border-bottom: solid 0.026667rem #e7e7e7;
  font-size: 0.4rem;
  padding: 0.266667rem 0;
  font-weight: bold;
}

.container .ss {
  border-radius: 0.133333rem;
}

.container .ss .city {
  border-bottom: solid 0.026667rem #e7e7e7;
  padding-top: 0.266667rem;
}

.container .ss .city:last-child {
  border-bottom: none;
}

.container .ss .city .tip {
  font-size: 0.32rem;
  line-height: 0.533333rem;
  color: #9d9d9d;
  position: relative;
  top: -0.266667rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  padding-top: 0.4rem;
}

.container .ss .city .doors {
  line-height: 0.533333rem;
  font-size: 0.32rem;
}

.container .ss .city .doors .door {
  width: 33%;
  padding: 0 0.266667rem 0.266667rem 0.266667rem;
  display: inline-block;
}

.container .ss .city .doors .door div {
  border: solid 0.026667rem #f1f1f1;
  text-align: center;
  padding: 0.133333rem 0;
  box-shadow: 0 0 0.133333rem 0 rgba(60,72,88,0.1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.container .morebutton {
  border-top: solid 0.026667rem #e7e7e7;
  font-size: 0.266667rem;
  color: #9d9d9d;
  text-align: center;
  height: auto;
  line-height: 0.853333rem;
}