.wrap {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
}

.wrap .list {
  margin-top: 0.266667rem;
}

.wrap .num {
  width: 5.333333rem;
}

.wrap .captcha {
  width: 4.266667rem;
}

.wrap .captcha img {
  width: 100%;
}

.wrap .count {
  min-width: 2rem;
  text-align: center;
  padding: 0.08rem 0.133333rem;
  color: var(--setphone_count_color);
  background-image: var(--setphone_count_background_image);
  border-radius: 0.053333rem;
  font-size: 0.32rem;
}

.wrap .msg {
  padding: 0 0.266667rem;
  color: #9B9B9B;
}

.wrap .lightColor {
  min-width: 2.133333rem;
  text-align: center;
  padding: 0.08rem 0.133333rem;
  color: var(--setphone_light_color);
  background-color: var(--setphone_light_background_color);
  border-radius: 0.053333rem;
  font-size: 0.32rem;
}

.wrap :global .adm-button-block {
  width: 95%;
  padding: 0.266667rem 0.586667rem;
  margin: 0 auto;
  margin-top: 0.8rem;
  font-size: 0.373333rem;
  background-color: #2f7dcd;
}

.phone :global .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
}

.phone :global .adm-list-item-content .adm-list-item-content-main div {
  font-size: 0.373333rem;
  font-weight: 500;
}

.phone :global .adm-list-item-content .adm-list-item-content-main .adm-input-wrapper .adm-input {
  font-size: 0.373333rem;
  text-align: right !important;
}

.code :global .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
}

.code :global .adm-list-item-content .adm-list-item-content-main div {
  font-weight: 500;
}

.code :global .adm-list-item-content .adm-list-item-content-main .adm-input-wrapper {
  width: 40% !important;
  padding-right: 0.533333rem;
}

.code :global .adm-list-item-content .adm-list-item-content-main .adm-input-wrapper .adm-input {
  font-size: 0.373333rem;
}