.addreessBook {
  height: 100vh;
}

.addreessBook .name {
  margin-bottom: 0.133333rem;
  font-weight: bold;
  font-size: 0.48rem;
}

.addreessBook .phone {
  color: #9b9b9b;
  font-size: 0.426667rem;
}