.containerAdd {
  display: flex;
  flex-direction: column;
  background: #F5F5F5;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.containerAdd .searchBar {
  padding: 0.266667rem;
}

.containerAdd .renderSearchRes {
  position: absolute;
  top: 1.386667rem;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: #fafafa;
  z-index: 2;
}

.containerAdd .renderSearchRes .searchRes {
  padding: 0.4rem;
  border-bottom: solid 0.026667rem #cdcdcd;
}

.containerAdd .renderSearchRes .searchRes div {
  color: #353535;
  font-size: 0.373333rem;
}

.containerAdd .renderSearchRes .searchRes>div:last-child {
  color: #ababab;
  font-size: 0.266667rem;
}

.containerAdd .project {
  padding: 0.266667rem 0.4rem 0.533333rem 0.266667rem;
  background: #ffffff;
  font-size: 0.373333rem;
  border-top: solid 0.026667rem #dadada;
  border-bottom: solid 0.026667rem #d9d9d9;
}

.containerAdd .project span {
  color: #7d7d7d;
}

.containerAdd .btn {
  background: #F5F5F5;
  padding: 0 0.4rem;
  height: 1.866667rem;
  display: flex;
  align-items: center;
  justify-content: center;
}