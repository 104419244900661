.invoiceDetails {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: scroll;
}

.invoiceDetails .consult {
  margin-bottom: 1.066667rem;
}

.invoiceDetails .consult p {
  color: #9B9B9B;
  text-align: left;
  margin: 0;
  margin-top: 0.266667rem;
  padding-left: 0.266667rem;
}

.invoiceDetails .mandatory {
  color: red;
}

.invoiceDetails :global .adm-button-block {
  width: 95%;
  padding: 0.32rem 0.586667rem;
  margin: 0 auto;
  font-size: 0.373333rem;
  margin-top: 0.666667rem;
  background-color: #2f7dcd;
}

.message :global .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
}

.message :global .adm-list-item-content .adm-list-item-content-main div {
  font-size: 0.346667rem;
  font-weight: 500;
}

.message :global .adm-list-item-content .adm-list-item-content-main>div:nth-child(2) {
  font-size: 0.346667rem;
}

.message :global .adm-input {
  text-align: right;
  font-size: 0.346667rem;
}

.list :global .adm-list {
  margin-top: 0.266667rem;
}

.cell :global .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.cell :global .adm-list-item-content .adm-list-item-content-main div {
  font-size: 0.373333rem;
  font-weight: 500;
}

.cell :global .adm-list-item-content .adm-list-item-content-main>div:nth-child(1) {
  color: #808080;
  min-width: 1.493333rem;
}

.cell :global .adm-list-item-content .adm-list-item-content-main>div:nth-child(2) {
  font-size: 0.373333rem;
  flex: 1 1;
  text-align: left;
  padding-left: 0.8rem;
}

.cell :global .adm-list-item-content .adm-list-item-content-main .adm-input {
  font-size: 0.373333rem;
}

.cellTrip :global .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.cellTrip :global .adm-list-item-content .adm-list-item-content-main div {
  font-size: 0.373333rem;
  font-weight: 500;
}

.cellTrip :global .adm-list-item-content .adm-list-item-content-main>div:nth-child(1) {
  color: #808080;
}

.cellTrip :global .adm-list-item-content .adm-list-item-content-main>div:nth-child(2) {
  font-size: 0.373333rem;
  flex: 1 1;
  text-align: right;
  padding-left: 0.8rem;
  color: #808080;
}