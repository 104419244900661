.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
  padding: 0;
  margin: 0;
}

.container .qqmap {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}