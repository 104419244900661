.box {
  padding: 0.266667rem 0.266667rem 0 0.266667rem;
  position: relative;
}

.box .box_call {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.266667rem;
}

.box .cell {
  display: flex;
}

.box .service span {
  font-size: 0.32rem;
}

.box .service .servicePhone {
  color: #2f7dcd;
}

.box .box_time {
  width: 100%;
  background-color: #eef3f8;
  border-radius: 0.266667rem;
  padding: 0.08rem 0.213333rem 0.08rem 0.213333rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.266667rem;
}

.box .box_time span {
  font-size: 0.346667rem;
  color: #455060;
  display: inline-block;
}

.box .box_time .waitForTime {
  margin-left: 0.213333rem;
}

.box .box_time .bell {
  margin-left: 0.133333rem;
}

.box :global .adm-button {
  color: #1679ff;
  background-color: #eef3f8;
  border: none;
  font-size: 0.32rem;
}

.box :global .adm-swiper-track-inner {
  overflow: hidden;
}

.appointment :global .adm-dialog-content {
  margin: 0 auto;
  text-align: center;
}

.appointment :global .adm-dialog-content div {
  width: 5.12rem;
}