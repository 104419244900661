.notice {
  height: 0.746667rem;
  line-height: 0.746667rem;
  z-index: 2;
  background-color: white;
  display: flex;
}

.notice .message {
  font-size: 0.426667rem;
  font-weight: 500;
  color: #455060;
}

.notice .loopImg {
  width: 0.426667rem;
  height: 0.426667rem;
  margin-top: 0.133333rem;
  margin-left: 0.053333rem;
}