.summary {
  padding: 0.266667rem;
  font-size: 0.426667rem;
  display: flex;
  background-color: #ffffff;
  color: #141414;
}

.summary>div:nth-child(1) {
  flex: auto;
  line-height: 0.906667rem;
  display: flex;
}

.summary>div:nth-child(1) img {
  width: 0.533333rem;
  height: 0.533333rem;
  margin-left: 0.186667rem;
  margin-top: 0.186667rem;
  -webkit-animation: turn 1s linear infinite;
  animation: turn 1s linear infinite;
}

.summary>div:nth-child(2) {
  max-width: 5.333333rem;
  text-align: right;
}

.summary>div:nth-child(2) button {
  font-size: 0.346667rem;
  background-color: #f5f6f6;
  border-radius: 0.133333rem;
  border: none;
  color: #141414;
}

.manualOrderDetail {
  margin: 0 0.266667rem;
  border-top: solid 0.026667rem #efefef;
  padding: 0.266667rem 0;
  color: #2d2d2d;
  max-height: 5.333333rem;
  overflow: auto;
}

.manualOrderDetail .cars {
  background-color: #ffffff;
}

.manualOrderDetail .cars .carpannel .head {
  height: 1.066667rem;
  line-height: 1.066667rem;
  font-size: 0.426667rem;
  color: #3C4858;
  display: flex;
  font-weight: bold;
}

.manualOrderDetail .cars .carpannel .head>div:nth-child(1) {
  flex: auto;
}

.manualOrderDetail .cars .carpannel .head>div:nth-child(2) {
  width: 2.4rem;
  flex-shrink: 0;
  text-align: right;
}

.manualOrderDetail .cars .carpannel .head>div:nth-child(3) {
  width: 0.8rem;
  flex-shrink: 0;
  text-align: right;
  padding: 0.266667rem 0 0.266667rem 0.266667rem;
}

.manualOrderDetail .cars .carpannel .carlist .car {
  height: 1.2rem;
  display: flex;
  font-size: 0.373333rem;
  color: #3d4959;
}

.manualOrderDetail .cars .carpannel .carlist .car>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.manualOrderDetail .cars .carpannel .carlist .car>:nth-child(1) {
  width: 1.2rem;
  flex-shrink: 0;
}

.manualOrderDetail .cars .carpannel .carlist .car>:nth-child(1) img {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  overflow: hidden;
  border: solid 0.026667rem #EEF3F8;
}

.manualOrderDetail .cars .carpannel .carlist .car>:nth-child(3) {
  width: 2.4rem;
  flex-shrink: 0;
  text-align: right;
  flex: 1 1;
  font-size: 0.32rem;
}

.manualOrderDetail .cars .carpannel .carlist .car>:nth-child(3) span {
  margin: 0 0.08rem;
}

.manualOrderDetail .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(1) {
  height: auto;
}

.manualOrderDetail .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(1) span {
  font-size: 0.533333rem;
  color: #455060;
}

.manualOrderDetail .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(2) {
  height: auto;
  line-height: 0.533333rem;
  color: #a3a8b0;
}

.manualOrderDetail .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(2) span {
  color: #00ab19;
}

.manualOrderDetail .cars .carpannel .carlist .car>:nth-child(4) {
  width: 0.8rem;
  flex-shrink: 0;
  text-align: right;
  padding: 0.266667rem 0 0.266667rem 0.266667rem;
}

.manualOrderDetailHousekeeper {
  margin: 0 0.266667rem;
  border-top: solid 0.026667rem #efefef;
  padding: 0.266667rem 0;
  color: #2d2d2d;
  max-height: 3.733333rem;
  overflow: auto;
}

.manualOrderDetailHousekeeper .cars {
  background-color: #ffffff;
}

.manualOrderDetailHousekeeper .cars .carpannel .head {
  height: 1.066667rem;
  line-height: 1.066667rem;
  font-size: 0.426667rem;
  color: #3C4858;
  display: flex;
  font-weight: bold;
}

.manualOrderDetailHousekeeper .cars .carpannel .head>div:nth-child(1) {
  flex: auto;
}

.manualOrderDetailHousekeeper .cars .carpannel .head>div:nth-child(2) {
  width: 2.4rem;
  flex-shrink: 0;
  text-align: right;
}

.manualOrderDetailHousekeeper .cars .carpannel .head>div:nth-child(3) {
  width: 0.8rem;
  flex-shrink: 0;
  text-align: right;
  padding: 0.266667rem 0 0.266667rem 0.266667rem;
}

.manualOrderDetailHousekeeper .cars .carpannel .carlist .car {
  height: 1.2rem;
  display: flex;
  font-size: 0.373333rem;
  color: #3d4959;
}

.manualOrderDetailHousekeeper .cars .carpannel .carlist .car>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.manualOrderDetailHousekeeper .cars .carpannel .carlist .car>:nth-child(1) {
  width: 1.2rem;
  flex-shrink: 0;
}

.manualOrderDetailHousekeeper .cars .carpannel .carlist .car>:nth-child(1) img {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  overflow: hidden;
  border: solid 0.026667rem #EEF3F8;
}

.manualOrderDetailHousekeeper .cars .carpannel .carlist .car>:nth-child(3) {
  width: 2.4rem;
  flex-shrink: 0;
  text-align: right;
  flex: 1 1;
  font-size: 0.32rem;
}

.manualOrderDetailHousekeeper .cars .carpannel .carlist .car>:nth-child(3) span {
  margin: 0 0.08rem;
}

.manualOrderDetailHousekeeper .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(1) {
  height: auto;
}

.manualOrderDetailHousekeeper .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(1) span {
  font-size: 0.533333rem;
  color: #455060;
}

.manualOrderDetailHousekeeper .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(2) {
  height: auto;
  line-height: 0.533333rem;
  color: #a3a8b0;
}

.manualOrderDetailHousekeeper .cars .carpannel .carlist .car>:nth-child(3)>div:nth-child(2) span {
  color: #00ab19;
}

.manualOrderDetailHousekeeper .cars .carpannel .carlist .car>:nth-child(4) {
  width: 0.8rem;
  flex-shrink: 0;
  text-align: right;
  padding: 0.266667rem 0 0.266667rem 0.266667rem;
}

.toolBar {
  padding: 0.4rem;
}