.contact {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
}

.contact .list {
  margin-top: 0.266667rem;
}

.contact .box {
  display: flex;
  width: 73%;
}

.contact .box :global .adm-input {
  width: auto;
}

.contact .box span {
  width: 50%;
  height: 1.04rem;
  line-height: 1.04rem;
  padding-left: 0.266667rem;
  font-size: 0.373333rem;
  border-left: 0.026667rem solid #D9D9D9;
  display: inline-block;
  text-align: center;
  color: var(---contact_span_color);
}

.contact .msg {
  padding: 0 0.266667rem 0 0.613333rem;
  margin-top: 0.266667rem;
}

.contact .msg li {
  margin-bottom: 0.133333rem;
  color: #9B9B9B;
}

.contact :global .adm-button-block {
  width: 95%;
  padding: 0.266667rem 0.586667rem;
  margin: 0 auto;
  margin-top: 0.8rem;
  font-size: 0.373333rem;
  background-color: #2f7dcd;
}

.listCell :global .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
}

.listCell :global .adm-list-item-content .adm-list-item-content-main div {
  font-size: 0.373333rem;
  font-weight: 500;
}

.listCell :global .adm-input-element {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.listCell :global .adm-input {
  width: 73%;
  font-size: 0.373333rem !important;
}

.listCell .captcha {
  width: 3.093333rem;
}

.listCell .captcha img {
  width: 100%;
}

.validation :global .adm-list-item-content {
  padding: 0 0.32rem 0 0;
  height: 1.04rem;
}

.validation :global .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
}

.validation :global .adm-list-item-content-main div {
  font-size: 0.373333rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.validation :global .adm-list-item-content-main div input {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.validation :global .adm-input-wrapper {
  width: 60%;
}

.validation :global .adm-input-wrapper .adm-input {
  font-size: 0.373333rem !important;
}