.container {
  display: grid;
  grid-gap: 0.213333rem;
  font-size: 0.316267rem;
}

.container .item {
  background-color: #f3f3f3;
  border-radius: 0.133333rem;
  color: #373737;
}

.container .item.active {
  border: 0.053333rem solid #4b75b2;
  background-color: #F0F3F8;
  color: #4b75b2;
}

.container .item .label_wrap {
  padding: 0.213333rem 0.266667rem;
}

.container .item .textarea_wrap {
  border-top: 0.026667rem solid #EBEBEB;
  padding: 0.213333rem 0.266667rem;
}