.approval {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}

.approval .bar {
  width: 100%;
  position: fixed;
  height: calc(1.333333rem + env(safe-area-inset-bottom));
  background-color: #fafafa;
  display: flex;
  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom);
}

.approval .bar .left {
  width: 44%;
  display: flex;
  justify-content: left;
  border-top: 0.026667rem solid #eeeeee;
  border-bottom: 0.026667rem solid #eeeeee;
  padding-left: 0.32rem;
}

.approval .bar .right {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  border-top: 0.026667rem solid #eeeeee;
  border-bottom: 0.026667rem solid #eeeeee;
}

.approval .loadingbox {
  display: flex;
  height: 0.8rem;
  align-items: center;
  justify-content: center;
}

.approval .loading {
  text-align: center;
  color: #ccccd0;
}

.approval .msg {
  width: 100%;
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #ccccd0;
}

.approval .alreadyApprovalBar {
  width: 100%;
  height: calc(1.333333rem + env(safe-area-inset-bottom));
  position: fixed;
  bottom: 0;
  background-color: #FAFAFA;
  display: flex;
  border-top: 0.026667rem solid #eeeeee;
  z-index: 200;
  padding-bottom: env(safe-area-inset-bottom);
}

.approval .alreadyApprovalBar div {
  text-align: center;
  border-bottom: 0.026667rem solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  font-size: 0.4rem;
}

.approval .alreadyApprovalBar div:nth-child(1) {
  border-right: 0.026667rem solid #eeeeee;
}

.approval .cell_approval {
  max-width: 100%;
  margin: 0.266667rem;
  box-shadow: 0 0.16rem 0.426667rem 0 rgba(60,72,88,0.2);
  border-radius: 0.266667rem;
}

.maskApproval :global .adm-dialog-footer .adm-dialog-action-row button:nth-child(1) {
  color: #000000 !important;
}

.maskApproval :global .adm-dialog-footer .adm-dialog-action-row button:nth-child(2) {
  font-weight: normal !important;
}

.search_approval {
  background-color: #ffffff;
  margin: 0.266667rem;
  padding: 0.213333rem;
  border-radius: 0.213333rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_approval img {
  width: 0.48rem;
  height: 0.48rem;
  margin-right: 0.213333rem;
}

.search_approval :global .adm-input-element {
  font-size: 0.373333rem;
  font-weight: normal;
  margin-top: 0.053333rem;
}

.popupApproval :global .adm-collapse .adm-list-default {
  overflow: scroll !important;
}

.popupApproval :global .adm-collapse .adm-collapse-panel-content {
  overflow: scroll !important;
}

.through {
  font-size: 0.4rem;
  font-weight: 600;
  text-align: center;
}

.approval :global .adm-tabs-tab-list {
  width: 100%;
  position: fixed;
  background-color: #FFFFFF;
  z-index: 1;
}

.approval :global .adm-tabs-tab {
  font-size: 0.4rem;
}

.approval :global .adm-tabs-content {
  overflow: hidden;
  background-color: #f5f5f5;
  padding: 0.986667rem 0 1.333333rem 0;
  height: 100vh;
  overflow: auto;
}

.approval :global .adm-tabs-content .adm-empty {
  height: 100%;
}

.approval :global .adm-tabs-content .adm-empty .adm-empty-image-container img {
  width: 1.786667rem;
  height: 2.346667rem;
}

.approval :global .adm-tabs-content .adm-empty .adm-empty-description {
  font-size: 0.32rem;
}

.approval :global .adm-dialog-body-title {
  font-size: 0.426667rem;
}

.approval :global .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
  background-color: #5685bb;
}

.approvalDetailsPop :global .adm-list-item-content-main {
  font-size: 0.32rem;
  color: #9B9B9B;
}

.detail {
  display: flex;
  width: 100%;
  color: #000000;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.133333rem;
}

:global .adm-dialog-body-title {
  font-size: 0.4rem !important;
}

:global .adm-dialog-button {
  font-size: 0.4rem !important;
}

:global .adm-popup {
  z-index: 100;
}

.right :global .adm-button {
  width: 50%;
  border-radius: 1.333333rem;
  margin-right: 0.266667rem;
}

.prompt :global .adm-dialog-content {
  display: inline !important;
}

.prompt :global .adm-dialog-content .adm-text-area {
  padding: 0.133333rem;
  border: 0.013333rem solid #bbb;
}