.countDown {
  padding: 0 0.266667rem;
  display: inline-block;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.32rem;
  background-color: white;
  border-radius: 0.8rem;
  margin-top: 0.266667rem;
  width: -webkit-max-content;
  width: max-content;
}

.countDown .time {
  color: #77ac75;
  font-size: 0.373333rem;
}