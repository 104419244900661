.record {
  margin-top: 0.4rem;
  padding-left: 0.266667rem;
  background-color: #ffffff;
  border-radius: 0.266667rem;
  font-size: 0.373333rem;
  color: #465161;
  position: relative;
  overflow: hidden;
}

.record>* {
  box-sizing: border-box;
}

.record .checkSign {
  display: none;
}

.record.checked {
  border: solid 0.026667rem #42a637;
}

.record.checked .checkSign {
  display: block;
  position: absolute;
  right: -0.026667rem;
  bottom: -0.026667rem;
  width: 0;
  height: 0;
  border-top: 0.8rem solid transparent;
  border-right: 1.333333rem solid #42a637;
}

.record.checked .checkSign svg {
  position: absolute;
  bottom: 0.133333rem;
  right: -1.173333rem;
}

.record .head {
  height: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.record .head .checkbox {
  padding-right: 0.133333rem;
}

.record .head .orderNo {
  font-size: 0.346667rem;
  flex: auto;
  font-weight: bold;
  display: flex;
  justify-content: left;
  align-items: center;
}

.record .head .step {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.record .head .step>div {
  border-radius: 0.266667rem 0 0 0.266667rem;
  background-color: #2f7dcd;
  font-size: 0.32rem;
  color: #ffffff;
  height: 0.666667rem;
  line-height: 0.666667rem;
  padding-left: 0.16rem;
  padding-right: 0.16rem;
}

.record .head .step>div.reject {
  background-color: #ff4731;
}

.record .head .step>div.success {
  background-color: #42A737;
}

.record .body {
  border-top: solid 0.026667rem #eeeeee;
  padding: 0.133333rem 0;
}

.record .body>div {
  line-height: 0.533333rem;
  font-size: 0.346667rem;
}

.record .approve {
  border-top: solid 0.026667rem #eeeeee;
}

.record .approve .title {
  height: 1.2rem;
  line-height: 1.2rem;
  display: flex;
  flex-direction: row;
}

.record .approve .title>div:nth-child(1) {
  flex: auto;
  font-weight: bold;
}

.record .approve .title>div:nth-child(2) {
  padding: 0 0.186667rem;
}

.record .approve :global .adm-steps-vertical {
  padding: 0 0 0.4rem 0;
}

.record .approve .stepTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.record .approve .stepTitle>div:nth-child(1) {
  flex: auto;
  font-size: 0.346667rem;
  color: #465161;
}

.record .approve .stepTitle>div:nth-child(2) {
  color: #abafb6;
  padding-right: 0.266667rem;
  font-size: 0.32rem;
}

.record .approve span.btn {
  color: #ff4731;
  border: solid 0.026667rem #ff4731;
  border-radius: 0.266667rem;
  padding: 0.133333rem 0.266667rem;
  font-size: 0.346667rem;
}