.settings {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
}

.settings .list {
  margin-top: 0.266667rem;
}

.settings .input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings .box {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cbcbd0;
}

.settings .address {
  color: #cbcbd0;
}

.settings .addressBook .box {
  width: 50%;
}

.settings .addressBook .uploadForm {
  position: relative;
  z-index: 2;
}

.settings .addressBook .uploadForm .uploadInput {
  visibility: hidden;
  width: 100%;
}

.settings .addressBook .uploadText {
  position: absolute;
  top: 0.373333rem;
  right: 0.693333rem;
  z-index: 1;
}

.settings :global .adm-button-block {
  width: 95%;
  padding: 0.186667rem 0.586667rem;
  margin: 0 auto;
  margin-top: 0.8rem;
  font-size: 0.373333rem;
  background-color: #2f7dcd;
}

.listCell :global .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
}

.listCell :global .adm-list-item-content .adm-list-item-content-main div {
  font-size: 0.373333rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone :global .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.phone :global .adm-list-item-content .adm-list-item-content-main div {
  font-size: 0.373333rem;
  font-weight: 500;
}

.phone :global .adm-list-item-content .adm-list-item-content-main div span {
  color: #cbcbd0;
}

.phone :global .adm-list-item-content .adm-list-item-content-main div .adm-input-wrapper .adm-input {
  font-size: 0.373333rem;
  text-align: right !important;
}

.model :global .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
}

.model :global .adm-list-item-content .adm-list-item-content-main div {
  font-size: 0.373333rem;
  font-weight: 500;
}

.emergency :global .adm-list-item-content .adm-list-item-content-main,
.userAddressBook :global .adm-list-item-content .adm-list-item-content-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emergency :global .adm-list-item-content .adm-list-item-content-main div,
.userAddressBook :global .adm-list-item-content .adm-list-item-content-main div {
  font-size: 0.373333rem;
  font-weight: 500;
}

.emergency :global .adm-list-item-content .adm-list-item-content-main div .adm-input-wrapper .adm-input,
.userAddressBook :global .adm-list-item-content .adm-list-item-content-main div .adm-input-wrapper .adm-input {
  font-size: 0.373333rem;
  text-align: right !important;
}