.container {
  background-color: #f9f9f9;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.container .head {
  background: #6c98cf url(../assets/perApply.png) no-repeat 100% 100%;
  border-radius: 0.266667rem 0.266667rem 0 0;
  margin: 0.4rem 0.4rem 0 0.4rem;
  color: #fcfdfe;
}

.container .head>div:nth-child(1) {
  padding: 0.4rem 0.4rem 0.666667rem 0.4rem;
}

.container .head>div:nth-child(1)>div:nth-child(1) {
  font-size: 0.426667rem;
  height: 0.933333rem;
  line-height: 0.933333rem;
}

.container .head>div:nth-child(1)>div:nth-child(2) {
  font-size: 0.266667rem;
}

.container .head>div:nth-child(1)>div:nth-child(2)>div {
  margin-top: 0.133333rem;
}

.container .head>div:nth-child(2) {
  font-size: 0.4rem;
  text-align: center;
  background-color: rgba(255,255,255,0.2);
  height: 1.2rem;
  line-height: 1.2rem;
}

.container .body {
  margin-bottom: 3.2rem;
}

.container .body>div:nth-child(1) {
  background-color: #ffffff;
  padding: 0 0.4rem;
}

.container .body>div:nth-child(1) :global .adm-list-item-content {
  height: 1.2rem !important;
}

.container .body>div:nth-child(2) {
  margin-top: 0.4rem;
  background-color: #ffffff;
  padding: 0 0.4rem;
}

.container .body>div:nth-child(2)>div:nth-child(1) {
  font-size: 0.453333rem;
  height: 1.2rem;
  line-height: 1.2rem;
}

.container .body .ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 5.333333rem;
  text-align: right;
}

.container .body .ellipsis .text {
  color: #000000;
}

.container .foot {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
  height: auto;
  padding: 0.4rem;
  background-color: #f9f9f9;
}

.container .foot>:nth-child(2) {
  margin-top: 0.266667rem;
}

.searchBarContainer {
  padding: 0.32rem;
  border-bottom: solid 0.026667rem #eeeeee;
}

.checkListContainer {
  height: 8rem;
  overflow-y: scroll;
  padding: 0 0.266667rem;
}

.checkListContainer .myCheckList {
  border-top: solid 0.026667rem #eeeeee;
  border-bottom: solid 0.026667rem #eeeeee;
  line-height: 1.2rem;
  height: 1.2rem;
  display: flex;
  flex-direction: row;
  font-size: 0.373333rem;
}

.checkListContainer .myCheckList>div:nth-child(1) {
  flex: auto;
}