.sanofiMeeting {
  background-color: #f1f4f9;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.sanofiMeeting .container {
  flex: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sanofiMeeting .container>div:nth-child(1) {
  flex: auto;
  overflow: auto;
  padding: 0.4rem;
}

.sanofiMeeting .container>div:nth-child(1) .nodata {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.sanofiMeeting .container>div:nth-child(1) .nodata .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f1f4f9;
  margin-top: 60%;
}

.sanofiMeeting .container>div:nth-child(1) .nodata .box img {
  width: 4.266667rem;
  height: 4.266667rem;
}

.sanofiMeeting .container .card {
  position: relative;
  margin-bottom: 0.266667rem;
  font-size: 0.426667rem;
  box-shadow: 0 0.266667rem 0.533333rem 0 rgba(60,80,100,0.15);
}

.sanofiMeeting .container .card .header,
.sanofiMeeting .container .card .cardfooter {
  display: flex;
  flex-direction: column;
}

.sanofiMeeting .container .card .header {
  max-width: 90%;
  color: #3c4858;
}

.sanofiMeeting .container .card .header .icCode {
  font-size: 0.373333rem;
}

.sanofiMeeting .container .card .header span {
  margin-bottom: 0.053333rem;
}

.sanofiMeeting .container .card .cardfooter {
  margin-top: 0.266667rem;
  font-size: 0.373333rem;
  color: rgba(60,72,88,0.5);
}

.sanofiMeeting .container .card .cardfooter .detailDescribe {
  color: #2f7dcd;
}

.sanofiMeeting .container .card .deleteIcon {
  position: absolute;
  top: 1.333333rem;
  right: 0.4rem;
}

.sanofiMeeting .footer {
  height: 1.6rem;
  line-height: 1.6rem;
  background-color: #fafafa;
  border-top: solid 0.026667rem #d9d9d9;
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-size: 0.426667rem;
}

.sanofiMeeting .footer>div:nth-child(1) {
  width: 65%;
  color: #2f7dcd;
}

.sanofiMeeting .footer>div:nth-child(2) {
  width: 35%;
  color: #fff;
  background-color: #2f7dcd;
}

.sanofiMeeting .footer>div {
  text-align: center;
}