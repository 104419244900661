.container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.container .amap {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}