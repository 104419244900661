.container {
  min-height: 2.666667rem;
  text-align: center;
}

.container .title {
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.426667rem;
  font-weight: 500;
}

.container .body>div {
  height: 1.333333rem;
  line-height: 1.333333rem;
  font-size: 0.373333rem;
  color: #000000;
  background-color: #ffffff;
  border-top: solid 0.026667rem #ebebeb;
}

.container .body :global .adm-collapse {
  line-height: normal;
  border-top: none;
  height: 11.2rem;
  overflow-y: scroll;
}

.container .body :global .adm-collapse .adm-list-item-content-main {
  text-align: left;
}

.container .body :global .adm-collapse .adm-text-area textarea {
  font-size: 0.32rem;
  border-radius: 0.133333rem;
  border: 0.026667rem solid #edeff1;
  padding: 0.133333rem;
}

.container .reason_span {
  display: flex;
  justify-content: space-between;
  padding: 0.186667rem 0;
}

.container .reason_span span {
  font-size: 0.346667rem;
  color: #333;
}

.container .reason_span .no {
  width: 0.426667rem;
  height: 0.426667rem;
  border: solid 0.026667rem #C8C8CD;
  border-radius: 0.266667rem;
}

.container .reason_span .checked {
  width: 0.426667rem;
  height: 0.426667rem;
  background: var(--manualChoose_car_checked_image) no-repeat 0% 0%;
  background-size: 100% 100%;
  border-radius: 0.266667rem;
  border: none;
}

.container .foot {
  height: 1.066667rem;
  line-height: 1.333333rem;
  margin-top: 0.4rem;
  font-size: 0.373333rem;
  color: #000000;
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0.266667rem;
}

.container .foot :global button {
  width: 4.266667rem;
}