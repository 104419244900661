.container {
  background-color: #f5f5f5;
  position: fixed;
  height: 100%;
  width: 100%;
  border: 0.026667rem solid transparent;
}

.container .share_box {
  text-align: right;
}

.container .share_box .share_img {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.533333rem;
  margin-top: 0.266667rem;
  margin-bottom: 0.266667rem;
}

.container .head {
  width: 100vw;
  height: 2.666667rem;
  position: relative;
}

.container .head .share {
  width: 2.186667rem;
  height: 2.373333rem;
  position: absolute;
  top: 0.133333rem;
  right: 0.266667rem;
}

.container .head .share img {
  width: 100%;
  height: 100%;
}

.container .head .tip {
  color: #4F77AA;
  font-size: 0.48rem;
  position: absolute;
  bottom: 0;
  left: 0.4rem;
  width: 100vw;
  padding-right: 0.4rem;
}

.container .order {
  margin: 0.4rem;
  background: #fff url(../assets/rectangle.png) no-repeat 50% 50%;
  background-size: 100%;
  min-height: 4.8rem;
  box-shadow: 0 0 0.533333rem 0.08rem #dadada;
  border-radius: 0.133333rem;
  padding: 0.266667rem 0.4rem;
}

.container .order .title {
  display: flex;
  border-bottom: solid 0.053333rem #f6f6f6;
  padding: 0.266667rem 0;
}

.container .order .title>div {
  color: #4F77AA;
  flex: 1 1;
  text-align: center;
}

.container .order .title>div>div:nth-child(1) {
  font-size: 0.4rem;
}

.container .order .title>div>div:nth-child(2) {
  font-size: 0.453333rem;
  font-weight: bold;
  padding-top: 0.133333rem;
}

.container .order .title>div:nth-child(1) {
  border-right: solid 0.053333rem #f6f6f6;
}

.container .order .body {
  padding: 0.266667rem 0 0 0;
}

.container .order .body>div {
  display: flex;
  height: 1.2rem;
  color: #9B9B9B;
  font-size: 0.373333rem;
  align-items: center;
}

.container .order .body>div>div:nth-child(1) {
  width: 0.533333rem;
  text-align: center;
}

.container .order .body>div>div:nth-child(1) img {
  width: 0.4rem;
  height: 0.4rem;
}

.container .order .body>div>div:nth-child(2) {
  width: 1.333333rem;
  padding-left: 0.133333rem;
}

.container .order .body>div>div:nth-child(3) {
  flex: auto;
  text-align: right;
}