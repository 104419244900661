.clause {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  padding: 0.266667rem 0.266667rem 0 0.266667rem;
  overflow-y: scroll;
}

.clause h2,
.clause h3 {
  margin: 0;
  font-weight: 500;
}

.clause h3 {
  margin-top: 0.266667rem;
  font-size: 0.426667rem;
}

.clause h2 {
  font-size: 0.48rem;
}

.clause p {
  font-size: 0.373333rem;
}