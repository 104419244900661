.container {
  padding: 0.533333rem;
  background-color: #fdfdfd;
  font-size: 0.373333rem;
  position: fixed;
  height: 100%;
  width: 100%;
}

.container .title {
  color: #497dab;
  font-size: 0.586667rem;
  font-weight: bold;
}

.container .tip {
  margin: 0.266667rem 0;
}

.container .info {
  margin: 0.8rem 0;
  padding: 0.266667rem;
  border-radius: 0.133333rem;
  box-shadow: 0 0 0.533333rem 0.08rem #dadada;
}

.container .info>div:nth-child(1) {
  border-bottom: dashed 0.026667rem #c0c0c0;
  padding-bottom: 0.4rem;
}

.container .info>div:nth-child(2) {
  padding-top: 0.4rem;
}

.container .info>div>div:nth-child(1) {
  color: #c0c0c0;
  display: flex;
}

.container .info>div>div:nth-child(2) {
  padding-top: 0.266667rem;
  padding-left: 0.48rem;
  font-size: 0.426667rem;
}

.container .dot {
  width: 0.48rem;
}

.container .dot div {
  width: 0.133333rem;
  height: 0.133333rem;
  border-radius: 50%;
  background-color: #387cc6;
  overflow: hidden;
  margin-top: 0.266667rem;
}

.container .strategy {
  display: flex;
  margin: 0.266667rem 0;
}

.container .strategy span {
  color: #387cc6;
}

.container .toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

.container .toolbar>div:nth-child(1) {
  width: 55%;
}

.container .toolbar>div:nth-child(2) {
  width: 55%;
}

.container .toolbar>div {
  padding: 0.266667rem;
}

.container .toolbar>div .box {
  padding-right: 0.453333rem;
}

.container .toolbar>div button {
  padding: 0.4rem 0;
}