.container {
  background-color: #ffffff;
}

.container .cars {
  display: flex;
  margin: 0 0.266667rem;
  flex-direction: row;
  border-bottom: solid 0.026667rem #D9D9D9;
}

.container .cars .car {
  width: 25%;
  height: 4rem;
  position: relative;
  text-align: center;
  color: rgba(0,0,0,0.3);
}

.container .cars .car.active {
  color: #000000;
}

.container .cars .car.active .pointer {
  display: block !important;
}

.container .cars .car .cover {
  width: 1.333333rem;
  height: 1.333333rem;
  margin: auto;
  margin-top: 0.533333rem;
}

.container .cars .car .cover img {
  width: 100%;
  height: 100%;
}

.container .cars .car .carname {
  margin-top: 0.266667rem;
  font-size: 0.373333rem;
  letter-spacing: 0.026667rem;
}

.container .cars .car .reimbursement {
  margin-top: 0.08rem;
  font-size: 0.346667rem;
}

.container .cars .car .pointer {
  width: 0;
  height: 0;
  border: 0.266667rem transparent dashed;
  border-bottom: solid 0.266667rem #D9D9D9;
  position: absolute;
  bottom: 0;
  left: calc(50% - 0.266667rem);
  margin: auto;
  display: none;
}

.container .cars .car .pointer>div {
  width: 0;
  height: 0;
  border: 0.266667rem transparent dashed;
  border-bottom: solid 0.266667rem #ffffff;
  position: absolute;
  bottom: -0.293333rem;
  left: -0.266667rem;
  z-index: 5;
  overflow: hidden;
}

.container .price {
  font-size: 0.693333rem;
  height: 1.333333rem;
  line-height: 1.333333rem;
}

.container .price ._price {
  display: table;
  padding-right: 0.533333rem;
  margin: 0 auto;
  background: url(../../assets/backbtn.png) 100% 45% no-repeat;
  background-size: 0.186667rem;
}

.container .price ._price span {
  font-size: 0.48rem;
}

.container .btncont {
  padding: 0.266667rem;
}

.container .remark {
  position: absolute;
  margin-left: 10vw;
  margin-top: 20vh;
  width: 80vw;
  border-radius: 0.133333rem;
  background-color: #ffffff;
}

.container .remark .title {
  margin: 0.266667rem 0.4rem;
  border-bottom: dotted 0.026667rem #9f9f9f;
  color: #9f9f9f;
}

.container .remark .title>div {
  position: relative;
  top: 0.266667rem;
  text-align: center;
  background-color: #ffffff;
  display: table;
  margin: auto;
  padding: 0 0.266667rem;
}

.container .remark .body {
  margin: 0.4rem;
  border: solid 0.026667rem #d9d9d9;
  border-radius: 0.133333rem;
  text-align: center;
}

.container .remark .body>div {
  height: 1.6rem;
  line-height: 1.6rem;
  display: flex;
  border-bottom: solid 0.026667rem #d9d9d9;
}

.container .remark .body .ascending .cartype {
  flex: 1 1;
  font-size: 0.4rem;
}

.container .remark .body .ascending .cartype img {
  width: 0.48rem;
  height: 0.48rem;
  position: relative;
  top: 0.106667rem;
}

.container .remark .body .ascending .arrow {
  width: 0.8rem;
  background: url(../../assets/price_arrow.png) no-repeat 50% 50%;
}

.container .remark .body .prices>div {
  flex: 1 1;
  font-size: 0.586667rem;
}

.container .remark .body .prices>div span {
  font-size: 0.48rem;
}

.container .remark .body .prices>div:nth-child(1) {
  border-right: solid 0.026667rem #d9d9d9;
}

.container .remark .body .carleves {
  font-size: 0.4rem;
}

.container .remark .body .carleves>div {
  flex: 1 1;
}

.container .remark .body .carleves>div:nth-child(1) {
  border-right: solid 0.026667rem #d9d9d9;
}

.container .remark .body .dynamic {
  font-size: 0.346667rem;
  color: #9B9B9B;
  border-bottom: none;
}

.container .remark .body .dynamic>div {
  flex: 1 1;
}

.container .remark .body .dynamic>div span {
  font-size: 0.586667rem;
  color: #000000;
}

.container .remark .body .dynamic>div:nth-child(1) {
  border-right: solid 0.026667rem #d9d9d9;
}

.container .remark .footbar {
  font-size: 0.453333rem;
  color: #467DB9;
  text-align: center;
  border-top: solid 0.026667rem #d9d9d9;
  height: 1.333333rem;
  line-height: 1.333333rem;
}