.box {
  width: 100%;
  margin-top: 0.266667rem;
  padding: 0 0 0.266667rem 0.266667rem;
  background-color: white;
  position: relative;
  border-radius: 0.213333rem;
  box-shadow: 0 0.16rem 0.426667rem 0 rgba(60,72,88,0.2);
}

.box .cell {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.213333rem;
  padding-top: 0.266667rem;
  color: #292c31;
  padding-right: 0.266667rem;
  margin-bottom: 0.266667rem;
}

.box .cell .price {
  display: flex;
  align-items: center;
}

.box .cell .price .money {
  font-size: 0.533333rem;
  font-weight: 500;
  color: #3C4858;
  margin-right: 0.266667rem;
  vertical-align: middle;
}

.box .cell .price .frozen {
  font-size: 0.533333rem;
  font-weight: 500;
  color: #ccccd0;
  margin-right: 0.266667rem;
  vertical-align: middle;
}

.box .cell .price .frozenIcon {
  font-size: 0.32rem;
  display: inline-block;
  color: #ccccd0;
  margin-right: 0.266667rem;
  vertical-align: middle;
}

.box .cell .price em {
  font-size: 0.32rem;
  font-style: inherit;
  display: inline-block;
  border-radius: 0.106667rem;
  color: #3C4858;
  background: rgba(60,72,88,0.05);
  vertical-align: middle;
  margin-right: 0.133333rem;
  font-weight: 400;
}

.box .cell .price .prepaid {
  color: #F5A623;
  background-color: #fef6e9;
  margin-left: 0.133333rem;
}

.box .cell .price .unpaid {
  background-color: var(--order_unpaid_background_color);
  color: var(--order_unpaid_color);
  font-size: 0.32rem;
  padding: 0 0.133333rem;
  border-radius: 0.133333rem;
  margin-left: 0.133333rem;
  font-weight: 400;
}

.box .cell .price .error {
  background-color: #f34747;
  color: #ffffff !important;
  font-size: 0.32rem;
  padding: 0 0.133333rem;
  border-radius: 0.133333rem;
  margin-left: 0.133333rem;
}

.box .cell .price .cancel_expenses {
  color: #3C4858;
  margin-left: 0.053333rem;
}

.box .cell .status {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 0.32rem;
}

.box .cell .status .blue {
  color: var(--order_blue);
}

.box .cell .status .gray {
  color: #ccccd0;
}

.box .cell .status img {
  width: 0.32rem;
  height: 0.32rem;
  margin-left: 0.133333rem;
}

.box .cell .status span {
  width: -webkit-max-content;
  width: max-content;
  text-align: right;
}

.box .address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.4rem;
  position: relative;
}

.box .address .left .date {
  color: #3C4858;
  margin-bottom: 0.266667rem;
  font-size: 0.346667rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.266667rem;
}

.box .address .left .date .img {
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.133333rem;
}

.box .address .left .date span {
  color: #3C4858;
  opacity: 0.5;
}

.box .address .left .start {
  color: #3C4858;
  margin-bottom: 0.266667rem;
  font-size: 0.346667rem;
  display: flex;
}

.box .address .left .start div {
  margin-right: 0.133333rem;
  width: 0.4rem;
  height: 0.4rem;
  text-align: center;
  list-style: none;
  vertical-align: middle;
}

.box .address .left .start .circular {
  width: 0.133333rem;
  height: 0.133333rem;
  background-color: var(--order_circular_green);
  vertical-align: middle;
  display: inline-block;
  border-radius: 50%;
}

.box .address .left .start .position {
  width: 7.2rem;
  color: #3C4858;
  opacity: 0.5;
  vertical-align: middle;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.box .address .left .end {
  color: #3C4858;
  font-size: 0.346667rem;
  display: flex;
}

.box .address .left .end div {
  margin-right: 0.133333rem;
  width: 0.4rem;
  height: 0.4rem;
  text-align: center;
  list-style: none;
  vertical-align: middle;
}

.box .address .left .end .circular {
  width: 0.133333rem;
  height: 0.133333rem;
  background-color: var(--order_circular_red);
  vertical-align: middle;
  display: inline-block;
  border-radius: 50%;
}

.box .address .left .end .position {
  color: #3C4858;
  opacity: 0.5;
  vertical-align: middle;
}

.box .address .right .pass {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2.186667rem;
  height: 1.733333rem;
  z-index: 10;
}

.orderlist {
  width: 100%;
  padding-top: 1.333333rem;
}

.box:nth-child(1) {
  margin-top: 0;
}

.address :global .adm-button {
  background-color: #2F7DCD;
  border-radius: 0.08rem;
}