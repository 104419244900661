.wrap {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  padding: 0.266667rem;
  display: flex;
  flex-direction: column;
}

.wrap .content {
  background: #FFFFFF;
  box-shadow: 0 0.16rem 0.426667rem 0 rgba(60,72,88,0.2);
  border-radius: 0.213333rem;
  flex-shrink: 0;
}

.wrap .content .car_source {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.666667rem 0;
  border-bottom: 0.026667rem solid #edeff1;
}

.wrap .content .car_source>div:nth-child(1) {
  display: flex;
  align-items: center;
  margin-bottom: 0.266667rem;
}

.wrap .content .car_source>div:nth-child(1) span {
  font-size: 0.373333rem;
  color: #101010;
  text-align: center;
  font-weight: 500;
}

.wrap .content .car_source>div:nth-child(1) img {
  width: 0.666667rem;
  height: 0.666667rem;
  border-radius: 50%;
  margin-right: 0.213333rem;
}

.wrap .content .car_source>div:nth-child(2) {
  font-size: 0.346667rem;
  color: #898989;
  font-weight: 400;
}

.wrap .content .car_source>div:nth-child(2) span {
  font-size: 0.48rem;
  color: var(--expenseDetails_car_source_span);
  font-weight: 500;
}

.wrap .box {
  margin-bottom: 0.4rem;
}

.wrap .box:last-child {
  margin-bottom: 0;
}

.wrap .expenses {
  display: flex;
  justify-content: space-between;
}

.wrap .expenses span {
  font-size: 0.373333rem;
  color: #3C4858;
  font-weight: 500;
}

.wrap .expenses .red_color {
  font-size: 0.373333rem;
  color: var(--expenseDetails_red_color);
  font-weight: 400;
}

.wrap .dimension {
  font-size: 0.32rem;
  color: #4F77AA;
  font-weight: 400;
}

.wrap .content_list {
  padding: 0.4rem 0.266667rem 0.4rem 0.266667rem;
}

.wrap .refund_box {
  font-size: 0.306667rem;
  text-align: center;
  padding: 0.4rem 0.266667rem 0.4rem 0.266667rem;
}

.wrap .feedback {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 0.373333rem;
  color: #3C4858;
  font-weight: 400;
  padding-bottom: 1.066667rem;
}

.wrap .feedback div {
  font-size: 0.373333rem;
  color: var(--expenseDetails_feedback_dev);
  font-weight: 500;
}