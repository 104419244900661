.menu {
  background-color: #fff;
  height: 100%;
  overflow: auto;
}

.menu :global .adm-list {
  background-color: #fff !important;
  font-size: 0.373333rem !important;
}

.trainContainer {
  height: 1.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: #fff;
  background: no-repeat center/100% url(../../assets/bg_train.png);
}