@-webkit-keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(90deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(270deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(90deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(270deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.smartOrderState {
  padding: 0.266667rem;
  font-size: 0.426667rem;
  display: flex;
  background-color: #ffffff;
  color: #141414;
}

.smartOrderState>div:nth-child(1) {
  flex: auto;
  line-height: 0.906667rem;
  display: flex;
}

.smartOrderState>div:nth-child(1) img {
  width: 0.533333rem;
  height: 0.533333rem;
  margin-left: 0.186667rem;
  margin-top: 0.186667rem;
  -webkit-animation: turn 1s linear infinite;
  animation: turn 1s linear infinite;
}

.smartOrderState>div:nth-child(2) {
  width: 5.333333rem;
  text-align: right;
}

.smartOrderState>div:nth-child(2) button {
  border-radius: 0.533333rem;
  font-size: 0.373333rem;
}

.smartOrderDetail {
  margin: 0 0.266667rem;
  border-top: solid 0.026667rem #efefef;
  padding: 0.266667rem 0;
  color: #2d2d2d;
}