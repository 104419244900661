.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
  top: 0;
  left: 0;
}

.container .toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 0.266667rem;
  background-color: #ffffff;
}

.container .toolbar .pathInfo {
  font-size: 0.373333rem;
}

.container .toolbar .btns {
  margin-top: 0.266667rem;
  display: flex;
}

.container .toolbar .stationbar {
  flex: auto;
  text-align: right;
}