.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow: auto;
}

.container .cancel {
  height: 1.2rem;
  background-color: #ffffff;
  line-height: 1.2rem;
  display: flex;
  padding: 0 0.4rem;
  border-bottom: 0.026667rem solid #dfdfdf;
}

.container .cancel>div:nth-child(1) {
  flex: auto;
  font-size: 0.4rem;
}

.container .cancel>div:nth-child(2) {
  padding-top: 0.32rem;
}

.container .alert {
  background-color: #ffffff;
  font-size: 0.373333rem;
  color: #4A4A4A;
  line-height: 0.586667rem;
  padding: 0.133333rem 0.4rem;
  border-bottom: 0.026667rem solid #dfdfdf;
}

.container .reason {
  background-color: #ffffff;
  font-size: 0.32rem;
  color: #4A4A4A;
  line-height: 0.586667rem;
  border-radius: 0 0 0.213333rem 0.213333rem;
  border: 1 solid #dfdfdf;
  border-top: none;
  padding: 0.133333rem 0.4rem;
  overflow: hidden;
  margin: 0 0.4rem 0.4rem 0.4rem;
}