.failBody :global {
  max-height: 60vh;
}

.failBody :global .adm-modal-content {
  max-height: 60vh;
  padding-bottom: 0;
}

.failBody :global .adm-modal-content .adm-modal-title {
  font-weight: 500;
}

.failBody :global .adm-modal-content .content {
  white-space: pre-wrap;
}

.content {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 0.266667rem;
}

.content label {
  display: flex;
  justify-content: left;
  align-items: center;
}

.content label input[type=checkbox]:checked::after {
  background-color: #1f91f1 !important;
  -moz-print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.book {
  font-size: 0.32rem;
}

.agreement {
  font-size: 0.32rem;
}