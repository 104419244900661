.estimate {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f0f0f0;
}

.estimate_jd {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: #f0f0f0;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.amapContainer {
  width: 100vw;
  flex: auto;
  background-color: antiquewhite;
  position: relative;
}

.amapContainer_jd {
  width: 100vw;
  height: 40%;
  background-color: antiquewhite;
  position: relative;
}

.estimate_panel {
  height: auto;
  position: relative;
}

.estimate_panel_jd {
  height: 60%;
  position: relative;
}

.maprestoration {
  height: 1.2rem;
  text-align: right;
  position: absolute;
  left: 0;
  width: 100%;
  top: -1.2rem;
  display: flex;
}

.maprestoration>div {
  padding: 0.266667rem;
}

.maprestoration>div .estimatetime {
  border-radius: 0.213333rem;
  background-color: #ffffff;
  box-shadow: 0 0.08rem 0.16rem 0 rgba(0,0,0,0.02);
  text-align: center;
  font-size: 0.293333rem;
  color: #141414;
  font-weight: 500;
  padding: 0.16rem 0.32rem 0.16rem 0.32rem;
}

.maprestoration>div .estimatetime span {
  color: #00AB19;
}

.maprestoration img {
  width: 1.2rem;
  height: 1.2rem;
}

.menuIcon {
  position: absolute;
  top: 0.213333rem;
  left: 0.133333rem;
  width: 1.28rem;
  height: 1.28rem;
  border-radius: 0.533333rem;
  display: inline-block;
  background: url(../assets/menu.png) center no-repeat;
  background-size: 100% 100%;
}

.menuIcon .box {
  width: 1.28rem;
  height: 1.28rem;
}

.btn_details {
  font-size: 0.373333rem;
  color: #000000;
  font-weight: 500;
}

.btn_close {
  font-size: 0.373333rem;
  color: #898989;
  font-weight: 400;
}

.estimate :global .adm-badge-content {
  min-width: 0.32rem;
  font-size: 0.266667rem;
  padding: 0.053333rem 0.133333rem;
}

.container :global .adm-mask {
  z-index: 100;
}

.container :global .adm-modal-wrap {
  z-index: 101;
  min-width: 9.466667rem;
}

.container :global .adm-modal {
  z-index: 1002;
}

.container :global .adm-space {
  display: none;
}

.container :global tr td,
.container :global tr th {
  border: #c4c4c4 0.026667rem solid;
}

.container :global td {
  font-size: 0.32rem;
  padding: 0.133333rem;
}

.container :global th {
  font-size: 0.346667rem;
}

.container :global th span {
  font-size: 0.32rem;
  font-weight: normal;
}

.container :global tr td:first-child {
  color: #5997d7;
  font-weight: 500;
}

.container :global .adm-modal-wrap {
  min-width: 95% !important;
}

.container :global .adm-popup-body-position-bottom {
  border-top-left-radius: 0.266667rem !important;
  border-top-right-radius: 0.266667rem !important;
}