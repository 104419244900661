.container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  padding: 0.266667rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.container .top {
  flex-shrink: 0;
}

.container .top .details {
  padding: 0.266667rem;
  background: #FFFFFF;
  box-shadow: 0 0.16rem 0.426667rem 0 rgba(60,72,88,0.2);
  border-radius: 0.266667rem;
}

.container .top .details>p {
  font-size: 0.373333rem;
  color: var(--serviceCenter_details_p_color);
  font-weight: 500;
  margin: 0 0 0.266667rem 0;
}

.container .top .details .msg {
  display: flex;
  align-items: center;
}

.container .top .details .msg .price {
  font-size: 0.533333rem;
  color: #3C4858;
  text-align: right;
  font-weight: 400;
}

.container .top .details .msg .type {
  background: var(--serviceCenter_details_type);
  border-radius: 0.133333rem;
  font-size: 0.32rem;
  color: #3C4858;
  font-weight: 400;
  display: inline-block;
  padding: 0.053333rem 0.106667rem;
  margin-left: 0.133333rem;
}

.container .top .details .msg .pay_type {
  background: var(--serviceCenter_pay_type_background_color);
  border-radius: 0.133333rem;
  font-size: 0.32rem;
  color: var(--serviceCenter_pay_type_color);
  font-weight: 400;
  display: inline-block;
  padding: 0.053333rem 0.106667rem;
  margin-left: 0.133333rem;
}

.container .top .details .tiem {
  display: flex;
  margin-top: 0.266667rem;
}

.container .top .details .tiem img {
  width: 0.373333rem;
  height: 0.346667rem;
}

.container .top .details .tiem span {
  opacity: 0.5;
  font-size: 0.346667rem;
  color: #3C4858;
  font-weight: 400;
  margin-left: 0.133333rem;
}

.container .top .details .begin {
  display: flex;
  align-items: center;
  margin: 0.266667rem 0 0.266667rem 0;
}

.container .top .details .begin span {
  width: 0.186667rem;
  height: 0.186667rem;
  border-radius: 50%;
  background: var(--serviceCenter_begin_span_background_color);
  display: inline-block;
  margin-left: 0.08rem;
}

.container .top .details .begin p {
  margin: 0;
  opacity: 0.5;
  font-size: 0.32rem;
  color: #3C4858;
  font-weight: 400;
  margin-left: 0.213333rem;
}

.container .top .details .end {
  display: flex;
  align-items: center;
}

.container .top .details .end span {
  width: 0.186667rem;
  height: 0.186667rem;
  border-radius: 50%;
  background: var(--serviceCenter_end_span_background_color);
  display: inline-block;
  margin-left: 0.08rem;
}

.container .top .details .end p {
  margin: 0;
  opacity: 0.5;
  font-size: 0.32rem;
  color: #3C4858;
  font-weight: 400;
  margin-left: 0.213333rem;
}

.container .top .problem_type {
  padding: 0.266667rem;
  background: #FFFFFF;
  box-shadow: 0 0.16rem 0.426667rem 0 rgba(60,72,88,0.2);
  border-radius: 0.266667rem;
  margin-top: 0.266667rem;
}

.container .top .problem_type .content {
  margin-top: 0.266667rem;
}

.container .top .problem_type p {
  margin: 0;
  font-size: 0.373333rem;
  color: #3C4858;
  font-weight: 500;
  display: flex;
}

.container .top .problem_type p span {
  font-size: 0.48rem;
  color: red;
}

.container .top .problem_type .fee_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.container .top .problem_type .fee_list .fee_cell {
  background: rgba(255,255,255,0.1);
  border: 0.026667rem solid #edeff1;
  border-radius: 0.133333rem;
  padding: 0.133333rem 0.533333rem;
  margin-bottom: 0.266667rem;
  font-size: 0.346667rem;
}

.container .top .problem_type .fee_cell_bule {
  background: var(--serviceCenter_fee_cell_bule_background_color);
  border: 0.026667rem solid var(--serviceCenter_fee_cell_bule_border_color);
  border-radius: 0.133333rem;
  padding: 0.133333rem 0.533333rem;
  margin-bottom: 0.266667rem;
  font-size: 0.346667rem;
  color: var(--serviceCenter_fee_cell_bule_color);
  text-align: center;
  font-weight: 400;
}

.container .top .problem_type .fee_list::after {
  content: '';
  width: 0.4rem;
}

.container .article {
  font-size: 0.346667rem;
  display: flex;
  align-items: center;
  color: #3C4858;
  font-weight: 400;
}

.container .article span {
  margin-right: 0.133333rem;
}

.container .article img {
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.213333rem;
}

.container .article :global .adm-badge-content {
  color: var(--serviceCenter_badge_color);
}

.container .contact {
  flex-grow: 1;
  font-size: 0.373333rem;
  color: #3C4858;
  font-weight: 400;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 1.333333rem;
}

.container .contact div {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.533333rem;
}

.container .contact img {
  width: 0.586667rem;
  height: 0.586667rem;
  margin-right: 0.266667rem;
}

.container :global .adm-button {
  background: #2F7DCD;
  border-radius: 0.133333rem;
  padding-top: 0.213333rem;
  padding-bottom: 0.213333rem;
  margin-top: 0.533333rem;
}

.problem_type :global .adm-list {
  --active-background-color: '';
}

.problem_type :global .adm-list-body {
  border: none !important;
  background: #f5f6f6 !important;
  border-radius: 0.133333rem;
}

.problem_type :global .adm-collapse-panel-content-inner {
  background-color: white;
}

.problem_type :global .adm-list-item-content {
  border: none;
}

.problem_type :global .adm-collapse-arrow svg {
  font-size: 0.4rem;
  color: #3c4858;
}

.problem_type :global .adm-list-body-inner a::after {
  border: none !important;
}

.problem_type :global .adm-text-area textarea {
  font-size: 0.373333rem;
  border-radius: 0.133333rem;
  border: 0.026667rem solid #edeff1;
  padding: 0.133333rem;
}

.show_red :global .adm-text-area textarea::-webkit-input-placeholder {
  color: red;
}

.show_red :global .adm-text-area textarea:-ms-input-placeholder {
  color: red;
}

.show_red :global .adm-text-area textarea::placeholder {
  color: red;
}