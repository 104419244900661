.addSanofiMeeting {
  background-color: #fafaf8;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.addSanofiMeeting .heads {
  background-color: #fafaf8;
  width: 100%;
  font-size: 0.346667rem;
}

.addSanofiMeeting .heads .head {
  display: flex;
  line-height: 1.2rem;
  border-bottom: solid 0.026667rem #d9ddde;
}

.addSanofiMeeting .heads .head .input {
  flex: auto;
  margin: 0 0 0 0.266667rem;
  position: relative;
}

.addSanofiMeeting .heads .head .input .searchIcon {
  position: absolute;
  top: 0.426667rem;
  left: 0.48rem;
}

.addSanofiMeeting .heads .head .input input {
  width: 100%;
  height: 0.8rem;
  line-height: 0.8rem;
  text-indent: 0.266667rem;
  border: solid 0.026667rem #d9d9d9;
  border-radius: 0.133333rem;
  font-size: 0.373333rem;
}

.addSanofiMeeting .heads .head .input>input::-ms-input-placeholder {
  padding-left: 0.533333rem;
  text-align: left;
  font-size: 0.346667rem;
}

.addSanofiMeeting .heads .head .input>input::-webkit-input-placeholder {
  padding-left: 0.533333rem;
  text-align: left;
  font-size: 0.346667rem;
}

.addSanofiMeeting .heads .head .cancel {
  width: 1.6rem;
  color: #4670a0;
  text-align: center;
}

.addSanofiMeeting .container {
  flex: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.addSanofiMeeting .container>div:nth-child(1) {
  flex: auto;
  overflow: auto;
}

.addSanofiMeeting .container>div:nth-child(1) .nodata {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.addSanofiMeeting .container>div:nth-child(1) .nodata .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60%;
}

.addSanofiMeeting .container>div:nth-child(1) .nodata .box img {
  width: 4.266667rem;
  height: 4.266667rem;
}

.addSanofiMeeting .container .listItem {
  font-size: 0.373333rem;
  color: #9B9B9B;
}

.addSanofiMeeting .container .listItem .title {
  display: flex;
  align-items: center;
  margin-bottom: 0.133333rem;
  font-size: 0.48rem;
  color: #000000;
}

.addSanofiMeeting .container .listItem .title .tag {
  margin-left: 0.133333rem;
  padding: 0.106667rem;
  font-size: 0.32rem;
}