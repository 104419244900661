.container {
  display: flex;
  flex-direction: column;
  background: #F5F5F5;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.container .tips {
  position: absolute;
  left: 10vw;
  width: 80vw;
  top: 20vh;
  text-align: center;
}

.container .tips img {
  width: 100%;
}

.container .tips>div:last-child {
  font-size: 0.346667rem;
  text-align: center;
  color: #9f9f9f;
  margin-top: 0.266667rem;
}